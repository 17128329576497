import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';

/**
 * Defines the Profile Context for the whole app
 */
import { T_ProfileResponse } from '~/pages/api/profile';

import { NextRouter, useRouter } from 'next/router';

import { useProfileService } from '~/hooks/services/useProfileService';
import { getFirstActiveParticipation } from '~/utils/participations';
import { EmailStatus } from '~/enums/profile';
import usePrevious from '~/hooks/usePrevious';
import { sendDataLayerEvent } from '~/utils/analytics';
import { T_PartialProfile, T_Profile } from '~/services/profile';

type ProfileProvider = {
    profile: T_ProfileResponse;
    setProfile: (profile: T_ProfileResponse) => void;
    setPartialProfile: (partialProfile: T_PartialProfile) => void;
    profileRequestFinished: boolean;
};

const NextProfileContext = React.createContext<ProfileProvider | null>(null);

type NextProfileProviderProps = {
    children: ReactNode;
};

declare global {
    interface Window {
        darwinexzero: Record<string, any>;
    }
}

export const NextProfileProvider = ({ children }: NextProfileProviderProps) => {
    const { getProfileInfo } = useProfileService();

    const [profile, setProfile] = useState<T_ProfileResponse>(null);
    const [profileRequestFinished, setProfileRequestFinished] = useState<boolean>(false);

    const prevProfile = usePrevious(profile);

    const setPartialProfile = (partialProfile: T_PartialProfile) => {
        setProfile({
            ...profile,
            ...partialProfile,
        } as T_ProfileResponse);
    };

    const {
        data: userData,
        status: profileRequestStatus,
        error: userError,
        execute: doGetUserData,
    } = getProfileInfo<T_ProfileResponse>();

    useEffect(() => {
        checkCodeTribes();
        if (getCookie('logged') === true) {
            doGetUserData();
        } else {
            checkCodeSignup();
            setProfileRequestFinished(true);
        }

        // Init global variable
        window.darwinexzero = {
            username: null,
            email: null,
            userIdPublic: null,
            isPlatform: null,
        };
    }, []);

    const checkCodeSignup = () => {
        if (typeof window !== 'undefined') {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const couponParam = urlParams.get('coupon');
            if (couponParam) {
                const dateToken = new Date();
                dateToken.setTime(dateToken.getTime() + 30 * 24 * 60 * 60 * 1000);
                setCookie('COUPON_SIGNUP', couponParam as string, {
                    path: '/',
                    sameSite: 'lax',
                    httpOnly: false,
                    expires: dateToken,
                    secure: true,
                });
            }
        }
    };

    const checkCodeTribes = () => {
        if (typeof window !== 'undefined') {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const tribeParam = urlParams.get('tribe_inv_code');
            if (tribeParam) {
                const dateToken = new Date();
                dateToken.setTime(dateToken.getTime() + 30 * 24 * 60 * 60 * 1000);
                setCookie('JOIN_TRIBE', tribeParam as string, {
                    path: '/',
                    sameSite: 'lax',
                    httpOnly: false,
                    expires: dateToken,
                    secure: true,
                });
            }
        }
    };

    useEffect(() => {
        if (profileRequestStatus) {
            setProfileRequestFinished(true);
        }
    }, [profileRequestStatus]);

    let router: NextRouter | null = null;

    try {
        router = useRouter();
    } catch (errorRouter) {}

    useEffect(() => {
        if (router?.isReady && !router?.pathname.match(/404|500|403|logout/) && profile) {
            if (profile.emailStatus === EmailStatus.UNVERIFIED) {
                doGetUserData();
            } else {
                const participation = getFirstActiveParticipation(profile);
                if (participation) {
                    doGetUserData();
                }
            }
        }
    }, [router?.pathname]);

    useEffect(() => {
        // Update global variable
        const darwinexZeroInfo = { ...window.darwinexzero };
        if (profile) {
            darwinexZeroInfo.username = profile?.username;
            darwinexZeroInfo.email = profile?.email;
            darwinexZeroInfo.userIdPublic = profile?.publicId;
        } else {
            darwinexZeroInfo.username = null;
            darwinexZeroInfo.email = null;
            darwinexZeroInfo.userIdPublic = null;
        }
        window.darwinexzero = darwinexZeroInfo;
    }, [JSON.stringify(profile)]);

    useEffect(() => {
        if (prevProfile && !profile) {
            sendDataLayerEvent({
                event: 'logout',
            });
        }
    }, [JSON.stringify(profile)]);

    useEffect(() => {
        if (userData) {
            const profileData = userData as T_ProfileResponse;
            setProfile(profileData);
        }
        // TODO Remove userError here, no need to show on console
        if (userError) console.error(userError);
    }, [userData, userError]);

    const providerValue = useMemo<ProfileProvider>(
        () => ({ profile, setProfile, setPartialProfile, doGetUserData, profileRequestFinished }),
        [profile, setProfile, setPartialProfile, doGetUserData, profileRequestFinished],
    );

    return <NextProfileContext.Provider value={providerValue}>{children}</NextProfileContext.Provider>;
};

export const useProfile = () => {
    const context = React.useContext(NextProfileContext);
    if (context === undefined) {
        throw new Error('useProfile must be used within a NextProfileProvider');
    }
    return context as ProfileProvider;
};
