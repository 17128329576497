import React from 'react';
import classNames from 'classnames';
import { useI18N } from '~/contexts/NextI18NProvider';
import { BREAKPOINT_MAP, Button, Modal } from '@darwinex/components-library';
import useWindowSize from '~/hooks/useWindowSize';
import Link from 'next/link';

interface Props {
    onModalClose: React.MouseEventHandler;
    isMigrated?: boolean;
}

const CancelParticipationWarning = ({ onModalClose, isMigrated }: Props) => {
    const { M } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;
    return (
        <Modal
            size="md"
            type="warning"
            title={M('modals.cancel-participation-warning.title')}
            onModalClose={onModalClose}
        >
            <div className="dx-font-size-lg">
                <div className="dx-font-size-lg">
                    {isMigrated ? (
                        <span>
                            {M('modals.cancel-participation-warning.description-migrated')}{' '}
                            <Link href="/settings" onClick={onModalClose}>
                                {M('modals.cancel-participation-warning.description-migrated-cta')}
                            </Link>
                        </span>
                    ) : (
                        <span>{M('modals.cancel-participation-warning.description')}</span>
                    )}
                </div>
            </div>
            <div className="d-flex justify-content-end  dx-mt-10">
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    size="z-md"
                    variant="primary"
                    onClick={onModalClose}
                    border="rect"
                    type="button"
                >
                    {M('modals.cancel-participation-warning.cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default CancelParticipationWarning;
