import React, { useEffect } from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import {
    Alert,
    BooleanInput,
    BREAKPOINT_MAP,
    Button,
    DxNumber,
    IconInfoEmpty,
    Modal,
} from '@darwinex/components-library';
import useWindowSize from '~/hooks/useWindowSize';
import classNames from 'classnames';
import { DxCurrencies } from '@darwinex/components-library/build/components/DxNumber/number';
import { getAmountWithCurrencyFormat } from '~/utils/format';

import { Currency } from '~/enums/Currency';
import Nullable from '~/types/Nullable';
import { usePaymentsSubscriptionService } from '~/hooks/services/usePaymentSubscriptionService';
import { date } from '~/utils/dates';
import settingsStyles from '../../SettingsPage.module.scss';

interface Props {
    onModalClose: React.MouseEventHandler;
    onEditCreditCard: () => void;
    onRestartSubscription: () => void;
    restartRequested?: boolean;
    onClickPayWithPFees?: React.ChangeEventHandler<HTMLInputElement>;
    payWithPFees?: boolean;
    userCurrency: Nullable<Currency>;
    subscriptionId: Nullable<string>;
    serviceId: Nullable<string>;
    paymentPeriodNumberOfMonths: Nullable<number>;
}

const RestartParticipationDetails = ({
    onModalClose,
    onEditCreditCard,
    onRestartSubscription,
    restartRequested = false,
    onClickPayWithPFees,
    payWithPFees = false,
    userCurrency,
    subscriptionId,
    serviceId,
    paymentPeriodNumberOfMonths,
}: Props) => {
    const { M } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;

    const { getPaymentPreview } = usePaymentsSubscriptionService();

    const { data: paymentPreviewData, execute: executePaymentPreviewRequest } = getPaymentPreview(
        subscriptionId,
        serviceId,
    );
    useEffect(() => {
        if (subscriptionId && serviceId) {
            executePaymentPreviewRequest();
        }
    }, [subscriptionId, serviceId]);

    const getAmountToPay = (): Nullable<number> => {
        if (paymentPreviewData === undefined) {
            return null;
        }
        if (payWithPFees) {
            return paymentPreviewData.withEarningPreview.paymentDue.amount;
        }
        return paymentPreviewData.defaultPreview.paymentDue.amount;
    };

    const getRemainingPFeesInBothCurrencies = (): string => {
        if (paymentPreviewData === undefined) {
            return '';
        }
        if (
            paymentPreviewData.charges.currency !==
            paymentPreviewData.withEarningPreview.walletMoneyAfter.walletCurrency.currency
        ) {
            const { currency: chargesCurrency, amount: invoiceWalletRemainingInUserCurrency } =
                paymentPreviewData.withEarningPreview.walletMoneyAfter.baseCurrency;
            const { currency: invoiceWalletCurrency, amount: invoiceWalletRemainingInInvoiceWalletCurrency } =
                paymentPreviewData.withEarningPreview.walletMoneyAfter.walletCurrency;
            return `${getAmountWithCurrencyFormat(Math.max(0, invoiceWalletRemainingInUserCurrency), chargesCurrency)}
        (${getAmountWithCurrencyFormat(
            Math.max(0, invoiceWalletRemainingInInvoiceWalletCurrency),
            invoiceWalletCurrency,
        )})
        `;
        }

        const { currency, amount } = paymentPreviewData.withEarningPreview.walletMoneyAfter.baseCurrency;

        return `${getAmountWithCurrencyFormat(Math.max(0, amount), currency)}`;
    };

    const amountToPay = getAmountToPay();
    const remainingPFeesInBothCurrenciesAfterPaying = getRemainingPFeesInBothCurrencies();

    const userDoesNotHaveEarningsNeitherCreditsAndThereAreNotProratedCharges =
        paymentPreviewData?.walletMoneyBefore.baseCurrency.amount === 0 &&
        paymentPreviewData?.credit.amount === 0 &&
        paymentPreviewData?.charges.proratedCharges === 0;

    const getSubscriptionPaymentPeriod = (): string => {
        switch (paymentPeriodNumberOfMonths) {
            case 1:
                return `1 ${M('modals.restart-participation.payment.month')}`;
            case 12:
                return `1 ${M('modals.restart-participation.payment.year')}`;
            case 36:
                return `3 ${M('modals.restart-participation.payment.years')}`;
            default:
                return '---';
        }
    };

    return (
        <Modal size="md" title={M('modals.restart-participation.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg">
                <h5 className="dx-mb-3 font-open-sans--bold">{M('modals.restart-participation.payment.title')}</h5>
                {userDoesNotHaveEarningsNeitherCreditsAndThereAreNotProratedCharges && (
                    <p className="dx-my-3">
                        {M('modals.restart-participation.payment.text-1', {
                            price:
                                amountToPay !== null && userCurrency
                                    ? getAmountWithCurrencyFormat(
                                          amountToPay,
                                          paymentPreviewData?.charges.currency ?? '',
                                      )
                                    : '---',
                            card: paymentPreviewData?.paymentMethod.hint ?? '',
                        })}
                    </p>
                )}
                <p className="dx-my-3">{M('modals.restart-participation.payment.text-2')}</p>
                <p className="dx-my-3">{M('modals.restart-participation.payment.text-3')}</p>
                {!userDoesNotHaveEarningsNeitherCreditsAndThereAreNotProratedCharges && (
                    <>
                        <hr className={classNames('dx-mt-7 dx-mb-3', settingsStyles.separator_light)} />
                        <div>
                            <p className="font-open-sans--bold">
                                {M('modals.restart-participation.payment.price-restart')}
                            </p>
                            <div className="font-open-sans--bold d-flex dx-gx-3 align-items-baseline dx-mb-3">
                                <span className="dx-font-size-xxl">
                                    {paymentPreviewData ? (
                                        <DxNumber
                                            variant="dark"
                                            decimals={2}
                                            format="currency"
                                            currency={paymentPreviewData?.charges.currency}
                                            value={paymentPreviewData?.charges.restartCharge}
                                        />
                                    ) : (
                                        '---'
                                    )}
                                </span>
                            </div>
                            {paymentPreviewData?.charges && paymentPreviewData.charges.proratedCharges > 0 && (
                                <>
                                    <hr className={classNames('dx-mb-3', settingsStyles.separator_light)} />
                                    <p className="font-open-sans--bold">
                                        {M('modals.restart-participation.payment.prorated-price')}
                                    </p>
                                    <div className="font-open-sans--bold d-flex dx-gx-3 align-items-baseline dx-mb-3">
                                        <span className="dx-font-size-xxl">
                                            <DxNumber
                                                variant="dark"
                                                decimals={2}
                                                format="currency"
                                                currency={paymentPreviewData.charges.currency}
                                                value={paymentPreviewData.charges.proratedCharges}
                                            />
                                        </span>
                                    </div>
                                </>
                            )}
                            {paymentPreviewData?.credit && paymentPreviewData.credit.amount > 0 && (
                                <>
                                    <hr className={classNames('dx-mb-3', settingsStyles.separator_light)} />
                                    <p className="font-open-sans--bold">
                                        {M('modals.restart-participation.payment.credits')}
                                    </p>
                                    <div className="font-open-sans--bold d-flex dx-gx-3 align-items-baseline dx-mb-3">
                                        <span className="dx-font-size-xxl">
                                            <DxNumber
                                                colored
                                                variant="dark"
                                                decimals={2}
                                                format="currency"
                                                currency={paymentPreviewData.credit.currency}
                                                value={paymentPreviewData.credit.amount}
                                            />
                                        </span>
                                    </div>
                                </>
                            )}
                            {paymentPreviewData?.walletMoneyBefore &&
                                paymentPreviewData.walletMoneyBefore.baseCurrency.amount > 0 && (
                                    <>
                                        <hr className={classNames('dx-mb-3', settingsStyles.separator_light)} />
                                        <p className="font-open-sans--bold">
                                            {M('modals.restart-participation.payment.earnings-available-to-withdraw')}
                                        </p>
                                        <div className="font-open-sans--bold d-flex dx-gx-3 align-items-baseline dx-mb-3">
                                            <span className="dx-font-size-xxl">
                                                <span className="dx-font-size-xxl color-semantic-success-600">
                                                    <DxNumber
                                                        colored
                                                        variant="dark"
                                                        decimals={2}
                                                        format="currency"
                                                        currency={
                                                            paymentPreviewData.walletMoneyBefore.baseCurrency.currency
                                                        }
                                                        value={paymentPreviewData.walletMoneyBefore.baseCurrency.amount}
                                                    />
                                                </span>{' '}
                                                {paymentPreviewData.walletMoneyBefore.baseCurrency.currency !==
                                                    paymentPreviewData.walletMoneyBefore.walletCurrency.currency && (
                                                    <span className="dx-font-size-xxl color-semantic-success-600">
                                                        (
                                                        <DxNumber
                                                            colored
                                                            variant="dark"
                                                            decimals={2}
                                                            format="currency"
                                                            currency={
                                                                paymentPreviewData.walletMoneyBefore.walletCurrency
                                                                    .currency
                                                            }
                                                            value={
                                                                paymentPreviewData.walletMoneyBefore.walletCurrency
                                                                    .amount
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </>
                                )}
                            {paymentPreviewData &&
                                paymentPreviewData.walletMoneyBefore.baseCurrency.amount > 0 &&
                                onClickPayWithPFees && (
                                    <BooleanInput
                                        id="pay-with-pfees"
                                        name="pay-with-pfees"
                                        type="checkbox"
                                        checked={payWithPFees}
                                        onChange={onClickPayWithPFees}
                                        label={
                                            <span className="color-primary-500 font-open-sans--bold">
                                                {M('modals.restart-participation.payment.pay-with-earnings')}
                                            </span>
                                        }
                                        className="dx-my-5"
                                    />
                                )}
                        </div>
                        {payWithPFees && amountToPay !== null && amountToPay > 0 && (
                            <Alert
                                variant="info"
                                icon={<IconInfoEmpty width={24} height={24} viewBox="0 0 24 24" />}
                                shadow={false}
                            >
                                <span className="dx-font-size-lg font-open-sans--bold">
                                    {M('modals.pay-subscription-with-pfees.info')}
                                </span>
                            </Alert>
                        )}
                        {amountToPay !== null && amountToPay > 0 && (
                            <p className="dx-my-5">
                                {M('modals.restart-participation.payment.text-1', {
                                    price: getAmountWithCurrencyFormat(
                                        Math.max(0, amountToPay),
                                        paymentPreviewData?.charges.currency,
                                    ),
                                    card: paymentPreviewData?.paymentMethod.hint ?? '',
                                })}
                            </p>
                        )}
                        {payWithPFees && (
                            <p className="dx-my-5">
                                {M('modals.restart-participation.payment.pfees-available-after-payment', {
                                    available: remainingPFeesInBothCurrenciesAfterPaying,
                                })}
                            </p>
                        )}
                    </>
                )}
                <div className="dx-font-size-lg  text-right">
                    <p className="dx-mb-3 font-open-sans--bold">
                        {M('modals.restart-participation.payment.amount-due', {
                            currency: paymentPreviewData?.charges.currency ?? '---',
                        })}
                    </p>
                    <div className="font-open-sans--bold dx-font-size-h2">
                        {amountToPay !== null ? (
                            <DxNumber
                                decimals={Number.isInteger(Math.max(0, amountToPay)) ? 0 : 2}
                                format="currency"
                                currency={paymentPreviewData?.charges.currency as DxCurrencies}
                                value={Math.max(0, amountToPay)}
                            />
                        ) : (
                            '---'
                        )}
                    </div>
                </div>
                {paymentPreviewData?.nextBillingAtAfterRestart && (
                    <Alert
                        variant="info-light"
                        icon={<IconInfoEmpty width={24} height={24} viewBox="0 0 24 24" />}
                        shadow={false}
                    >
                        <p className="color-tertiary-800">
                            {M('modals.restart-participation.payment.subscription-renewal-date', {
                                subscriptionPaymentPeriod: getSubscriptionPaymentPeriod(),
                                date: date(paymentPreviewData?.nextBillingAtAfterRestart, { format: 'date' }),
                            })}
                        </p>
                    </Alert>
                )}
            </div>
            <div className="dx-mt-9 d-flex flex-wrap dx-g-5 align-items-center justify-content-between">
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onEditCreditCard}
                    size="z-md"
                    border="rect"
                    variant="secondary"
                    type="submit"
                >
                    {M('modals.restart-participation.payment.edit-cta')}
                </Button>
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onRestartSubscription}
                    size="z-md"
                    border="rect"
                    variant="primary"
                    type="submit"
                    disabled={restartRequested}
                >
                    {M('modals.restart-participation.payment.restart-cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default RestartParticipationDetails;
