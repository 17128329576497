import moment, { Moment } from 'moment';
import Nullable from '~/types/Nullable';

export const date = (value: Nullable<number | Moment>, config: { format: string; value2?: number }) => {
    let duration;

    if (!value) {
        return '---';
    }

    const momentDate = moment.utc(value);
    const yearFormat = 'y';

    switch (config.format) {
        case 'date':
            return momentDate.format('DD/MM/YYYY');
        case 'date2':
            return momentDate.format('D MMM YYYY');
        case 'dateTime':
            return momentDate.format('DD/MM/YYYY HH:mm');
        case 'dateTime2':
            return momentDate.format('DD/MM/YYYY HH:mm:ss');
        case 'dateTime3':
            return momentDate.format('DD/MM/YYYY HH:mm:ss.SSS');
        case 'time':
            return momentDate.format('HH.mm');
        case 'time2':
            return momentDate.format('HH:mm:ss');
        case 'time3':
            return momentDate.format('HH:mm:ss.SSS');
        case 'dateMonthYear':
            return momentDate.format('MMMM YYYY');
        case 'calendar':
            return momentDate.calendar();
        case 'fromNow':
            return momentDate.fromNow();
        case 'fromNowYearMonth':
            duration = moment.duration(moment.utc().valueOf() - momentDate.startOf('month').valueOf());
            if (duration.years() > 0 && duration.months() > 0) {
                return `${duration.years()}${yearFormat} ${duration.months()}m`;
            }
            if (duration.years() > 0) {
                return `${duration.years()}${yearFormat}`;
            }
            if (duration.months() > 0) {
                return `${duration.months()}m`;
            }
            return '';

        case 'duration':
            if (config.value2) {
                const momentDate2 = moment.utc(config.value2);
                const res = [];
                duration = moment.duration(momentDate2.valueOf() - momentDate.valueOf());
                if (duration.months() > 0) {
                    res.push(`${duration.months()} months`);
                } else if (duration.days() > 0) {
                    res.push(`${duration.days()} d.`);
                } else if (duration.hours() > 0) {
                    res.push(`${duration.hours()} h.`);
                } else if (duration.minutes() > 0) {
                    res.push(`${duration.minutes()} m.`);
                }
                return res.join(',');
            }
            return '';
        default:
            return momentDate.format(config.format);
    }
};
