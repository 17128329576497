import numeral from 'numeral';

export const getCurrencySymbol = (currency) => {
    switch (currency) {
        case 'EUR':
            return '€';

        case 'USD':
            return '$';

        case 'GBP':
            return '£';

        default:
            return ''; // Causes NULL_VALUE
    }
};

export const getCurrencyFormat = (currency) => {
    switch (currency) {
        case 'EUR':
            return '%d €';

        case 'USD':
            return '$ %d';

        case 'GBP':
            return '£ %d';

        default:
            return ''; // Causes NULL_VALUE
    }
};

export const getFormattedAmount = (amount, currency, threshold = 10000, numeralFormat = '0.0a') =>
    getCurrencyFormat(currency).replace(
        '%d',
        parseFloat(amount) > threshold
            ? numeral(parseFloat(amount)).format(numeralFormat).toUpperCase()
            : parseFloat(amount),
    );

export const getFormattedAmountRound = (amount, currency) => {
    const amountRound = Math.round(amount * 100) / 100;
    return getCurrencyFormat(currency).replace('%d', numeral(parseFloat(amountRound)).format('0,0.00'));
};

export const getAmountWithCurrency = (amount, currency, numeralFormat = '0,0') => {
    if (!currency) return '';
    return `${numeral(parseFloat(amount)).format(numeralFormat)} ${currency}`;
};

export const getAmountWithCurrencyFormat = (amount, currency, numeralFormat = '0,0[.]00') => {
    if (!currency) return '';
    return getCurrencyFormat(currency).replace('%d', `${numeral(parseFloat(amount)).format(numeralFormat)}`);
};

export const formatAmountThousandMillion = (value, precision = 2) => {
    let formatValue;
    const multiplier = 10 ** (precision || 0);
    if (value > 1000000) {
        formatValue = `${Math.round((value / 1000000) * multiplier) / multiplier}M`;
    } else if (value > 1000) {
        formatValue = `${Math.round((value / 1000) * multiplier) / multiplier}k`;
    } else {
        formatValue = value;
    }
    return formatValue;
};

export const getNumberWithDecimalsFormat = (number, numeralFormat = '0.[00]') => {
    if (!number || typeof number === 'undefined') return '---';
    return numeral(parseFloat(number)).format(numeralFormat);
};

export const getOrdinalNumber = (value) => {
    if (value < 1) {
        throw Error('Number must be greater than 0');
    }
    const j = value % 10;
    const k = value % 100;
    if (j === 1 && k !== 11) {
        return `${value}st`;
    }
    if (j === 2 && k !== 12) {
        return `${value}nd`;
    }
    if (j === 3 && k !== 13) {
        return `${value}rd`;
    }
    return `${value}th`;
};

export const getNumberWithAtLeast2Digit = (num) => {
    if (num <= 9) return `0${num}`;
    return num.toString();
};

export const getFormattedNumberDecimalsAndPositive = (number, precision = 2) => {
    const multiplier = 10 ** precision;
    if (number && number !== 0) {
        if (number > 0) return Math.floor(number * multiplier) / multiplier;
        return Math.ceil(number * multiplier) / multiplier;
    }
    return 0;
};

export const roundValue = (value, decimalPlaces = 2) => {
    const decimals = decimalPlaces === 0 ? 1 : 10 ** decimalPlaces;
    return Math.round(value * decimals) / decimals;
};

export const roundFloorValue = (value, decimalPlaces = 2) => {
    const decimals = decimalPlaces === 0 ? 1 : 10 ** decimalPlaces;
    return Math.floor(value * decimals) / decimals;
};

export const roundCeilValue = (value, decimalPlaces = 2) => {
    const decimals = decimalPlaces === 0 ? 1 : 10 ** decimalPlaces;
    return Math.ceil(value * decimals) / decimals;
};
