import React from 'react';

import { useModal } from '~/contexts/NextModalProvider';
import ModalError from '~/storybook/components/common/modal/modals/error/ModalError';

interface Props {
    configuration: Record<string, any> | null;
}

const GenericError = ({ configuration }: Props) => {
    const { closeModal } = useModal();

    const onModalClose = () => {
        closeModal();
    };

    return <ModalError onModalClose={() => onModalClose()} configuration={configuration} />;
};

export default GenericError;
