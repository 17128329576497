// eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions

import React, { useEffect, useState } from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { DateTime, Duration } from 'luxon';

// TODO Remove Polyfill when browsers support it. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DurationFormat
import '@formatjs/intl-durationformat/polyfill';

import {
    Button,
    IconArrow,
    IconChevronRounded,
    IconCloseRounded,
    IconSuccessCircleEmpty,
    IconWarningCircleHollow,
    SelectInput,
    Spinner,
} from '@darwinex/components-library';
import classNames from 'classnames';
import IconLogoDarwinexZeroMobile from '~/storybook/icons/IconLogoDarwinexZeroMobile';
import IconLogoDarwinexZero from '~/storybook/icons/IconLogoDarwinexZero';
import { type QuestionAnswersMap } from '~/hooks/services/useKnowledgeTest';

import signupStyles from '~/storybook/pages/platform/signup/SignUp.module.scss';
import { ServiceAvailability } from '~/enums/profile';
import TradingAccountTypeSelector from '~/storybook/components/common/trading-account-type-selector/TradingAccountTypeSelector';
import Nullable from '~/types/Nullable';
import { useProfile } from '~/contexts/NextProfileProvider';
import { usePricesService } from '~/hooks/services/usePricesService';
import { getAmountWithCurrencyFormat } from '~/utils/format';
import { getKeyByFamilyAndPlatform } from '~/utils/trading-accounts';
import { Currency } from '~/enums/Currency';
import styles from './KnowledgeTest.module.scss';

interface FormProps {
    onFormFinished: (answers: KnowledgeTestAnswers) => void;
    questions: QuestionAnswersMap[];
    onClose: React.MouseEventHandler;
    canExitTestWithoutFinishing?: boolean;
}

export interface KnowledgeTestAnswers {
    [questionId: string]: string;
}

const DOUBLE_QUESTIONS = ['FOREX_FREQUENCY', 'CFDS_FREQUENCY'];

export const KnowledgeTestForm = ({
    onFormFinished,
    questions,
    onClose,
    canExitTestWithoutFinishing = false,
}: FormProps) => {
    const { M } = useI18N();
    const [questionNumber, setQuestionNumber] = useState(0);
    const [answers, setAnswers] = useState<KnowledgeTestAnswers>({});

    const currentQuestion = questions[questionNumber];
    const currentQuestionIsDoubleQuestion = DOUBLE_QUESTIONS.includes(currentQuestion.questionId);
    const extraQuestion =
        currentQuestionIsDoubleQuestion &&
        answers[currentQuestion.questionId] &&
        !answers[currentQuestion.questionId].includes('NEVER')
            ? questions[questionNumber + 1]
            : undefined;

    const onClickAnswer = (question: string, newAnswer: string) => (_: React.MouseEvent<HTMLButtonElement>) => {
        let newAnswers = { ...answers };
        if (typeof newAnswers[question] !== 'undefined' && newAnswers[question] === newAnswer) {
            delete newAnswers[question];
        } else {
            newAnswers = {
                ...newAnswers,
                [question]: newAnswer,
            };
        }
        setAnswers(newAnswers);
    };

    const onGoBack = (event: any) => {
        if (questionNumber > 0) {
            const extraQuestion = questionNumber > 1 ? questionNumber - 2 : questionNumber - 1;
            const shouldDoExtraBack =
                extraQuestion >= 0 ? DOUBLE_QUESTIONS.includes(questions[extraQuestion].questionId) : false;
            setQuestionNumber((v) => v - (shouldDoExtraBack ? 2 : 1));
        } else if (canExitTestWithoutFinishing) {
            onClose(event);
        }
    };

    const onGoNext = () => {
        if (questionNumber < questions.length - 1) {
            if (currentQuestionIsDoubleQuestion) {
                setQuestionNumber((v) => v + 2);
            } else {
                setQuestionNumber((v) => v + 1);
            }
        } else {
            onFormFinished(answers);
        }
    };

    // Question Tracker at the bottom of the screen
    // const trackerCurrentQuestionNumber = questionNumber + 1;
    const trackerCurrentQuestionNumber =
        questions.slice(0, questionNumber).filter((it) => !DOUBLE_QUESTIONS.includes(it.questionId)).length + 1;
    const totalQuestionsNumber = questions.filter((it) => !DOUBLE_QUESTIONS.includes(it.questionId)).length;

    // Logic to decide if button should be enabled or disabled
    const currentQuestionHasAnswer = !!answers[currentQuestion.questionId];
    const extraQuestionHasAnswerIfNeeded = extraQuestion === undefined || !!answers[extraQuestion.questionId];
    const btnDisabled = !(currentQuestionHasAnswer && extraQuestionHasAnswerIfNeeded);

    return (
        <KnowledgeTestContainer onlyLogo={!canExitTestWithoutFinishing} onGoBack={onGoBack} onClose={onClose}>
            <div className={styles.content_container}>
                <h3 className={styles.content_title}>
                    {M(`knowledge-test.modals.form.q-${currentQuestion.questionId}`)}
                </h3>
                {!currentQuestionIsDoubleQuestion && (
                    <h5 className="dx-mt-4 dx-mb-7 text-center">{M(`knowledge-test.modals.form.choose-an-answer`)}</h5>
                )}
                {currentQuestionIsDoubleQuestion && (
                    <h4 className="dx-mt-7 dx-mb-5 font-open-sans--bold">
                        {M(`knowledge-test.modals.form.q-${currentQuestion.questionId}-extra`)}
                    </h4>
                )}
                <div className="d-flex flex-column">
                    {currentQuestion.answers.map((ans) => (
                        <button
                            key={ans}
                            type="button"
                            className={classNames(
                                styles.question_answer,
                                answers[currentQuestion.questionId] === ans && styles.selected,
                            )}
                            onClick={onClickAnswer(currentQuestion.questionId, ans)}
                        >
                            {M(`knowledge-test.modals.form.a-${ans}`)}
                        </button>
                    ))}
                </div>
                {extraQuestion && (
                    <div className="dx-mt-3 d-flex flex-column">
                        <h5 className="dx-mb-5 font-open-sans--bold">
                            {M(`knowledge-test.modals.form.q-${extraQuestion.questionId}`)}
                        </h5>
                        {extraQuestion.answers.map((ans) => (
                            <button
                                key={ans}
                                type="button"
                                className={classNames(
                                    styles.question_answer,
                                    answers[extraQuestion.questionId] === ans && styles.selected,
                                )}
                                onClick={onClickAnswer(extraQuestion.questionId, ans)}
                            >
                                {M(`knowledge-test.modals.form.a-${ans}`)}
                            </button>
                        ))}
                    </div>
                )}
                <div className="d-flex justify-content-between">
                    <Button
                        size="z-md"
                        border="rect"
                        type="button"
                        variant="secondary"
                        onClick={onGoBack}
                        disabled={questionNumber <= 0}
                    >
                        {M('commons.back')}
                    </Button>
                    <Button
                        size="z-md"
                        border="rect"
                        type="button"
                        variant="primary"
                        disabled={btnDisabled}
                        onClick={onGoNext}
                    >
                        {M('commons.next')}
                    </Button>
                </div>
            </div>
            <div className={styles.question_counter}>{`${trackerCurrentQuestionNumber} / ${totalQuestionsNumber}`}</div>
        </KnowledgeTestContainer>
    );
};

interface LoadingProps {
    onClose: React.MouseEventHandler;
}

export const KnowledgeTestLoading = ({ onClose }: LoadingProps) => (
    <KnowledgeTestContainer onlyLogo onClose={onClose}>
        <div className={styles.spinner_container}>
            <Spinner className={styles.spinner} size={48} />
        </div>
    </KnowledgeTestContainer>
);

interface SuccessProps {
    onCancel: React.MouseEventHandler;
    onContinue: React.MouseEventHandler;
}

export const KnowledgeTestSuccessWithPayment = ({ onContinue, onCancel }: SuccessProps) => {
    const { M } = useI18N();
    return (
        <KnowledgeTestContainer onlyLogo onClose={onCancel}>
            <div className={classNames(styles.content_container, 'dx-my-auto text-center')}>
                <IconSuccessCircleEmpty className="color-semantic-success-500" />
                <h3 className={styles.content_title}>{M('knowledge-test.modals.success.title')}</h3>
                <div className="dx-mt-4">{M('knowledge-test.modals.success.description')}</div>
                <div className="dx-mt-7 d-flex justify-content-between">
                    <Button size="z-md" border="rect" type="button" variant="secondary" onClick={onCancel}>
                        {M('knowledge-test.modals.success.cta-cancel')}
                    </Button>
                    <Button size="z-md" border="rect" type="button" variant="primary" onClick={onContinue}>
                        {M('knowledge-test.modals.success.cta-continue')}
                    </Button>
                </div>
            </div>
        </KnowledgeTestContainer>
    );
};

export const KnowledgeTestSuccessNoPayment = ({ onContinue }: { onContinue: React.MouseEventHandler }) => {
    const { M } = useI18N();
    return (
        <KnowledgeTestContainer onlyLogo>
            <div className={classNames(styles.content_container, 'dx-my-auto text-center')}>
                <IconSuccessCircleEmpty className="color-semantic-success-500" />
                <h3 className={styles.content_title}>{M('knowledge-test.modals.success-mandatory.title')}</h3>
                <div className="dx-mt-4">{M('knowledge-test.modals.success-mandatory.description')}</div>
                <Button
                    className="dx-mt-7"
                    size="z-md"
                    border="rect"
                    type="button"
                    variant="primary"
                    onClick={onContinue}
                >
                    {M('commons.continue')}
                </Button>
            </div>
        </KnowledgeTestContainer>
    );
};

interface ErrorProps {
    retryDateInMs?: number;
    onCancel: React.MouseEventHandler;
    onContinuePay: () => void;
    requestOriginCountry: Nullable<string>;
    previouslySelectedServiceId?: Nullable<string>;
    onChangeInfo: (
        tradingPlatform: string,
        familyType: string,
        familyAvailability: ServiceAvailability,
        currency: Currency,
        serviceId: string,
        couponValue?: Nullable<string>,
    ) => void;
    onChangeFinalPaymentAmount?: (finalPaymentAmount: number) => void;
}

export const KnowledgeTestErrorWithPayment = ({
    retryDateInMs,
    onCancel,
    onContinuePay,
    requestOriginCountry,
    previouslySelectedServiceId,
    onChangeInfo,
    onChangeFinalPaymentAmount,
}: ErrorProps) => {
    const { M, locale } = useI18N();
    const { profile } = useProfile();

    const [timeUntilRetry, setTimeUntilRetry] = useState<Duration | undefined>(undefined);
    const [tradingAccountSelectorOpen, setTradingAccountSelectorOpen] = useState<boolean>(false);
    const [selectedTradingPlatform, setSelectedTradingPlatform] = useState<Nullable<string>>(null);
    const [selectedFamilyType, setSelectedFamilyType] = useState<Nullable<string>>(null);
    const [selectedAccountAvailability, setSelectedAccountAvailability] = useState<Nullable<ServiceAvailability>>(null);
    const [selectedAccountCurrency, setSelectedAccountCurrency] = useState<Nullable<Currency>>(null);
    const [selectedServiceId, setSelectedServiceId] = useState<string>('');

    const { getPricesInfo } = usePricesService();

    const { data: pricesData, execute: executePricesRequest } = getPricesInfo(
        profile?.country ?? requestOriginCountry ?? '',
    );

    const toggleTradingAccountSelectorOpen = () => setTradingAccountSelectorOpen((prev) => !prev);

    const onClickSelectorOption = (
        tradingPlatform: string,
        familyType: string,
        accountAvailability: ServiceAvailability,
        currency: Currency,
    ) => {
        toggleTradingAccountSelectorOpen();
        setSelectedTradingPlatform(tradingPlatform);
        setSelectedFamilyType(familyType);
        setSelectedAccountAvailability(accountAvailability);
        setSelectedAccountCurrency(currency);
        if (previouslySelectedServiceId !== undefined && previouslySelectedServiceId !== null) {
            const previouslySelectedServiceInfo = pricesData?.find((service) =>
                service.priceInfo.find((price) => price.serviceId === previouslySelectedServiceId),
            );
            const previouslySelectedServicePrice = previouslySelectedServiceInfo?.priceInfo.find(
                (price) => price.serviceId === previouslySelectedServiceId,
            );
            const newSelectedServiceInfo = pricesData?.find(
                (service) =>
                    service.accountInfo.tradingPlatform === tradingPlatform &&
                    service.accountInfo.familyType === familyType &&
                    service.accountInfo.availability === accountAvailability &&
                    service.accountInfo.currency === currency,
            );
            const newSelectedServicePrice = newSelectedServiceInfo?.priceInfo.find(
                (p) => p.price.numOfMonths === previouslySelectedServicePrice?.price.numOfMonths,
            );
            if (newSelectedServicePrice !== undefined) {
                setSelectedServiceId(newSelectedServicePrice.serviceId);
                onChangeInfo(
                    tradingPlatform ?? '',
                    familyType ?? '',
                    accountAvailability ?? ServiceAvailability.UNAVAILABLE,
                    currency ?? Currency.USD,
                    newSelectedServicePrice.serviceId ?? '',
                );
            }
        } else {
            const newSelectedServiceInfo = pricesData?.find(
                (service) =>
                    service.accountInfo.tradingPlatform === tradingPlatform &&
                    service.accountInfo.familyType === familyType &&
                    service.accountInfo.availability === accountAvailability &&
                    service.accountInfo.currency === currency,
            );
            if (newSelectedServiceInfo !== undefined && newSelectedServiceInfo.priceInfo.length > 0) {
                setSelectedServiceId(newSelectedServiceInfo.priceInfo[0].serviceId);
                onChangeInfo(
                    tradingPlatform ?? '',
                    familyType ?? '',
                    accountAvailability ?? ServiceAvailability.UNAVAILABLE,
                    currency ?? Currency.USD,
                    newSelectedServiceInfo.priceInfo[0].serviceId ?? '',
                );
            }
        }
    };

    useEffect(() => {
        if (profile?.country !== '' && requestOriginCountry !== null) {
            executePricesRequest();
        }
    }, [profile?.country, requestOriginCountry]);

    useEffect(() => {
        if (retryDateInMs) {
            const retryDate = DateTime.fromMillis(retryDateInMs);
            const timerId = setInterval(() => {
                const diff = retryDate.diffNow(['days', 'hours', 'minutes', 'seconds', 'milliseconds']);
                setTimeUntilRetry(diff);
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [retryDateInMs]);

    const { days, hours, minutes, seconds } = timeUntilRetry?.toObject() ?? {};
    // This is resolved, is poly-filled above. Ignore the error.
    const timeRemainingInString = timeUntilRetry
        ? // @ts-expect-error This is polyfilled above
          new Intl.DurationFormat(locale, { style: 'narrow' }).format({ days, hours, minutes, seconds })
        : '---';

    const selectedAccountTypeInfo = pricesData?.find(
        (pricesInfo) =>
            pricesInfo.accountInfo.tradingPlatform === selectedTradingPlatform &&
            pricesInfo.accountInfo.familyType === selectedFamilyType,
    );

    const subscriptions =
        selectedAccountTypeInfo?.priceInfo.map((item) => ({
            value: item.serviceId,
            label: `${item.price.numOfMonths} ${
                item.price.numOfMonths > 1
                    ? M('components.select-payment-period.months')
                    : M('components.select-payment-period.month')
            } | ${M('components.select-payment-period.total-payment')}: ${getAmountWithCurrencyFormat(
                item.price.base,
                selectedAccountTypeInfo.accountInfo.currency,
                '0.[00]',
            )}`,
        })) ?? [];

    const onChangeSelectedServiceId = (serviceId: string) => {
        const selectedAccountInfo = pricesData?.find(
            (pricesInfo) =>
                pricesInfo.accountInfo.tradingPlatform === selectedTradingPlatform &&
                pricesInfo.accountInfo.familyType === selectedFamilyType &&
                pricesInfo.accountInfo.currency === selectedAccountCurrency,
        );
        const selectedAccountPriceInfo = selectedAccountInfo?.priceInfo.find(
            (priceInfo) => priceInfo.serviceId === serviceId,
        );
        if (selectedAccountPriceInfo !== undefined) {
            setSelectedServiceId(selectedAccountPriceInfo.serviceId);
            onChangeInfo(
                selectedTradingPlatform ?? '',
                selectedFamilyType ?? '',
                selectedAccountAvailability ?? ServiceAvailability.UNAVAILABLE,
                selectedAccountCurrency ?? Currency.USD,
                selectedAccountPriceInfo.serviceId ?? '',
            );
            if (onChangeFinalPaymentAmount !== undefined) {
                onChangeFinalPaymentAmount(selectedAccountPriceInfo.price.base + selectedAccountPriceInfo.price.extra);
            }
        }
    };

    return (
        <KnowledgeTestContainer onlyLogo onClose={onCancel}>
            <div className={classNames(styles.content_container, 'dx-my-auto text-center')}>
                <IconWarningCircleHollow className="color-semantic-danger-500" />
                <h3 className={styles.content_title}>{M('knowledge-test.modals.error.title')}</h3>
                <div className="dx-mt-5 dx-font-size-md">
                    {M('knowledge-test.modals.error.description', {
                        time: timeRemainingInString,
                    })}
                </div>

                <div className={classNames(signupStyles.selector_trading_account__container, 'dx-mt-5 text-left')}>
                    <div className="dx-mb-3 dx-font-size-md">
                        {M('components.select-trading-account.label')}
                        <span className="dx-ml-2 color-semantic-danger-500">*</span>
                    </div>
                    <div
                        onClick={toggleTradingAccountSelectorOpen}
                        className="dx-p-5 w-100-perc bg-tertiary-700 br-md d-flex justify-content-between cursor-pointer"
                    >
                        {selectedTradingPlatform !== null && selectedFamilyType !== null ? (
                            M(
                                `components.select-trading-account.${getKeyByFamilyAndPlatform(
                                    selectedFamilyType,
                                    selectedTradingPlatform,
                                )}.title`,
                            )
                        ) : (
                            <span className="color-secondary-500">
                                {M('components.select-trading-account.placeholder')}
                            </span>
                        )}
                        <IconChevronRounded
                            rotation={tradingAccountSelectorOpen ? 'up' : 'down'}
                            className="color-primary-500"
                            width={16}
                            height={16}
                        />
                    </div>
                    <TradingAccountTypeSelector
                        selectorOpen={tradingAccountSelectorOpen}
                        onClickSelectorOption={onClickSelectorOption}
                        servicesWithPricesData={
                            pricesData?.filter(
                                (priceInfo) => priceInfo.accountInfo.availability === ServiceAvailability.AVAILABLE,
                            ) ?? []
                        }
                    />
                </div>

                {(previouslySelectedServiceId === undefined || previouslySelectedServiceId === null) && (
                    <div className={classNames(signupStyles.selector_trading_account__container, 'dx-mt-5 text-left')}>
                        <div className="dx-mb-3 dx-font-size-md">
                            {M('components.select-payment-period.input')}
                            <span className="dx-ml-2 color-semantic-danger-500">*</span>
                        </div>
                        <SelectInput
                            size={1}
                            name="payment-period-selector"
                            id="payment-period-selector"
                            selected={selectedServiceId}
                            disabled={selectedTradingPlatform === null || selectedFamilyType === null}
                            options={subscriptions}
                            onChange={(e) => {
                                onChangeSelectedServiceId(e.target.value);
                            }}
                            inputClassName="bg-tertiary-700"
                        />
                    </div>
                )}
                <div className="dx-mt-7 d-flex dx-g-5 flex-column flex-xxl-row">
                    <Button size="z-md" border="rect" type="button" variant="secondary" onClick={onCancel}>
                        {M('knowledge-test.modals.error.cta-remind-me')}
                    </Button>
                    <Button size="z-md" border="rect" type="button" variant="primary" onClick={onContinuePay}>
                        {M('knowledge-test.modals.error.cta-do-pay')}
                    </Button>
                </div>
            </div>
        </KnowledgeTestContainer>
    );
};

interface ErrorOnboardingProps {
    onCancel: React.MouseEventHandler;
}

export const KnowledgeTestErrorNoPayment = ({ onCancel }: ErrorOnboardingProps) => {
    const { M, locale } = useI18N();

    return (
        <KnowledgeTestContainer onlyLogo onClose={onCancel}>
            <div className={classNames(styles.content_container, 'dx-my-auto text-center')}>
                <IconWarningCircleHollow className="color-semantic-danger-500" />
                <h3 className={styles.content_title}>{M('knowledge-test.modals.error-mandatory-fill.title')}</h3>
                <div className="dx-mt-5 dx-font-size-md">
                    {M('knowledge-test.modals.error-mandatory-fill.description')}
                </div>
                <div className="dx-mt-7 d-flex dx-g-5 flex-column align-items-center">
                    <Button size="z-md" border="rect" type="button" variant="secondary" onClick={onCancel}>
                        {M('commons.continue')}
                    </Button>
                </div>
            </div>
        </KnowledgeTestContainer>
    );
};

type ContainerProps =
    | {
          onlyLogo: true;
          children: React.ReactNode;
          onClose?: React.MouseEventHandler;
      }
    | {
          onlyLogo: false;
          children: React.ReactNode;
          onGoBack: React.MouseEventHandler;
          onClose?: React.MouseEventHandler;
      };

const KnowledgeTestContainer = (props: ContainerProps) => {
    // Cannot destructure props because type inference on onlyLogo breaks
    const { M } = useI18N();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {!props.onlyLogo && (
                    <div onClick={props.onGoBack} className="d-flex align-items-center">
                        <IconArrow rotation="left" className="dx-mr-3" height={16} width={16} />
                        {M('commons.back')}
                    </div>
                )}
                <div className="dx-mx-auto">
                    <IconLogoDarwinexZeroMobile className="d-md-none" />
                    <IconLogoDarwinexZero className="d-none d-md-block" />
                </div>
                {!props.onlyLogo && (
                    <div
                        className="d-flex align-items-center"
                        onClick={(event) => {
                            if (props.onClose) {
                                props.onClose(event);
                            } else {
                                window.history.back();
                            }
                        }}
                    >
                        <IconCloseRounded height={16} />
                    </div>
                )}
            </div>
            {props.children}
        </div>
    );
};
