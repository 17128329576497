import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { DarwinStatsInfo, OpenPositionInfo, useProviderPortalService } from '~/hooks/services/useProviderPortalService';
import { useTradingAccountService } from '~/hooks/services/useTradingAccountService';
import { useProfile } from '~/contexts/NextProfileProvider';
import { getFirstActiveParticipation } from '~/utils/participations';
import { GraphStates } from '@darwinex/components-library';
import { GraphTimeFrames } from '~/enums/graph-timeframes';
import moment from 'moment';
import Nullable from '~/types/Nullable';
import { isVisibleRef, useInactiveDetector } from '~/hooks/useInactiveDetector';

export type ClosureRate = {
    value: Nullable<number>;
    lastUpdateTime: Nullable<number>;
};

export type DarwinLiveTradesInfo = {
    productName: Nullable<string>;
    darwinRisk: Nullable<number>;
    targetVar: Nullable<number>;
    closureRate: ClosureRate;
    openPositions: Array<OpenPositionInfo>;
    darwinRiskGraphInfo: {
        data: Array<Array<number>>;
        yAxisMin: number;
        yAxisMax: number;
        status: GraphStates;
        selectedTimeFrame: GraphTimeFrames;
    };
    modifyDarwinRiskGraphTimeframe: (timeframe: GraphTimeFrames) => void;
    liveTradesRef: React.RefObject<HTMLDivElement> | null;
    getData: () => void;
};

const DarwinLiveTradesInfoContext = React.createContext<DarwinLiveTradesInfo>({
    productName: null,
    darwinRisk: null,
    targetVar: null,
    closureRate: {
        value: null,
        lastUpdateTime: null,
    },
    openPositions: [],
    darwinRiskGraphInfo: {
        data: [],
        yAxisMin: Infinity,
        yAxisMax: -Infinity,
        status: GraphStates.LOADING,
        selectedTimeFrame: GraphTimeFrames.YTD,
    },
    modifyDarwinRiskGraphTimeframe: (timeframe: GraphTimeFrames) => {},
    liveTradesRef: null,
    getData: () => {},
});

type DarwinLiveTradesInfoProviderProps = {
    children: ReactNode;
};

export const DarwinLiveTradesInfoProvider = ({ children }: DarwinLiveTradesInfoProviderProps) => {
    const liveTradesRef = useRef(null);

    const UPDATE_INTERVAL = 10000;

    const { profile, profileRequestFinished } = useProfile();
    const [currentActiveProduct, setCurrentActiveProduct] = useState<Nullable<string>>(null);
    const [darwinRiskGraphState, setDarwinRiskGraphState] = useState<GraphStates>(GraphStates.LOADING);
    const [darwinRiskGraphYAxisMin, setDarwinRiskGraphYAxisMin] = useState<number>(Infinity);
    const [darwinRiskGraphYAxisMax, setDarwinRiskGraphYAxisMax] = useState<number>(-Infinity);
    const [darwinRiskGraphTimeframe, setDarwinRiskGraphTimeframe] = useState<GraphTimeFrames>(GraphTimeFrames.YTD);
    const [filteredDarwinRiskGraphData, setFilteredDarwinRiskGraphData] = useState<Array<Array<number>>>([]);

    const { getRisk, getDarwinClosureRate, getOpenPositions } = useProviderPortalService();
    const { getDarwinRiskGraph } = useTradingAccountService();

    const [darwinRiskData, setDarwinRiskData] = useState<Nullable<number>>(null);
    const [riskData, setRiskData] = useState<DarwinStatsInfo>({
        // Uncomment below if needed:
        // monthlyDivergencePercentage: null,
        // monthlyLatencyInMs: null,
        // productId: null,
        productName: null,
        // strategyName: null,
        targetVarPercentage: null,
        varRatio: null,
    });

    const [closureRate, setClosureRate] = useState<ClosureRate>({
        value: null,
        lastUpdateTime: null,
    });

    const {
        data: riskDataRequest,
        status: riskDataRequestStatus,
        execute: executeRiskRequest,
    } = getRisk(currentActiveProduct);

    const { data: openPositionsData, execute: executeOpenPositionsRequest } = getOpenPositions(currentActiveProduct);

    const {
        data: closureRateDataRequest,
        status: closureRateRequestStatus,
        error: closureRateErrorRequest,
        execute: executeClosureRateRequest,
    } = getDarwinClosureRate(currentActiveProduct);

    const {
        data: darwinRiskGraphData,
        status: darwinRiskGraphRequestStatus,
        error: darwinRiskGraphRequestError,
        execute: executeDarwinRiskGraphRequest,
    } = getDarwinRiskGraph(currentActiveProduct);

    useEffect(() => {
        if (riskDataRequestStatus === 200) {
            const darwinInfo = riskDataRequest?.find((item) => item.productName === currentActiveProduct);

            if (darwinInfo) {
                setRiskData({
                    // Uncomment below if needed:
                    // monthlyDivergencePercentage: darwinInfo.monthlyDivergencePercentage,
                    // monthlyLatencyInMs: darwinInfo.monthlyLatencyInMs,
                    // productId: darwinInfo.productId,
                    productName: darwinInfo.productName,
                    // strategyName: darwinInfo.strategyName,
                    targetVarPercentage: darwinInfo.targetVarPercentage,
                    varRatio: darwinInfo.varRatio,
                });
            }
        }
    }, [riskDataRequestStatus]);

    useEffect(() => {
        if (
            closureRateRequestStatus === 200 &&
            closureRateDataRequest &&
            closureRateDataRequest.lastUpdateTime !== null
        ) {
            setClosureRate(closureRateDataRequest);
        } else if (closureRateErrorRequest) {
            setClosureRate({
                value: null,
                lastUpdateTime: null,
            });
        }
    }, [closureRateRequestStatus, closureRateErrorRequest]);

    useEffect(() => {
        if (darwinRiskGraphRequestStatus === 200) {
            filterDarwinGraphData(darwinRiskGraphTimeframe);
            setDarwinRiskGraphState(GraphStates.LOADED);
        }
    }, [darwinRiskGraphRequestStatus]);

    useEffect(() => {
        if (profileRequestFinished) {
            const participation = getFirstActiveParticipation(profile);
            if (participation?.tradingAccount?.productName) {
                setCurrentActiveProduct(participation.tradingAccount.productName);
            }
        }
    }, [profileRequestFinished]);

    useEffect(() => {
        if (darwinRiskGraphRequestError) {
            setDarwinRiskGraphState(GraphStates.EXTERNAL_ERROR);
        }
    }, [darwinRiskGraphRequestError]);

    const inactiveMouse = useInactiveDetector(5 * 60 * 1000);

    useEffect(() => {
        let interval: number | undefined;
        if (currentActiveProduct) {
            if (!inactiveMouse) {
                getDataInterval();
                interval = setInterval(() => {
                    getDataInterval();
                }, UPDATE_INTERVAL) as unknown as number;
            } else {
                clearInterval(interval);
            }
        }
        return () => {
            clearInterval(interval);
        };
    }, [currentActiveProduct, inactiveMouse]);

    const getDataInterval = () => {
        if (isVisibleRef(liveTradesRef) && !document.hidden) {
            getData();
        }
    };

    const getData = () => {
        executeRiskRequest();
        executeClosureRateRequest();
        executeOpenPositionsRequest();
        executeDarwinRiskGraphRequest();
    };

    const modifyDarwinRiskGraphTimeframe = (newTimeframe: GraphTimeFrames) => {
        setDarwinRiskGraphTimeframe(newTimeframe);
        filterDarwinGraphData(newTimeframe);
    };

    const filterDarwinGraphData = (timeframe: GraphTimeFrames) => {
        if (darwinRiskGraphData.length > 0) {
            const startTimestamp = getStartTimestampByTimeFrame(timeframe);
            const firstPointRisk = darwinRiskGraphData[0][1];
            const filteredGraphData = darwinRiskGraphData.filter(
                (point) => point.length > 0 && point[0] >= startTimestamp,
            );
            if (darwinRiskData) {
                filteredGraphData.push([moment.utc().valueOf(), darwinRiskData]);
            }
            setDarwinRiskGraphYAxisMin(firstPointRisk / 2);
            setDarwinRiskGraphYAxisMax(firstPointRisk * 2);
            setFilteredDarwinRiskGraphData(filteredGraphData);
        }
    };

    const getStartTimestampByTimeFrame = (timeframe: GraphTimeFrames): number => {
        const NOW = moment.utc();
        switch (timeframe) {
            case GraphTimeFrames.YTD:
                return NOW.startOf('year').valueOf();
            case GraphTimeFrames.W1:
                return NOW.subtract(1, 'week').valueOf();
            case GraphTimeFrames.M1:
                return NOW.subtract(1, 'month').valueOf();
            case GraphTimeFrames.M3:
                return NOW.subtract(3, 'months').valueOf();
            case GraphTimeFrames.M6:
                return NOW.subtract(6, 'months').valueOf();
            case GraphTimeFrames.Y1:
                return NOW.subtract(1, 'year').valueOf();
            case GraphTimeFrames.ALL:
                return 0;
            default:
                return 0;
        }
    };

    const DarwinLiveTradesValue = useMemo<DarwinLiveTradesInfo>(
        () => ({
            productName: currentActiveProduct,
            darwinRisk: riskData.varRatio || null,
            targetVar: riskData.targetVarPercentage || null,
            closureRate: closureRate || {
                value: null,
                lastUpdateTime: null,
            },
            openPositions: openPositionsData || [],
            darwinRiskGraphInfo: {
                data: filteredDarwinRiskGraphData,
                yAxisMin: darwinRiskGraphYAxisMin,
                yAxisMax: darwinRiskGraphYAxisMax,
                status: darwinRiskGraphState,
                selectedTimeFrame: darwinRiskGraphTimeframe,
            },
            modifyDarwinRiskGraphTimeframe,
            liveTradesRef,
            getData,
        }),
        [
            riskData.varRatio,
            riskData.targetVarPercentage,
            darwinRiskGraphYAxisMin,
            darwinRiskGraphYAxisMax,
            closureRate,
            openPositionsData,
            darwinRiskGraphState,
            filteredDarwinRiskGraphData,
            darwinRiskGraphTimeframe,
        ],
    );

    return (
        <DarwinLiveTradesInfoContext.Provider value={DarwinLiveTradesValue}>
            {children}
        </DarwinLiveTradesInfoContext.Provider>
    );
};

export const useDarwinLiveTradesInfo = () => {
    const context = React.useContext(DarwinLiveTradesInfoContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a NextModalProvider');
    }
    return context as DarwinLiveTradesInfo;
};
