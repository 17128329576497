import React from 'react';
import classNames from 'classnames';
import { useI18N } from '~/contexts/NextI18NProvider';
import { BREAKPOINT_MAP, Button, Modal } from '@darwinex/components-library';
import useWindowSize from '~/hooks/useWindowSize';

interface Props {
    onModalClose: React.MouseEventHandler;
    errorRestart?: string | null;
}

const RestartParticipationError = ({ onModalClose, errorRestart }: Props) => {
    const { M } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;
    return (
        <Modal size="md" type="danger" title={M('modals.restart-participation.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg font-open-sans--bold">
                {M('modals.restart-participation.error.subtitle')}
            </div>
            <div className="dx-font-size-lg dx-mt-4">
                {errorRestart ? M(errorRestart) : M('modals.restart-participation.error.description')}
            </div>
            <div className="dx-font-size-lg dx-mt-4">{M('modals.restart-participation.error.support')}</div>
            <div className="d-flex justify-content-end  dx-mt-10">
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    size="z-md"
                    variant="primary"
                    onClick={onModalClose}
                    border="rect"
                    type="button"
                >
                    {M('modals.restart-participation.error.cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default RestartParticipationError;
