import useFetch from '~/hooks/useFetch';
import { TradingAccountValidatorsInfo } from '~/storybook/pages/platform/darwin-page/DarwinPageView';
import { TradingJournalResponse } from '~/pages/api/tradingaccounts/[accountName]/trading-journal/[zoom]';
import { PlatformCredentialsInfo } from '~/storybook/pages/platform/darwin-page/modals/platform-credentials/PlatformCredentials';
import { TradingAccountBasicInfoResponse } from '~/pages/api/products/[productShortName]/basic-info';
import { GraphTimeFrames } from '~/enums/graph-timeframes';
import moment from 'moment';
import yearToDateFilter from '~/utils/graphs';
import Nullable from '../../types/Nullable';

interface State<T> {
    data: T;
    error?: Error;
    status?: number;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const TRADINGACCOUNTS_MS_URL = '/api/tradingaccounts';

export const useTradingAccountService = () => {
    const useGetTradingAccountBasicInfo = (
        productShortName: Nullable<string>,
    ): UseFetchReturnType<TradingAccountBasicInfoResponse> => {
        const { data, error, execute } = useFetch<TradingAccountBasicInfoResponse>(
            `${TRADINGACCOUNTS_MS_URL}/basics/${productShortName}`,
        );
        return {
            data: data || {
                accountName: null,
                accountType: null,
                status: null,
                ticker: null,
                username: null,
                registerDate: null,
                avatar: null,
                creationDate: null,
                connectionType: null,
                tradingJournalVisible: null,
                assetsVisible: null,
            },
            error,
            execute,
        };
    };

    const useGetValidatorsInfo = (accountName: Nullable<string>): UseFetchReturnType<TradingAccountValidatorsInfo> => {
        const { data, error, execute } = useFetch<TradingAccountValidatorsInfo>(
            `${TRADINGACCOUNTS_MS_URL}/detail/${accountName}/darwiniable-validations`,
        );

        return {
            data: data || { validators: [] },
            error,
            execute,
        };
    };

    const useTradingJournalData = (accountName: Nullable<string>): UseFetchReturnType<TradingJournalResponse> => {
        const { data, status, error, execute } = useFetch<TradingJournalResponse>(
            `${TRADINGACCOUNTS_MS_URL}/${accountName}/trading-journal/ALL`,
        );
        return {
            data: data || { tradingJournalOps: [], tradingJournal: [] },
            status,
            error,
            execute,
        };
    };

    const useTradingAccountCredentialsData = (
        accountName: Nullable<string>,
    ): UseFetchReturnType<PlatformCredentialsInfo> => {
        const { data, status, error, execute } = useFetch<PlatformCredentialsInfo>(
            `${TRADINGACCOUNTS_MS_URL}/${accountName}/credentials`,
        );
        return {
            data: data || {
                login: null,
                platform: null,
                serverName: null,
                serverUrl: null,
            },
            status,
            error,
            execute,
        };
    };

    const useGetDarwinRiskGraph = (productName: Nullable<string>): UseFetchReturnType<Array<Array<number>>> => {
        const { data, status, error, execute } = useFetch<Array<Array<number>>>(
            `${TRADINGACCOUNTS_MS_URL}/chart/${productName}/DARWIN_RISK/ALL/1`,
        );
        return {
            data: data || [],
            status,
            error,
            execute,
        };
    };

    const useSetTradingAccountVisibility = (
        accountName: Nullable<string>,
        visibilityAssets: boolean,
    ): UseFetchReturnType<any> => {
        const { data, status, error, execute } = useFetch(`${TRADINGACCOUNTS_MS_URL}/visibility/all`, {
            method: 'PUT',
            body: JSON.stringify({
                accountName,
                visibilityAssets,
                visibilityStrategy: true,
                visibilityTradingJournal: false,
            }),
        });
        return {
            data,
            status,
            error,
            execute: () => {
                if (accountName) {
                    execute();
                }
            },
        };
    };

    return {
        getTradingAccountBasicInfo: useGetTradingAccountBasicInfo,
        getValidatorsInfo: useGetValidatorsInfo,
        getTradingJournal: useTradingJournalData,
        getCredentialsData: useTradingAccountCredentialsData,
        getDarwinRiskGraph: useGetDarwinRiskGraph,
        setTradingAccountVisibility: useSetTradingAccountVisibility,
    };
};
