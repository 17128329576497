import classNames from 'classnames';
import { CSSProperties } from 'react';
import styles from './Spinner.module.scss';

interface Props {
    size?: 48 | 32 | 24 | 16;
    className?: string;
    style?: CSSProperties;
}

/**
 * Can change its color with css custom var "---loader-color"
 *
 * */
const Spinner = ({ size = 32, className, style }: Props) => (
    <div className={classNames(styles.loader, styles[`size${size}`], className)} style={style}>
        <span></span>
    </div>
);

export default Spinner;
