import React from 'react';

import Spinner from '~/storybook/components/common/spinner/Spinner';
import { Modal } from '@darwinex/components-library';

const GenericLoading = () => (
    <Modal size="md" type="plain" title="" onModalClose={() => {}} iconClose="">
        <div style={{ paddingTop: '60px', paddingBottom: '100px' }}>
            <div className="d-flex align-items-center justify-content-center">
                <Spinner size={48} style={{ '---loader-color': '#F7AA89' } as React.CSSProperties} />
            </div>
        </div>
    </Modal>
);

export default GenericLoading;
