import useFetch from '~/hooks/useFetch';

interface State<T> {
    data?: T;
    error?: Error;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

type T_GetNotificationsCheck = {
    lang: string;
};

type T_GetNotifications = {
    lang: string;
    page: number;
    per_page: number;
    status: string;
};

type T_GetImportantNotification = {
    lang: string;
};

type T_MarkNotificationAsRead = {
    notificationId: string;
};

type T_MarkNotificationAsUnread = {
    notificationId: string;
};

type T_MarkNotificationsSelectedAsRead = {
    selected: string[];
};

export type T_MarkNotificationsSelectedAsReadOutput = {
    marked: boolean;
};

export type T_UseArchiveNotificationSelectedOutput = {
    data: boolean;
};

export type T_GetNotificationsCheckOutput = {
    unread: boolean;
    important: boolean;
    numUnread: number;
    numImportant: number;
} | null;

export type T_NotificationOutput = {
    id: string;
    group: string;
    content: string;
    icon: {
        name: string;
        type: string;
    };
    image: string;
    shareableContent: {
        type: string;
        content: string;
        image: string;
        link: string;
    }[];
    action: {
        type: {
            type: string;
            ref: string;
        };
        ref: string;
    };
    unread: boolean;
    date: number;
};

export type T_GetNotificationsOutput = {
    all: number;
    unread: number;
    notifications: T_NotificationOutput[];
};

export type T_GetImportantNotificationOutput = {
    notificationData: T_NotificationOutput;
};

const URL_BASE_NOTIFICATIONS = '/api/webnotifications';

export const useNotificationsService = () => {
    const useGetNotificationsCheck = <T_GetNotificationsCheckOutput>(
        options: T_GetNotificationsCheck,
    ): UseFetchReturnType<T_GetNotificationsCheckOutput> => {
        const { lang } = options;
        const { data, error, execute } = useFetch(`${URL_BASE_NOTIFICATIONS}/notifications/check?lang=${lang}`);
        return {
            data: data as T_GetNotificationsCheckOutput,
            error,
            execute,
        };
    };

    const useGetNotifications = <T_GetNotificationsOutput>(
        options: T_GetNotifications,
    ): UseFetchReturnType<T_GetNotificationsOutput> => {
        const { lang, page, per_page, status } = options;
        const { data, error, execute } = useFetch(
            `${URL_BASE_NOTIFICATIONS}/notifications?lang=${lang}&page=${page}&per_page=${per_page}&status=${status}`,
        );
        return {
            data: data as T_GetNotificationsOutput,
            error,
            execute,
        };
    };

    const useGetImportantNotification = <T_GetNotificationsOutput>(
        options: T_GetImportantNotification,
    ): UseFetchReturnType<T_GetNotificationsOutput> => {
        const { lang } = options;
        const { data, error, execute } = useFetch(`${URL_BASE_NOTIFICATIONS}/notifications/important?lang=${lang}`);
        return {
            data: data as T_GetNotificationsOutput,
            error,
            execute,
        };
    };

    const useMarkNotificationAsRead = <T_GetNotificationsCheckOutput>(
        options: T_MarkNotificationAsRead,
    ): UseFetchReturnType<T_GetNotificationsCheckOutput> => {
        const { notificationId } = options;
        const { data, error, execute } = useFetch(
            `${URL_BASE_NOTIFICATIONS}/notifications/read?notificationId=${notificationId}`,
            {
                method: 'POST',
            },
        );
        return {
            data: data as T_GetNotificationsCheckOutput,
            error,
            execute,
        };
    };

    const useMarkAllNotificationsAsRead = <
        T_GetNotificationsCheckOutput,
    >(): UseFetchReturnType<T_GetNotificationsCheckOutput> => {
        const { data, error, execute } = useFetch(`${URL_BASE_NOTIFICATIONS}/notifications/readall`, {
            method: 'POST',
        });
        return {
            data: data as T_GetNotificationsCheckOutput,
            error,
            execute,
        };
    };

    const useMarkNotificationsSelectedAsRead = <T_GetNotificationsCheckOutput>(
        options: T_MarkNotificationsSelectedAsRead,
    ): UseFetchReturnType<T_GetNotificationsCheckOutput> => {
        const { selected } = options;
        const notificationIds = selected.join(',');

        const { data, error, execute } = useFetch(
            `${URL_BASE_NOTIFICATIONS}/notifications/read?notificationIds=${notificationIds}`,
            {
                method: 'POST',
            },
        );
        return {
            data: data as T_GetNotificationsCheckOutput,
            error,
            execute,
        };
    };

    /*
    const useMarkNotificationsSelectedAsRead = <T_MarkNotificationsSelectedAsReadOutput>(
        options: T_MarkNotificationsSelectedAsRead,
    ) => {
        const { selected } = options;

        const notificationsSelectedRequests: Promise<any>[] = [];
        selected.forEach((id) => {
            notificationsSelectedRequests.push(
                fetch(`${URL_BASE_NOTIFICATIONS}/notifications/read?notificationId=${id}`, {
                    method: 'POST',
                }),
            );
        });

        return Promise.all(notificationsSelectedRequests)
            .then((responsesNotificationsSelected) => Promise.all(responsesNotificationsSelected.map((r) => r.json())))
            .then((responsesNotificationsSelected) => {
                const error = responsesNotificationsSelected.filter((res) => typeof res.error !== 'undefined');
                return {
                    marked: error.length === 0,
                };
            })
            .catch((e) => ({
                marked: false,
            }));
    };
    */

    const useMarkNotificationAsUnread = <T_GetNotificationsCheckOutput>(
        options: T_MarkNotificationAsUnread,
    ): UseFetchReturnType<T_GetNotificationsCheckOutput> => {
        const { notificationId } = options;
        const { data, error, execute } = useFetch(
            `${URL_BASE_NOTIFICATIONS}/notifications/unread?notificationId=${notificationId}`,
            {
                method: 'POST',
            },
        );
        return {
            data: data as T_GetNotificationsCheckOutput,
            error,
            execute,
        };
    };

    const useArchiveNotification = <T>(options: T_MarkNotificationAsUnread): UseFetchReturnType<T> => {
        const { notificationId } = options;
        const { data, error, execute } = useFetch(
            `${URL_BASE_NOTIFICATIONS}/notifications/archive?notificationId=${notificationId}`,
            {
                method: 'POST',
            },
        );
        return {
            data: data as T,
            error,
            execute,
        };
    };

    const useArchiveNotificationSelected = <T>(options: T_MarkNotificationsSelectedAsRead): UseFetchReturnType<T> => {
        const { selected } = options;
        const notificationIds = selected.join(',');

        const { data, error, execute } = useFetch(
            `${URL_BASE_NOTIFICATIONS}/notifications/archive?notificationIds=${notificationIds}`,
            {
                method: 'POST',
            },
        );
        return {
            data: data as T,
            error,
            execute,
        };
    };

    /*
    const useArchiveNotificationSelected = <T_UseArchiveNotificationSelectedOutput>(
        options: T_MarkNotificationsSelectedAsRead,
    ) => {
        const { selected } = options;

        const notificationsSelectedRequests: Promise<any>[] = [];
        selected.forEach((id) => {
            notificationsSelectedRequests.push(
                fetch(`${URL_BASE_NOTIFICATIONS}/notifications/archive?notificationId=${id}`, {
                    method: 'POST',
                }),
            );
        });

        return Promise.all(notificationsSelectedRequests)
            .then((responsesNotificationsSelected) => Promise.all(responsesNotificationsSelected.map((r) => r.json())))
            .then(() => ({
                data: true,
            }))
            .catch((e) => ({
                data: true,
            }));
    };
     */

    return {
        getNotificationsCheck: useGetNotificationsCheck,
        getNotifications: useGetNotifications,
        getImportantNotification: useGetImportantNotification,
        markNotificationAsRead: useMarkNotificationAsRead,
        markAllNotificationsAsRead: useMarkAllNotificationsAsRead,
        markNotificationAsUnread: useMarkNotificationAsUnread,
        archiveNotification: useArchiveNotification,
        markNotificationsSelectedAsRead: useMarkNotificationsSelectedAsRead,
        archiveNotificationSelected: useArchiveNotificationSelected,
    };
};
