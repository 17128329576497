export enum ParticipationStatus {
    ONBOARDING = 'ONBOARDING',
    ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
    TRAINING = 'TRAINING',
    TRAINING_COMPLETE = 'TRAINING_COMPLETE',
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
}

export enum EmailStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
}

export enum SubscriptionStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    RECURRENT = 'RECURRENT',
    RENEWED = 'RENEWED',
    TO_EXPIRE = 'TO_EXPIRE',
    EXPIRED = 'EXPIRED',
    TO_CANCEL = 'TO_CANCEL',
    CANCELLED = 'CANCELLED',
}

export enum NextSubscriptionStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    UNPAID = 'UNPAID',
    CANCELLED = 'CANCELLED',
}

export enum TypeProfile {
    PARTICULAR = 'PARTICULAR',
    CORPORATIVE = 'CORPORATIVE',
}

export enum AccountType {
    EURMT5FOREX = 'EURMT5FOREX',
    EURMT5FUTURES = 'EURMT5FUTURES',
    EURMT5STOCKS_USD = 'EURMT5STOCKS_USD',
    EURMT4FOREX = 'EURMT4FOREX',
    USDMT5FOREX = 'USDMT5FOREX',
    USDMT5FUTURES = 'USDMT5FUTURES',
    USDMT5STOCKS_USD = 'USDMT5STOCKS_USD',
    USDMT4FOREX = 'USDMT4FOREX',
}

export enum AccountFamilyType {
    FOREX = 'FOREX',
    FUTURES = 'FUTURES',
    STOCKS = 'STOCKS',
}

export enum ServiceAvailability {
    UNAVAILABLE = 'UNAVAILABLE',
    RESTRICTED = 'RESTRICTED',
    AVAILABLE = 'AVAILABLE',
}

export type TradingAccountCheckoutInfo = {
    value: string;
    label: string;
    description: string;
    labelExtra?: string;
    descriptionExtra?: string;
    alertExtra?: string;
    linkExtra?: string;
    familyType: AccountFamilyType;
    disabledTradingAccount?: boolean;
    knowledgeTestAvailability?: ServiceAvailability;
    disabledWarning?: string;
};

export type TradingAccountsAvailable = Record<AccountType, TradingAccountCheckoutInfo>;

export enum RegulatedCompanies {
    FCA = 'DX_FCA',
    CNMV = 'DX_CNMV',
    SYC = 'DX_SYC',
}

const MT5Info = {
    webTerminal: 'https://www.darwinexzero.com/webterminal',
    loadWindows: 'https://download.mql5.com/cdn/web/tradeslide.trading.tech/mt5/darwinex5setup.exe',
    loadMacOS:
        'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=www.metatrader4.com&utm_campaign=download.mt5.macos',
    loadLinux: 'https://www.mql5.com/es/articles/625?utm_source=www.metatrader4.com&utm_campaign=download.mt5.linux',
    loadAndroid: 'https://download.metatrader.com/cdn/mobile/mt5/android?server=Darwinex-Demo,Darwinex-Live',
    loadIOS: 'https://download.metatrader.com/cdn/mobile/mt5/ios?server=Darwinex-Demo,Darwinex-Live',
};

export const URLS_DOWNLOAD = {
    MT4FOREX: {
        webTerminal: 'https://www.darwinexzero.com/webterminal',
        loadWindows: 'https://download.mql5.com/cdn/web/tradeslide.trading.tech/mt4/darwinex4setup.exe',
        loadMacOS:
            'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg?utm_source=www.metatrader4.com&utm_campaign=download.mt4.macos',
        loadLinux:
            'https://www.mql5.com/es/articles/1358?utm_source=www.metatrader4.com&utm_campaign=download.mt4.linux',
        loadAndroid: 'https://download.metatrader.com/cdn/mobile/mt4/android?server=Darwinex-Demo,Darwinex-Live',
        loadIOS: 'https://download.metatrader.com/cdn/mobile/mt4/ios?server=Darwinex-Demo,Darwinex-Live',
    },
    MT5FOREX: MT5Info,
    MT5STOCKS_USD: MT5Info,
    MT5FUTURES: MT5Info,
};
