import React from 'react';
import classNames from 'classnames';
import IconLogoDarwinexZero from '~/storybook/icons/IconLogoDarwinexZero';
import IconLogoDarwinexZeroMobile from '~/storybook/icons/IconLogoDarwinexZeroMobile';

import styles from './Navbar.module.scss';

const NavbarEmpty = () => (
    <header className={classNames(styles.navbar)}>
        <div className={classNames(styles['navbar--right'])}>
            <div className={classNames(styles.navbar__top)}>
                <IconLogoDarwinexZeroMobile className={classNames(styles['navbar__logo--mobile'])} />
                <IconLogoDarwinexZero className={classNames(styles['navbar__logo--desk'])} />
            </div>
        </div>
    </header>
);

export default NavbarEmpty;
