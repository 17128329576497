import React, { useEffect, useState } from 'react';

import { useProfile } from '~/contexts/NextProfileProvider';
import { useModal } from '~/contexts/NextModalProvider';
import { T_ProfileResponse } from '~/pages/api/profile';
import { useProfileService } from '~/hooks/services/useProfileService';
import { T_StartParticipationOutput, useParticipationService } from '~/hooks/services/useParticipationService';
import { useRouter } from 'next/router';
import SBReactivateParticipation from '~/storybook/pages/platform/settings/modals/reactivate-participation/ReactivateParticipation';
import SBReactivateParticipationSuccess from '~/storybook/pages/platform/settings/modals/reactivate-participation/ReactivateParticipationSuccess';
import { getFirstActiveParticipation } from '~/utils/participations';
import { sendDataLayerEvent } from '~/utils/analytics';
import { T_PartialProfile, UpdateProfileType } from '~/services/profile';

const ReactiveParticipation = () => {
    const router = useRouter();

    const { profile, setPartialProfile } = useProfile();
    const { getProfileInfo } = useProfileService();
    const { closeModal, setModal } = useModal();

    const [participationId, setParticipationId] = useState<string>('');
    const { reactiveParticipation } = useParticipationService();
    const participation = getFirstActiveParticipation(profile);

    const [participationSuccess, setParticipationSuccess] = useState<boolean>(false);

    const [reactiveRequested, setReactiveRequested] = useState<boolean>(false);

    useEffect(() => {
        if (participationId) {
            setReactiveRequested(true);
            doReactiveParticipation();
        }
    }, [participationId]);

    const {
        data: reactiveParticipationData,
        error: reactiveParticipationError,
        execute: doReactiveParticipation,
    } = reactiveParticipation<T_StartParticipationOutput>({
        participationId: participationId || '',
    });

    useEffect(() => {
        if (reactiveParticipationData) {
            sendDataLayerEvent({ event: 'reactivate_subscription' });
            setParticipationSuccess(true);
        } else if (reactiveParticipationError) {
            setReactiveRequested(false);
            setModal('generic-error');
        }
    }, [reactiveParticipationData, reactiveParticipationError]);

    const onCloseSuccessReactive = () => {
        doGetUserData();
        setReactiveRequested(false);
    };

    const {
        data: userData,
        error: userError,
        execute: doGetUserData,
    } = getProfileInfo<T_PartialProfile>([UpdateProfileType.PARTICIPATION_SUBSCRIPTION]);

    useEffect(() => {
        if (userData) {
            closeModal();
            setPartialProfile(userData);
        } else if (userError) {
            closeModal();
            router.push('/500');
        }
    }, [userData, userError]);

    const onModalClose = () => {
        closeModal();
        if (reactiveParticipationError) {
            // router.push('/500');
            setModal('generic-error');
        }
    };

    return !participationSuccess ? (
        <SBReactivateParticipation
            onModalClose={() => onModalClose()}
            onReactiveSubscription={() => {
                if (participation) {
                    setParticipationId(participation.id);
                } else {
                    closeModal();
                }
            }}
            reactiveRequested={reactiveRequested}
        />
    ) : (
        <SBReactivateParticipationSuccess onModalClose={() => onCloseSuccessReactive()} />
    );
};

export default ReactiveParticipation;
