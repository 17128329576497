const config = {
    locales: ['en', 'es', 'fr', 'zh', 'it', 'cs', 'de'],
    untranslatedLocalesPath: ['en', 'zh', 'cs', 'de'],
    defaultLocale: 'en',
    localeDetection: false,
    localeFolders: {
        en: 'en',
        es: 'es',
        fr: 'fr',
        zh: 'zh',
        it: 'it',
        cs: 'cs',
        de: 'de',
    },
};
module.exports = config;
