import { ParticipationStatus, RegulatedCompanies } from '~/enums/profile';
import { RequirementsStatus } from '~/enums/requirements-status';
import { getIKS, KYSPromise, T_KYS } from '~/services/kys';
import { getMigration, INIT_MIGRATION, MigrationResponse } from '~/services/migration';
import { getUserInfo, UserInfo } from '~/services/user-info';
import Nullable from '~/types/Nullable';
import { getUserPreferences, UserPreferences } from '~/services/user-preferences';
import {
    getParticipationAndSubscription,
    ParticipationAndSubscription,
    T_Participation,
    T_SubscriptionStatus,
} from '~/services/participation-subscription';
import { getUserNotifications, UserNotification, UserNotifications } from '~/services/user-notifications';
import { getFirstParticipation } from '~/utils/participations';
import { getAllLegalDocuments, LegalDocuments, T_Legal } from './legal-documents';

export type RequirementsStatusKYS = RequirementsStatus | null;

export type T_Profile = {
    username: string;
    publicId: string;
    avatarUrl: Nullable<string>;
    locale: string;
    email: string;
    emailNew: string;
    emailStatus: string;
    country: Nullable<string>;
    regulatedCompany: RegulatedCompanies | null;
    registerDate: number | null;
    memberGetMemberCode: Nullable<string>;
    participations: T_Participation[];
    subscriptions: T_SubscriptionStatus[];
    legalDocuments: T_Legal[];
    KYS: T_KYS;
    visualizationPreferences: Record<string, string>;
    migrationDarwin: MigrationResponse;
    notifications: UserNotification[];
};

export type T_PartialProfile = Partial<T_Profile>;

export enum UpdateProfileType {
    USER_INFO = 'USER_INFO',
    USER_PREFERENCES = 'USER_PREFERENCES',
    USER_NOTIFICATIONS = 'USER_NOTIFICATIONS',
    LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
    MIGRATION = 'MIGRATION',
    KYS = 'KYS',
    PARTICIPATION_SUBSCRIPTION = 'PARTICIPATION_SUBSCRIPTION',
}

type PromisesProfileType =
    | UserInfo
    | UserPreferences
    | UserNotifications
    | LegalDocuments
    | KYSPromise
    | ParticipationAndSubscription;

export async function getProfileInfo(
    headers: Headers,
    updateProfile?: UpdateProfileType[],
): Promise<T_PartialProfile | T_Profile> {
    const allRequests = [
        {
            updateTypes: [UpdateProfileType.USER_INFO],
            service: getUserInfo(headers),
        },
        {
            updateTypes: [UpdateProfileType.USER_PREFERENCES],
            service: getUserPreferences(headers),
        },
        {
            updateTypes: [UpdateProfileType.USER_NOTIFICATIONS, UpdateProfileType.MIGRATION],
            service: getUserNotifications(headers),
        },
        {
            updateTypes: [UpdateProfileType.LEGAL_DOCUMENTS, UpdateProfileType.MIGRATION],
            service: getAllLegalDocuments(headers),
        },
        {
            updateTypes: [UpdateProfileType.KYS],
            service: getIKS(headers),
        },
        {
            updateTypes: [UpdateProfileType.PARTICIPATION_SUBSCRIPTION, UpdateProfileType.MIGRATION],
            service: getParticipationAndSubscription(headers),
        },
    ];

    const requestsUpdate: Promise<PromisesProfileType>[] = allRequests
        .filter((request) => !updateProfile || request.updateTypes.some((item) => updateProfile.includes(item)))
        .map((request) => request.service);

    const getMigrationRequest = async (
        participations: T_Participation[],
        legalDocuments: T_Legal[],
        notifications: UserNotification[],
    ) => {
        if (!updateProfile || updateProfile.includes(UpdateProfileType.MIGRATION)) {
            const participation = participations.length > 0 ? participations[0] : null;
            if (
                participation &&
                [ParticipationStatus.ACTIVE as string, ParticipationStatus.CANCELLED as string].includes(
                    participation.status,
                ) &&
                participation?.tradingAccount?.productName
            ) {
                return await getMigration(
                    headers,
                    participation?.tradingAccount?.productName ?? '',
                    legalDocuments,
                    notifications,
                );
            }
            return {
                ...INIT_MIGRATION,
            };
        }
        return null;
    };

    return Promise.all(requestsUpdate)
        .then(async (responses) => {
            let newProfile: T_PartialProfile;
            newProfile = responses.reduce((pro, curr) => ({ ...pro, ...curr }), {});

            const migrationDarwin = await getMigrationRequest(
                newProfile?.participations ?? [],
                newProfile?.legalDocuments ?? [],
                newProfile?.notifications ?? [],
            );
            newProfile = {
                ...newProfile,
                ...migrationDarwin,
            };

            return updateProfile ? (newProfile as T_PartialProfile) : (newProfile as T_Profile);
        })
        .catch((r) => {
            throw new Error('Error fetching profile data', r.err as Error);
        });
}
