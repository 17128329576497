import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { getCookie } from 'cookies-next';

/**
 * Defines the Notifications Context for the whole app
 */

import { NextRouter, useRouter } from 'next/router';
import { useI18N } from '~/contexts/NextI18NProvider';
import { T_GetNotificationsCheckOutput, useNotificationsService } from '~/hooks/services/useNotificationsService';

type NotificationsProvider = {
    notifications: T_GetNotificationsCheckOutput;
    setNotifications: (notifications: T_GetNotificationsCheckOutput) => void;
    doGetNotificationsCheck: () => void;
};

const NextNotificationsContext = React.createContext<NotificationsProvider | null>(null);

type NextNotificationsProviderProps = {
    children: ReactNode;
};

export const NextNotificationsProvider = ({ children }: NextNotificationsProviderProps) => {
    const { getNotificationsCheck, getNotifications, getImportantNotification } = useNotificationsService();

    let router: NextRouter | null = null;
    let userLocale = 'en';

    try {
        router = useRouter();
        userLocale = useI18N().locale;
    } catch (errorRouter) {}

    const [notifications, setNotifications] = useState<T_GetNotificationsCheckOutput>(null);

    const { data: notificationsCheckData, execute: doGetNotificationsCheck } =
        getNotificationsCheck<T_GetNotificationsCheckOutput>({
            lang: userLocale,
        });

    useEffect(() => {
        if (getCookie('logged') === true) {
            doGetNotificationsCheck();
        }
    }, []);

    useEffect(() => {
        if (router?.isReady && !router?.pathname.match(/404|500|403|logout/) && getCookie('logged') === true) {
            doGetNotificationsCheck();
        }
    }, [router?.pathname]);

    useEffect(() => {
        if (notificationsCheckData) {
            const notificationsCheck = notificationsCheckData as T_GetNotificationsCheckOutput;
            setNotifications(notificationsCheck);
        }
    }, [notificationsCheckData]);

    const providerValue = useMemo<NotificationsProvider>(
        () => ({ notifications, setNotifications, doGetNotificationsCheck }),
        [notifications, setNotifications, doGetNotificationsCheck],
    );

    return <NextNotificationsContext.Provider value={providerValue}>{children}</NextNotificationsContext.Provider>;
};

export const useNotifications = () => {
    const context = React.useContext(NextNotificationsContext);
    if (context === undefined) {
        throw new Error('useNotifications must be used within a NextNotificationsProvider');
    }
    return context as NotificationsProvider;
};
