import useFetch from '~/hooks/useFetch';
import Nullable from '~/types/Nullable';
import { Currency } from '~/enums/Currency';
import { ServiceAvailability } from '~/enums/profile';
import { useState } from 'react';

interface State<T> {
    data?: T;
    error?: Error;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const URL_BASE_PARTICIPATIONS = '/api/bootcamp-participation/participations';

type T_StartParticipation = {
    participationId: string;
};

type T_CancelParticipation = {
    participationId: string;
};

type T_ReactiveParticipation = {
    participationId: string;
};

type T_RestartParticipation = {
    participationId: string;
    tradingPlatform: string;
    familyType: string;
    currency: Currency;
    country: Nullable<string>;
    serviceId: string;
    payWithYourEarnings?: boolean;
};

export type T_StartParticipationOutput = {
    brokerAccountNumber: string;
    password: string;
    investorPassword: string;
    url: string;
    serverName: string;
    ticker?: string | null;
} | null;

// todo: Remove anys and replace with actual types and remove unused generics

export const useParticipationService = () => {
    const useStartParticipation = <T_StartParticipationOutput>(
        options: T_StartParticipation,
    ): UseFetchReturnType<T_StartParticipationOutput> => {
        const { participationId } = options;
        const { data, error, execute } = useFetch(`${URL_BASE_PARTICIPATIONS}/${participationId}/start`, {
            method: 'PUT',
        });
        return {
            data: data as T_StartParticipationOutput,
            error,
            execute,
        };
    };

    const useCancelParticipation = <T_CancelParticipationOutput>(
        options: T_CancelParticipation,
    ): UseFetchReturnType<T_CancelParticipationOutput> => {
        const { participationId } = options;
        const { data, error, execute } = useFetch(`${URL_BASE_PARTICIPATIONS}/${participationId}/cancel`, {
            method: 'PUT',
        });
        return {
            data: data as T_CancelParticipationOutput,
            error,
            execute,
        };
    };

    const useCancelParticipationManual = <T_CancelParticipationOutput>(
        options: T_CancelParticipation,
    ): UseFetchReturnType<T_CancelParticipationOutput> => {
        const { participationId } = options;
        const { data, error, execute } = useFetch(
            `${URL_BASE_PARTICIPATIONS}/${participationId}/request-manual-cancellation`,
            {
                method: 'POST',
            },
        );
        return {
            data: data as T_CancelParticipationOutput,
            error,
            execute,
        };
    };

    const useReactiveParticipation = <T_ReactiveParticipationOutput>(
        options: T_ReactiveParticipation,
    ): UseFetchReturnType<T_ReactiveParticipationOutput> => {
        const { participationId } = options;
        const { data, error, execute } = useFetch(`${URL_BASE_PARTICIPATIONS}/${participationId}/reactivate`, {
            method: 'PUT',
        });
        return {
            data: data as T_ReactiveParticipationOutput,
            error,
            execute,
        };
    };

    // todo: add type for output data to remove any
    const useRestartParticipation = (options: T_RestartParticipation): UseFetchReturnType<any> => {
        const { participationId, familyType, tradingPlatform, currency, country, payWithYourEarnings, serviceId } =
            options;
        const { data, error, execute } = useFetch(`${URL_BASE_PARTICIPATIONS}/${participationId}/restart`, {
            method: 'POST',
            body: JSON.stringify({
                familyType,
                tradingPlatform,
                currency,
                payWithYourEarnings,
                country,
                serviceId,
            }),
        });
        return {
            data,
            error,
            execute,
        };
    };

    return {
        startParticipation: useStartParticipation,
        cancelParticipation: useCancelParticipation,
        cancelParticipationManual: useCancelParticipationManual,
        reactiveParticipation: useReactiveParticipation,
        restartParticipation: useRestartParticipation,
    };
};
