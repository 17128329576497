/**
 * Similar to lodash._get function, this allows for return of deeply nested object by passing an array or string as the key
 * @function deepGet
 * @param {{}} obj The object to extract the value from
 * @param {(string|[])} key The path to the value inside the object passed.
 * @param {*} [defVal=null] Default value if the path doesn't exist in object. Defaults to null
 * @returns {*} Returns the value extracted, or the default value.
 * */

type Param<T> = { [key: string]: T } | { [key: string]: Param<T> };

export function deepGet<T>(obj: Param<T>, keys: string | (string | number)[], defaultValue: T): T;
export function deepGet<T>(obj: Param<T>, keys: string | (string | number)[], defaultValue: null): T | null;
export function deepGet<T>(obj: Param<T>, keys: string | (string | number)[], defaultValue: T | null = null) {
    const delimiter = '.';
    const path: string[] = Array.isArray(keys)
        ? keys.map(String)
        : keys.split(delimiter).filter((key) => key.length > 0);

    if (!obj) return defaultValue;

    const firstEl = path.shift();
    if (!firstEl) return defaultValue;

    const child: Param<T> | T = obj[firstEl];

    if (!child) return defaultValue;

    if (typeof child === 'object') {
        // If path[0] exists and there are more elements, recursive call
        return deepGet(child as Param<T>, path, defaultValue);
    }
    return child;
}

export function isDefined<T>(val: T | undefined | null): val is T {
    return val !== undefined && val !== null;
}

export const checkEmptyObject = (o: object | null) =>
    Object.prototype.toString.call(o) === '[object Object]' && JSON.stringify(o) === '{}';

export function getBottom(htmlElement: HTMLElement) {
    if (htmlElement.nodeType === 1) {
        return window.innerHeight - htmlElement.getBoundingClientRect().top;
    }
    throw new Error(`Element not supported: ${htmlElement}`);
}

export function getHeight(htmlElement: HTMLElement) {
    if (htmlElement.nodeType === 1) {
        return htmlElement.getBoundingClientRect().bottom - htmlElement.getBoundingClientRect().top;
    }
    throw new Error(`Element not supported: ${htmlElement}`);
}

/**
 * Simple object check.
 */
export function isObject(item: unknown): item is Record<string, unknown> {
    return typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 */
export function mergeDeep<T>(target: T, ...sources: unknown[]): T {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function isHTTPStatusOK(n: number): boolean {
    return n >= 200 && n <= 299;
}
