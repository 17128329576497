/* eslint-disable react/display-name, react/function-component-definition */

import React, { ReactNode, useEffect, useMemo } from 'react';
import MarkdownIt from 'markdown-it';
import { checkEmptyObject, deepGet } from '~/common/utils';
import Router from 'next/router';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/zh-cn';
import 'moment/locale/it';
import 'moment/locale/cs';
import 'moment/locale/de';

import Link from 'next/link';
import { pathTranslations } from '~/i18n/pathTranslations';
import i18nConfig from 'i18n.config';

import { setHighchartsLangTheme } from '@darwinex/components-library';

const MARKDOWN_MARKER = '!!!';
const HTML_MARKER = '>>>';
const md = new MarkdownIt({
    html: true,
});

type CopiesLikeObject = { [key: string]: string | number };
type Messages = CopiesLikeObject | { [key: string]: Messages };
type MessageKey =
    | string
    | {
          id: string;
          altId: string;
      };
export type MFunctionReturnType = string | JSX.Element;
export type MFunction = (key: MessageKey, replacements?: CopiesLikeObject) => MFunctionReturnType;
type M_Wrapper = (messages: Messages | null, locale: string) => MFunction;

export type UFunction = (path: string) => string;
type U_Wrapper = (locale: string) => UFunction;

export type ULinkFunction = (propsLink: { href: string; children?: ReactNode; className?: string }) => JSX.Element;
type ULink_Wrapper = (locale: string) => ULinkFunction;

type I18NContext = {
    locale: string;
    locales: string[];
    excludedLoggedLocales: string[];
    hiddenLocales: string[];
    messages: Messages | null;
    M: MFunction;
    U: UFunction;
    ULink: ULinkFunction;
};

const ULink: ULink_Wrapper =
    (locale: string) =>
    ({ href, children, className }) => {
        const { untranslatedLocalesPath } = i18nConfig;
        const isExcludeLocale = untranslatedLocalesPath.includes(locale);
        let translatedPath = isExcludeLocale
            ? href
            : pathTranslations[locale]?.[href.includes('#') ? href.split('#')[0] : href];

        if (!isExcludeLocale && href.includes('#')) {
            translatedPath += `#${href.split('#')[1]}`;
        }

        // Set `as` prop to change displayed URL in browser
        const as = translatedPath ? `${locale === 'en' ? '' : `/${locale}`}${translatedPath}` : undefined;

        return (
            <Link href={href} as={as} className={className}>
                {children}
            </Link>
        );
    };

const U: U_Wrapper = (locale: string) => (path: string) => {
    const URLWithoutDomain = (url: string) => url.replace(/^.*\/\/[^\/]+/, '');

    const pathWithoutDomain = URLWithoutDomain(path);

    if (typeof pathTranslations[locale]?.[pathWithoutDomain] === 'undefined') {
        return path;
    }
    return `${locale === 'en' ? '' : `/${locale}`}${pathTranslations[locale]?.[pathWithoutDomain]}`;
    // return pathTranslations[locale]?.[pathWithoutDomain] ?? path;
};

// prettier-ignore
const M: M_Wrapper = (messages: Messages | null, locale: string) => (key , replacements = {}) => {
    const { defaultLocale } = i18nConfig;

    if (messages === null || checkEmptyObject(messages)) {
        return '';
    }

    const messageID = typeof key === 'string' ? key : key.id;
    const messageAltID = typeof key === 'string' ? null : key.altId;

    if (messages === undefined) {
        return (`_${locale}_${messageID}_`).toString();
    }

    // @ts-ignore
    let message = deepGet(messages[locale], messageID, `_${locale}_${messageID}_`).toString();

    if (message === `_${locale}_${messageID}_`) {
        if (messageAltID) {
            // @ts-ignore
            message = deepGet(messages[locale], messageAltID, `_${locale}_${messageAltID}_`).toString();
            if (message === `_${locale}_${messageAltID}_`) {
                // @ts-ignore
                message = deepGet(messages[defaultLocale], messageID, `_${locale}_${messageAltID}_`).toString();
            }
        } else {
            // @ts-ignore
            message = deepGet(messages[defaultLocale], messageID, `_${locale}_${messageID}_`).toString();
        }
    }

    // MARKDOWN support. Starting with !!!
    // Example: '!!!This is text with **some bold content**'
    // Done before replacements because some markdowns breaks replacement
    if (message.startsWith(MARKDOWN_MARKER)) {
        message = HTML_MARKER.concat(md.renderInline(message.replace(new RegExp('^'.concat(MARKDOWN_MARKER)), '')));
    }

    // replace keys like :keyname by values
    if (replacements && typeof replacements === 'object') {
        Object.keys(replacements).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(replacements, key)) return;
            const regexp = new RegExp(`:${key}\\b`, 'g');
            const replacementValue = replacements[key];
            if (typeof replacementValue !== 'undefined') {
                message = message.replace(regexp, replacementValue.toString());
            }
        });
    }

    // HTML support. Starting with >>>
    if (message.startsWith(HTML_MARKER)) {

        const contentClickHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
            const target = e.target as HTMLAnchorElement;
            const targetLink = target.closest('a');

            if(!targetLink) return;

            const isExternalURL = (url: string) => new URL(url).origin !== location.origin;

            if (!isExternalURL(targetLink.toString())) {
                const link = targetLink.href.toString();
                let path = U(locale)(link.includes('#') ? link.split('#')[0] : link);
                if (!path.includes('#') && link.includes('#')) {
                    path += `#${link.split('#')[1]}`;
                }
                if (targetLink.target === '_blank') {
                    targetLink.href = path;
                    return true;
                }
                e.preventDefault();
                Router.push(path);
            }
        }

        /* eslint-disable react/no-danger */
        const dangerousThing = {
            __html: md.renderInline(message.replace(new RegExp('^'.concat(HTML_MARKER)), '')),
        };
        return <span onClick={(event) => contentClickHandler(event)} dangerouslySetInnerHTML={dangerousThing} />;
    }

    return message;
};

/**
 * Defines the I18N Context for the whole app
 */
const NextI18NContext = React.createContext<I18NContext | null>(null);

type NextI18NProviderProps = {
    initLocale: string;
    locales?: string[];
    excludedLoggedLocales: string[];
    hiddenLocales: string[];
    children: ReactNode;
    messages: Messages;
};

export const importLanguageFile = (lang: string, fileName: string, namespace?: string) => {
    if (namespace) {
        return import(/* webpackChunkName: "i18n-[request]" */ `./${lang}/${namespace}/${fileName}.json`);
    }
    return import(/* webpackChunkName: "i18n-[request]" */ `./${lang}/${fileName}.json`);
};

export const NextI18NProvider = ({
    initLocale,
    locales,
    messages,
    excludedLoggedLocales,
    hiddenLocales,
    children,
}: NextI18NProviderProps) => {
    moment.locale(initLocale === 'zh' ? 'zh-cn' : initLocale);

    useEffect(() => {
        if (initLocale) {
            setHighchartsLangTheme(initLocale);
        }
    }, [initLocale]);

    const providerValue = useMemo<I18NContext>(
        () => ({
            locales: locales as string[],
            excludedLoggedLocales,
            hiddenLocales,
            messages,
            locale: initLocale,
            M: M(messages, initLocale),
            U: U(initLocale),
            ULink: ULink(initLocale),
        }),
        [locales, messages, initLocale, excludedLoggedLocales, hiddenLocales],
    );

    return <NextI18NContext.Provider value={providerValue}>{children}</NextI18NContext.Provider>;
};

export const useI18N = () => {
    const context = React.useContext(NextI18NContext);
    if (context === undefined) {
        throw new Error('useI18N must be used within a NextI18NProvider');
    }
    return context as I18NContext;
};
