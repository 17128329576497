import { useI18N } from '~/contexts/NextI18NProvider';
import {
    T_FormInfo as T_FormInfoCore,
    T_Validator as T_ValidatorCore,
    T_SetFieldsValue as T_SetFieldsValueCore,
    useValidationCore,
    T_Data,
} from '@darwinex/universal-toolkit/dist/hooks/useValidationCore';

export type T_FormInfo = T_FormInfoCore;
export type T_Validator = T_ValidatorCore;
export type T_SetFieldsValue = T_SetFieldsValueCore;
export type T_DataCore = T_Data;

const useValidation = (formInfo: T_FormInfo) => {
    const { locale } = useI18N();
    return useValidationCore(formInfo, locale);
};

export { useValidation };
