import React, { useEffect, useState } from 'react';

import { useProfile } from '~/contexts/NextProfileProvider';
import { useModal } from '~/contexts/NextModalProvider';
import { T_ProfileResponse } from '~/pages/api/profile';
import { useProfileService } from '~/hooks/services/useProfileService';
import { T_StartParticipationOutput, useParticipationService } from '~/hooks/services/useParticipationService';
import { useRouter } from 'next/router';
import SBCancelParticipationManual from '~/storybook/pages/platform/settings/modals/cancel-participation-manual/CancelParticipationManual';
import SBCancelParticipationManualSuccess from '~/storybook/pages/platform/settings/modals/cancel-participation-manual/CancelParticipationManualSuccess';
import { getFirstActiveParticipation, getParticipation } from '~/utils/participations';
import { sendDataLayerEvent } from '~/utils/analytics';
import { T_PartialProfile, UpdateProfileType } from '~/services/profile';

const CancelParticipationManual = () => {
    const router = useRouter();

    const { profile, setPartialProfile } = useProfile();
    const { getProfileInfo } = useProfileService();
    const { closeModal, setModal } = useModal();

    const [participationId, setParticipationId] = useState<string>('');
    const { cancelParticipationManual } = useParticipationService();
    const participation = getFirstActiveParticipation(profile);

    const [participationSuccess, setParticipationSuccess] = useState<boolean>(false);

    const [cancelRequested, setCancelRequested] = useState<boolean>(false);

    useEffect(() => {
        if (participationId) {
            setCancelRequested(true);
            doCancelParticipation();
        }
    }, [participationId]);

    const {
        data: cancelParticipationData,
        error: cancelParticipationError,
        execute: doCancelParticipation,
    } = cancelParticipationManual<T_StartParticipationOutput>({
        participationId: participationId || '',
    });

    useEffect(() => {
        if (cancelParticipationData) {
            setParticipationSuccess(true);
            doGetUserData();
            setCancelRequested(false);
        } else if (cancelParticipationError) {
            setModal('generic-error');
            setCancelRequested(false);
        }
    }, [cancelParticipationData, cancelParticipationError]);

    const {
        data: userData,
        error: userError,
        execute: doGetUserData,
    } = getProfileInfo<T_PartialProfile>([UpdateProfileType.PARTICIPATION_SUBSCRIPTION]);

    useEffect(() => {
        if (userData) {
            const newProfile = userData as T_ProfileResponse;
            if (newProfile) {
                const { participations, subscriptions } = newProfile;
                const participation = getParticipation(participationId, participations, subscriptions);
                sendDataLayerEvent({
                    event: 'cancel_subscription',
                    canBeReactivated: participation?.canBeReactivated,
                    withInvestment: true,
                });
            } else {
                sendDataLayerEvent({ event: 'cancel_subscription' });
            }
            setPartialProfile(userData);
        } else if (userError) {
            closeModal();
            router.push('/500');
        }
    }, [userData, userError]);

    const onModalClose = () => {
        closeModal();
        if (cancelParticipationError) {
            setModal('generic-error');
        }
    };

    return !participationSuccess ? (
        <SBCancelParticipationManual
            onModalClose={() => onModalClose()}
            onCancelSubscription={() => {
                if (participation) {
                    setParticipationId(participation.id);
                } else {
                    closeModal();
                }
            }}
            cancelRequested={cancelRequested}
        />
    ) : (
        <SBCancelParticipationManualSuccess onModalClose={() => onModalClose()} />
    );
};

export default CancelParticipationManual;
