import useFetch from '~/hooks/useFetch';
import { addQueryParams } from '~/utils/urls';
import { checkProductNameFormat } from '~/utils/darwin-name';
import { CompetitionTypes } from '~/enums/competition-types';
import { AchievementsTypes } from '~/enums/achievements';
import { getCompetitionRequestString, getEditionRequestFormat } from '~/utils/competition';
import { ExclusionTypes } from '~/enums/exclusions-types';
import { GraphTimeFrames } from '~/enums/graph-timeframes';
import { PromotionLimitDto } from '~/types/dto/darwinia/promotion/PromotionLimitDto';
import { ProductPromotionStatusDto } from '~/types/dto/darwinia/promotion/ProductPromotionStatusDto';
import { HonourableMentionDto } from '~/types/dto/darwinia/HonourableMentionDto';
import { LeagueDto } from '~/types/dto/darwinia/LeagueDto';
import Nullable from '~/types/Nullable';
import Pageable from '~/types/dto/Pageable';
import { IRanking } from '~/types/Darwinia/Ranking';
import { ProductExclusionData } from '~/pages/api/competition/[productName]/info';
import { AllocationInfo } from '~/storybook/pages/platform/darwin-page/DarwinPageView';
import yearToDateFilter from '~/utils/graphs';

interface State<T> {
    data: T;
    error?: Error;
    status?: number;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const COMPETITION_MS_URL = '/api/bootcamp-competition';
const NEXT_COMPETITION_API = '/api/competition';

export interface CompetitionPositionInfo {
    competition: Nullable<CompetitionTypes>;
    position: Nullable<number>;
    positionRaise: Nullable<number>;
    exclusionInfo: CompetitionExclusionInfo;
    prevProductInfo: PrevOrNextProductBasicInfo;
    nextProductInfo: PrevOrNextProductBasicInfo;
}

export interface CompetitionExclusionInfo {
    excluded: Nullable<boolean>;
    reason: Nullable<ExclusionTypes>;
    correlationValue: Nullable<number>;
    correlationPair: Nullable<string>;
}

export interface PrevOrNextProductBasicInfo {
    productName: Nullable<string>;
    position: Nullable<number>;
}

export interface CompetitionStatsInfo {
    return1M: Nullable<number>;
    return5M: Nullable<number>;
    return6M: Nullable<number>;
    maxDrawdown6M: Nullable<number>;
    scoreRate: Nullable<number>;
}

export interface CompetitionGlobalInfo {
    totalParticipants: Nullable<number>;
    minPrizeRating: Nullable<number>;
    lowestRatingInPrizes: Nullable<number>;
    minRating: Nullable<number>;
    maxRating: Nullable<number>;
    lastExecution: Nullable<number>;
    nextExecution: Nullable<number>;
}

export interface AchievementInfo {
    achievement: AchievementsTypes;
    groupPriority: number;
    achievementPriority: number;
    tab: string;
    showInRanking: boolean;
    showInOpenCompetition: boolean;
    showInClosedCompetition: boolean;
    winnerValue: Nullable<number>;
}

export interface HistoricalPositionStatsInfo {
    bestHistoricPositionInfo: {
        position: Nullable<number>;
        month: Nullable<number>;
        year: Nullable<number>;
    };
    participationTimes: Nullable<number>;
    averagePosition: Nullable<number>;
}

export interface CompetitionPrizesInfo {
    minRatingForAllocation: Nullable<number>;
    minAllocation: Nullable<number>;
    specialPositions: {
        amount: Nullable<number>;
        monthlyAllocation: Nullable<number>;
        prizesDistribution: Array<{ position: Nullable<number>; allocation: Nullable<number> }>;
    };
}

export interface CompetitionDates {
    rankingCalculationDate: Nullable<number>;
    rankingActivationDate: Nullable<number>;
    firstRankingInHistoryDate: Nullable<number>;
}

export interface CompetitionDatesByCompetition extends CompetitionDates {
    competition: CompetitionTypes;
}

export interface CompetitionRatingDistributionItem {
    count: number;
    maxIntervalRating: number;
    minIntervalRating: number;
}

export interface PositionEvolutionData {
    numPositionsPrized: number;
    positions: Array<PositionInfo>;
}

export interface PositionInfo {
    date: number;
    competition: Nullable<CompetitionTypes>;
    maxAllocation: Nullable<number>;
    realAllocation: Nullable<number>;
    position: Nullable<number>;
}

export interface RankingCalculatorSimulation {
    rate: Nullable<number>;
    expectedPosition: Nullable<number>;
    expectedFunds: Nullable<number>;
}

export interface OwnedProductInfo {
    competition: CompetitionTypes;
    edition: string;
    exclusionStatus: Nullable<CompetitionExclusionInfo>;
    maxAllocation: Nullable<number>;
    position: Nullable<number>;
    positionRaise: Nullable<number>;
    productName: string;
    rating: Nullable<number>;
    realAllocation: Nullable<number>;
}

export interface Edition {
    month: number;
    year: number;
}

export interface DarwinIAClassicAllocationInfo {
    totalAllocation: Nullable<number>;
    actualAllocation: Nullable<number>;
}

export interface DarwinIAClassicPositionStatistics {
    bestPosition: Nullable<number>;
    numPrizes: Nullable<number>;
    numParticipations: Nullable<number>;
}

const addEditionIfItIsProvided = (url: string, edition?: Edition) => {
    if (edition) {
        return `${url}${url.includes('?') ? '&' : '?'}edition=${getEditionRequestFormat(edition.month, edition.year)}`;
    }
    return url;
};

export const useCompetitionService = () => {
    const useGlobalCompetitionInfo = (
        competitionType: CompetitionTypes,
        edition?: Edition,
    ): UseFetchReturnType<CompetitionGlobalInfo> => {
        const competitionTypeUrl = competitionType === CompetitionTypes.GOLD ? 'gold' : 'silver';
        let URL = `${COMPETITION_MS_URL}/${competitionTypeUrl}/status`;
        URL = addEditionIfItIsProvided(URL, edition);

        const { data, error, execute } = useFetch<CompetitionGlobalInfo>(URL);
        return {
            data: data || {
                totalParticipants: null,
                minPrizeRating: null,
                lowestRatingInPrizes: null,
                minRating: null,
                maxRating: null,
                nextExecution: null,
                lastExecution: null,
            },
            error,
            execute,
        };
    };

    const useGetProductCompetitionInfo = (
        productName: Nullable<string>,
        edition?: Edition,
    ): UseFetchReturnType<CompetitionPositionInfo> => {
        let URL = `${NEXT_COMPETITION_API}/${productName}/info`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<CompetitionPositionInfo>(URL);
        return {
            data: data || {
                competition: null,
                position: null,
                positionRaise: null,
                prevProductInfo: {
                    productName: null,
                    position: null,
                },
                nextProductInfo: {
                    productName: null,
                    position: null,
                },
                exclusionInfo: {
                    excluded: null,
                    reason: null,
                    correlationPair: null,
                    correlationValue: null,
                },
            },
            status,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetProductCompetitionReturnStats = (
        productName: Nullable<string>,
        edition?: Edition,
    ): UseFetchReturnType<CompetitionStatsInfo> => {
        let URL = `${COMPETITION_MS_URL}/item/${productName}/stats`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, error, execute } = useFetch<CompetitionStatsInfo>(URL);
        return {
            data: data || {
                return6M: null,
                return5M: null,
                return1M: null,
                maxDrawdown6M: null,
                scoreRate: null,
            },
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetProductPositionStatsByCompetition = (
        productName: Nullable<string>,
        competitionType: Nullable<CompetitionTypes>,
        edition?: Edition,
    ) => {
        const { data, error, execute } = useFetch<HistoricalPositionStatsInfo>(
            addEditionIfItIsProvided(
                `${NEXT_COMPETITION_API}/${productName}/${getCompetitionRequestString(competitionType)}/position-stats`,
                edition,
            ),
        );
        return {
            data: data || {
                averagePosition: null,
                participationTimes: null,
                bestHistoricPositionInfo: {
                    position: null,
                    year: null,
                    month: null,
                },
            },
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetProductAllocationInfoByCompetition = (
        competition: Nullable<CompetitionTypes>,
        productName: Nullable<string>,
        edition?: Edition,
    ): UseFetchReturnType<AllocationInfo> => {
        const { data, error, execute } = useFetch<AllocationInfo>(
            addEditionIfItIsProvided(
                `${COMPETITION_MS_URL}/${getCompetitionRequestString(competition)}/${productName}/allocation`,
                edition,
            ),
        );
        return {
            data: data || {
                currentAllocation: null,
                totalAllocation: null,
                numPrizes: null,
            },
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetProductAchievements = (
        productName: Nullable<string>,
        competitionType: Nullable<CompetitionTypes>,
        edition?: Edition,
    ): UseFetchReturnType<Array<AchievementInfo>> => {
        let URL = `${COMPETITION_MS_URL}/${competitionType}/${productName}/achievements`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, error, execute } = useFetch<Array<AchievementInfo>>(URL);

        return {
            data: data || [],
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetProductAllocationEvolution = (
        productName: Nullable<string>,
        timeframe: GraphTimeFrames,
        minStartDate: Nullable<number>,
    ): UseFetchReturnType<Array<Array<number>>> => {
        const { data, status, error, execute } = useFetch<Array<Array<number>>>(
            `${COMPETITION_MS_URL}/${productName}/allocation/graph/${
                timeframe === GraphTimeFrames.YTD ? GraphTimeFrames.ALL : timeframe
            }?minStartDate=${minStartDate}`,
        );
        const getData = (graphData: Array<Array<number>> | undefined) => {
            if (graphData === undefined) return [];
            if (timeframe === GraphTimeFrames.YTD) {
                return yearToDateFilter(graphData, (arg) => arg[0]);
            }
            return graphData;
        };
        return {
            data: getData(data),
            error,
            status,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetCompetitionRanking = (
        competitionType: Nullable<CompetitionTypes>,
        page: number,
        perPage: number,
        edition?: Edition,
        onlyOwnedProducts?: boolean,
        productName?: Nullable<string>,
    ): UseFetchReturnType<Pageable<IRanking>> => {
        let URL = `${NEXT_COMPETITION_API}/ranking/${getCompetitionRequestString(
            competitionType,
        )}/ranking-with-achievements?page=${page}&perPage=${perPage}`;
        URL = addEditionIfItIsProvided(URL, edition);
        if (onlyOwnedProducts) {
            URL += `&ownedProducts=true`;
        }
        if (productName) {
            URL += `&product=${productName}`;
        }

        const { data, status, error, execute } = useFetch<Pageable<IRanking>>(URL);
        return {
            data: data || {
                number: 0,
                first: false,
                last: false,
                size: 0,
                numberOfElements: 0,
                totalElements: 0,
                totalPages: 0,
                content: [],
                sort: {
                    empty: false,
                    sorted: false,
                    unsorted: true,
                },
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetCompetitionAllocationInfo = (
        competitionType: Nullable<CompetitionTypes>,
        edition?: Edition,
    ): UseFetchReturnType<CompetitionPrizesInfo> => {
        const { data, error, execute } = useFetch<CompetitionPrizesInfo>(
            addEditionIfItIsProvided(
                `${COMPETITION_MS_URL}/${getCompetitionRequestString(competitionType)}/ranking-prizes-info`,
                edition,
            ),
        );
        return {
            data: data || {
                minRatingForAllocation: null,
                minAllocation: null,
                specialPositions: {
                    amount: null,
                    monthlyAllocation: null,
                    prizesDistribution: [],
                },
            },
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetDatesByCompetition = (
        competitionType: Nullable<CompetitionTypes>,
        edition?: Edition,
    ): UseFetchReturnType<CompetitionDates> => {
        const { data, status, error, execute } = useFetch<CompetitionDates>(
            addEditionIfItIsProvided(
                `${COMPETITION_MS_URL}/${getCompetitionRequestString(competitionType)}/activation-dates`,
                edition,
            ),
        );
        return {
            data: data || {
                rankingActivationDate: null,
                firstRankingInHistoryDate: null,
                rankingCalculationDate: null,
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetCompetitionRatingDistribution = (
        competitionType: Nullable<CompetitionTypes>,
        steps: number,
        lowerLimit: number,
        upperLimit: number,
        edition?: Edition,
    ): UseFetchReturnType<Array<CompetitionRatingDistributionItem>> => {
        let URL = `${COMPETITION_MS_URL}/${getCompetitionRequestString(competitionType)}/ranking/rating`;
        URL = addEditionIfItIsProvided(URL, edition);
        URL = addQueryParams(URL, [
            {
                key: 'steps',
                value: steps.toString(),
            },
            {
                key: 'lowerLimit',
                value: lowerLimit.toString(),
            },
            {
                key: 'upperLimit',
                value: upperLimit.toString(),
            },
        ]);
        const { data, status, error, execute } = useFetch<Array<CompetitionRatingDistributionItem>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetPositionEvolutionByCompetition = (
        competitionType: Nullable<CompetitionTypes>,
        productName: Nullable<string>,
        type: GraphTimeFrames,
        edition?: Edition,
    ): UseFetchReturnType<PositionEvolutionData> => {
        let URL = `${COMPETITION_MS_URL}/${getCompetitionRequestString(
            competitionType,
        )}/${productName}/ranking/evolution?type=${type}`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<PositionEvolutionData>(URL);
        return {
            data: data || {
                numPositionsPrized: 0,
                positions: [],
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetPositionEvolutionInAllCompetitions = (
        productName: Nullable<string>,
        type: GraphTimeFrames,
        edition?: Edition,
    ): UseFetchReturnType<Array<PositionInfo>> => {
        let URL = `${COMPETITION_MS_URL}/${productName}/ranking/evolution?type=${type}`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<Array<PositionInfo>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetCompetitionProductExclusions = (
        competitionType: Nullable<CompetitionTypes>,
        productName: Nullable<string>,
        edition?: Edition,
    ): UseFetchReturnType<CompetitionExclusionInfo> => {
        let URL = `${COMPETITION_MS_URL}/exclusions/${productName}`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<ProductExclusionData>(URL);
        return {
            data: {
                reason: data?.reason || null,
                excluded: data?.reason !== null,
                correlationPair: data?.correlationPair || null,
                correlationValue: data?.correlationValue ? Number(data.correlationValue) : null,
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const usePublicRatingCalculator = (
        competitionType: Nullable<CompetitionTypes>,
        currentMonthReturn: Nullable<number>,
        prior5MonthReturn: Nullable<number>,
        max6Mdrawdown: Nullable<number>,
        edition?: Edition,
    ): UseFetchReturnType<RankingCalculatorSimulation> => {
        let URL = `${COMPETITION_MS_URL}/${getCompetitionRequestString(
            competitionType,
        )}/calculator/expected-position?return1M=${currentMonthReturn}&return5M=${prior5MonthReturn}&drawdown6M=${max6Mdrawdown}`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<RankingCalculatorSimulation>(URL);
        return {
            data: data || {
                rate: null,
                expectedPosition: null,
                expectedFunds: null,
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useRatingCalculator = (
        competitionType: Nullable<CompetitionTypes>,
        productName: Nullable<string>,
        currentMonthReturn: Nullable<number>,
        edition: Edition,
    ): UseFetchReturnType<RankingCalculatorSimulation> => {
        let URL = `${COMPETITION_MS_URL}/${getCompetitionRequestString(
            competitionType,
        )}/calculator/${productName}/expected-position?return1M=${currentMonthReturn}`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<RankingCalculatorSimulation>(URL);
        return {
            data: data || {
                rate: null,
                expectedPosition: null,
                expectedFunds: null,
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetForecastAverageEntryQuote = (productName: Nullable<string>): UseFetchReturnType<number> => {
        const { data, status, error, execute } = useFetch<number>(
            `${COMPETITION_MS_URL}/${productName}/average-entry-quote`,
        );
        return {
            data: data || 0,
            status,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetProductAggregatedAllocation = (
        productName: Nullable<string>,
        edition: Edition,
    ): UseFetchReturnType<AllocationInfo> => {
        let URL = `${COMPETITION_MS_URL}/${productName}/allocation`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<AllocationInfo>(URL);
        return {
            data: data || {
                currentAllocation: null,
                totalAllocation: null,
                numPrizes: null,
            },
            status,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetOwnedProductsInAllCompetitionsByEdition = (
        edition: Edition,
    ): UseFetchReturnType<Array<OwnedProductInfo>> => {
        let URL = `${COMPETITION_MS_URL}/owned-products/status`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<Array<OwnedProductInfo>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute,
        };
    };

    const useGetCompetitionDates = (edition: Edition): UseFetchReturnType<Array<CompetitionDatesByCompetition>> => {
        let URL = `${COMPETITION_MS_URL}/activation-dates`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<Array<CompetitionDatesByCompetition>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute,
        };
    };

    const useGetPromotionLimits = (edition: Edition): UseFetchReturnType<Array<PromotionLimitDto>> => {
        let URL = `${COMPETITION_MS_URL}/gold/promotion-limits`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<Array<PromotionLimitDto>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute,
        };
    };

    const useGetProductPromotionStatus = (
        productName: Nullable<string>,
        edition: Edition,
    ): UseFetchReturnType<ProductPromotionStatusDto> => {
        let URL = `${COMPETITION_MS_URL}/gold/${productName}/promotion-status`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<ProductPromotionStatusDto>(URL);
        return {
            data: data || {
                canPromote: null,
                promotionStatsDtoList: [],
            },
            status,
            error,
            execute,
        };
    };

    const useGetHonourableMentions = (
        competitionType: Nullable<CompetitionTypes>,
        edition: Edition,
    ): UseFetchReturnType<Array<HonourableMentionDto>> => {
        let URL = `${COMPETITION_MS_URL}/${getCompetitionRequestString(competitionType)}/honourable-mentions`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<Array<HonourableMentionDto>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute,
        };
    };

    const useGetProductLeague = (
        productName: Nullable<string>,
        edition: Edition,
    ): UseFetchReturnType<Array<LeagueDto>> => {
        let URL = `${COMPETITION_MS_URL}/league?productNameStart=${productName}`;
        URL = addEditionIfItIsProvided(URL, edition);
        const { data, status, error, execute } = useFetch<Array<LeagueDto>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute,
        };
    };

    return {
        getCompetitionGlobalInfo: useGlobalCompetitionInfo,
        getCompetitionRanking: useGetCompetitionRanking,
        getCompetitionProductInfo: useGetProductCompetitionInfo,
        getCompetitionProductReturnStats: useGetProductCompetitionReturnStats,
        getProductAllocationInfoByCompetition: useGetProductAllocationInfoByCompetition,
        getProductAchievements: useGetProductAchievements,
        getProductPositionStatsByCompetition: useGetProductPositionStatsByCompetition,
        getProductAllocationEvolution: useGetProductAllocationEvolution,
        getCompetitionAllocationInfo: useGetCompetitionAllocationInfo,
        getDatesByCompetition: useGetDatesByCompetition,
        getCompetitionRatingDistribution: useGetCompetitionRatingDistribution,
        getPositionEvolutionByCompetition: useGetPositionEvolutionByCompetition,
        getPositionEvolutionInAllCompetitions: useGetPositionEvolutionInAllCompetitions,
        getCompetitionProductExclusions: useGetCompetitionProductExclusions,
        simulateProductRatingCalculation: useRatingCalculator,
        simulateRatingCalculation: usePublicRatingCalculator,
        getForecastAverageEntryQuote: useGetForecastAverageEntryQuote,
        getOwnedProductsInAllCompetitionsByEdition: useGetOwnedProductsInAllCompetitionsByEdition,
        getCompetitionDates: useGetCompetitionDates,
        getProductAggregatedAllocationInfo: useGetProductAggregatedAllocation,
        getPromotionLimits: useGetPromotionLimits,
        getProductPromotionStatus: useGetProductPromotionStatus,
        getHonourableMentions: useGetHonourableMentions,
        getProductLeague: useGetProductLeague,
    };
};
