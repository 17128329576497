import React from 'react';
import classNames from 'classnames';
import { useI18N } from '~/contexts/NextI18NProvider';
import { BREAKPOINT_MAP, Button, Modal } from '@darwinex/components-library';
import useWindowSize from '~/hooks/useWindowSize';

interface Props {
    onModalClose: React.MouseEventHandler;
    configuration: Record<string, any> | null;
}

const ModalError = ({ onModalClose, configuration }: Props) => {
    const { M } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;
    return (
        <Modal
            size="md"
            type="danger"
            title={configuration?.title ?? M('modals.generic-error.title')}
            onModalClose={onModalClose}
        >
            <div className="dx-font-size-lg font-open-sans--bold">
                {configuration?.subtitle ?? M('modals.generic-error.subtitle')}
            </div>
            <div className="dx-font-size-lg dx-mt-4">
                {configuration?.description ?? M('modals.generic-error.description')}
            </div>
            <div className="d-flex justify-content-end  dx-mt-10">
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    size="z-md"
                    variant="primary"
                    onClick={onModalClose}
                    border="rect"
                    type="button"
                >
                    {configuration?.description ?? M('modals.generic-error.cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalError;
