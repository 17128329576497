import React from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { Alert, BREAKPOINT_MAP, Button, IconInfoEmpty, Modal } from '@darwinex/components-library';
import useWindowSize from '~/hooks/useWindowSize';
import classNames from 'classnames';
import styles from './ReactivateParticipation.module.scss';

interface Props {
    onModalClose: React.MouseEventHandler;
    onReactiveSubscription: () => void;
    reactiveRequested?: boolean;
}

const ReactivateParticipation = ({ onModalClose, onReactiveSubscription, reactiveRequested = false }: Props) => {
    const { M } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;
    return (
        <Modal size="md" title={M('modals.reactivate-participation.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg">
                <h5 className="dx-mb-3 font-open-sans--bold">
                    {M('modals.reactivate-participation.what-will-happen.title')}
                </h5>
                <p className="dx-my-3">{M('modals.reactivate-participation.what-will-happen.reactivate-text')}</p>
                <p className="dx-my-3">{M('modals.reactivate-participation.what-will-happen.restart-text')}</p>
                <Alert
                    variant="info"
                    shadow={false}
                    className="dx-py-3 dx-mt-5 dx-mb-10"
                    iconClassName={styles.alert_icon}
                    icon={<IconInfoEmpty viewBox="0 0 24 24" />}
                >
                    {M('modals.reactivate-participation.what-will-happen.alert')}
                </Alert>
            </div>
            <div className="dx-mt-9 d-flex flex-wrap dx-g-5 align-items-center justify-content-between">
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onModalClose}
                    size="z-md"
                    border="rect"
                    variant="secondary"
                    type="submit"
                >
                    {M('modals.reactivate-participation.close-cta')}
                </Button>
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onReactiveSubscription}
                    size="z-md"
                    border="rect"
                    variant="primary"
                    type="submit"
                    disabled={reactiveRequested}
                >
                    {M('modals.reactivate-participation.reactivate-cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default ReactivateParticipation;
