import { apiRequest } from '~/common/apiRequest';
import { RegulatedCompanies } from '~/enums/profile';
import Nullable from '~/types/Nullable';

export interface UserInfo {
    username: string;
    publicId: string;
    avatarUrl: Nullable<string>;
    locale: string;
    email: string;
    emailNew: string;
    emailStatus: string;
    country: Nullable<string>;
    regulatedCompany: Nullable<RegulatedCompanies>;
    registerDate: Nullable<number>;
    memberGetMemberCode: Nullable<string>;
}

export const INIT_USER_INFO = {
    username: '',
    publicId: '',
    avatarUrl: null,
    locale: '',
    email: '',
    emailNew: '',
    emailStatus: '',
    country: null,
    regulatedCompany: null,
    registerDate: null,
    memberGetMemberCode: null,
};

export async function getUserInfo(headers: Headers): Promise<UserInfo> {
    const response = await apiRequest(headers, '/api/user/info', {}, false);

    if (response.ok) {
        const userInfo = response.data as Record<string, any>;
        return {
            username: userInfo.username,
            publicId: userInfo.publicId,
            avatarUrl: userInfo.avatarUrl,
            locale: userInfo.locale,
            email: userInfo.email,
            emailNew: userInfo.emailNew,
            emailStatus: userInfo.emailStatus,
            country: userInfo.registerCountry,
            regulatedCompany: userInfo.regulatedCompany || null,
            registerDate: userInfo.registerDate,
            memberGetMemberCode: userInfo.memberGetMemberCode,
        };
    }

    return { ...INIT_USER_INFO };
}
