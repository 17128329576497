import { T_ProfileResponse } from '~/pages/api/profile';
import { checkEmptyObject } from '~/common/utils';
import { ParticipationStatus } from '~/enums/profile';
import { getShortNameFromTicker } from '~/utils/darwin-name';
import { CompetitionStatus } from '~/enums/client-competition-status';
import { CompetitionPositionInfo } from '~/hooks/services/useCompetitionService';
import {
    T_Participation,
    T_ParticipationAndSubscription,
    T_SubscriptionStatus,
} from '~/services/participation-subscription';
import Nullable from '../types/Nullable';

export const getParticipation = (
    participationId: string,
    participations: T_Participation[],
    subscriptions?: T_SubscriptionStatus[],
): T_ParticipationAndSubscription => {
    const participation = participations.find((_p) => _p && _p.id === participationId);
    if (typeof participation !== 'undefined' && participation) {
        const { subscriptionId } = participation;
        if (subscriptionId && subscriptions) {
            const subscription = subscriptions.find((_s) => _s && _s.id === subscriptionId);
            return {
                ...participation,
                subscription: typeof subscription !== 'undefined' && subscription ? subscription : null,
            };
        }

        return {
            ...participation,
            subscription: null,
        };
    }
    return null;
};

export const getFirstParticipation = (profile: T_ProfileResponse, orderByActive = true) => {
    if (!profile || checkEmptyObject(profile)) return null;

    const { participations, subscriptions } = profile;

    let participation = null;
    if (orderByActive) {
        participation = participations.find((_p) => _p && _p.status !== ParticipationStatus.CANCELLED);
    }
    if (!participation) {
        participation = participations.length > 0 ? participations[0] : null;
    }
    if (typeof participation !== 'undefined' && participation) {
        return getParticipation(participation.id, participations, subscriptions);
    }
    return null;
};

export const getFirstActiveParticipation = (profile: T_ProfileResponse) => {
    if (!profile || checkEmptyObject(profile)) return null;

    const { participations, subscriptions } = profile;
    const participation = participations.find((_p) => _p && _p.status !== ParticipationStatus.CANCELLED);
    if (typeof participation !== 'undefined' && participation) {
        return getParticipation(participation.id, participations, subscriptions);
    }
    return null;
};

export const getParticipationById = (profile: T_ProfileResponse, id: string) => {
    if (!profile || checkEmptyObject(profile)) return null;

    const { participations, subscriptions } = profile;
    const participation = participations.find((_p) => _p && _p.id === id);
    if (typeof participation !== 'undefined' && participation) {
        return getParticipation(id, participations, subscriptions);
    }
    return null;
};

export const getParticipationByTradingAccount = (
    profile: T_ProfileResponse,
    prop: 'tradingAccountName' | 'ticker' | 'productName' | 'shortname',
    value: string,
) => {
    if (!profile || checkEmptyObject(profile)) return null;

    const { participations, subscriptions } = profile;
    const participation =
        prop === 'shortname'
            ? participations.find(
                  (_p) => _p && _p.tradingAccount && getShortNameFromTicker(_p.tradingAccount.ticker || '') === value,
              )
            : participations.find((_p) => _p && _p.tradingAccount && _p.tradingAccount[prop] === value);
    if (typeof participation !== 'undefined' && participation) {
        return getParticipation(participation.id, participations, subscriptions);
    }
    return null;
};

export const getInfoParticipationStep = (participation: T_Participation) => {
    if (!participation) return 0;

    let step;
    if (
        participation.status === ParticipationStatus.ONBOARDING ||
        participation.status === ParticipationStatus.ONBOARDING_COMPLETE
    ) {
        step = 1;
    } else if (participation.status === ParticipationStatus.TRAINING) {
        step = 2;
    } else if (participation.status === ParticipationStatus.TRAINING_COMPLETE) {
        step = 3;
    } else {
        step = 4;
    }
    return step;
};

export const getCompetitionStatusFromParticipation = (
    accountParticipationInfo: T_ParticipationAndSubscription,
    competitionData: CompetitionPositionInfo,
): Nullable<CompetitionStatus> => {
    if (accountParticipationInfo) {
        switch (accountParticipationInfo.status) {
            case ParticipationStatus.ONBOARDING:
            case ParticipationStatus.ONBOARDING_COMPLETE:
            case ParticipationStatus.TRAINING:
            case ParticipationStatus.TRAINING_COMPLETE:
                return CompetitionStatus.NOT_ACTIVE;
            case ParticipationStatus.ACTIVE:
            case ParticipationStatus.CANCELLED:
                return competitionData.position === null ? CompetitionStatus.EXCLUDED : CompetitionStatus.ACTIVE;
            default:
                return CompetitionStatus.NOT_ACTIVE;
        }
    }
    return null;
};
