import { apiRequest, ApiRequestReturn } from '~/common/apiRequest';
import { ParticipationStatus } from '~/enums/profile';
import Nullable from '~/types/Nullable';
import { Currency } from '~/enums/Currency';

export type T_TradingAccount = {
    tradingAccountName: Nullable<string>;
    tradingPlatform: string;
    familyType: string;
    ticker: Nullable<string>;
    productName: Nullable<string>;
} | null;

export type T_Participation = {
    id: string;
    status: ParticipationStatus;
    tradingAccount: T_TradingAccount;
    subscriptionId: Nullable<string>;
    willBeCancelled: boolean;
    canBeReactivated: boolean;
    competitionId: Nullable<string>;
    serviceId: Nullable<string>;
} | null;

export type CreditCardData = {
    last4Numbers?: string;
    firstName?: string;
    lastName?: string;
    expiryMonth: number;
    expiryYear: number;
    expiryDate: number;
};

export type T_Credits = {
    amount: number;
    currencyCode: Currency;
} | null;

export type T_CustomerInfo = {
    credits: T_Credits;
    paySubscriptionWithEarning: boolean;
} | null;

export type PayPalData = {
    email?: string;
};

export type T_Subscription = {
    publicId: string;
    nextRecurrentDate: number;
    state: string;
    creditCard: CreditCardData | null;
    paypal: PayPalData | null;
    subscriptionPrice?: {
        periodNumberOfMonths: number;
        periodPrice: number;
        currency: string;
    };
    serviceId: string;
} | null;

export type T_SubscriptionStatus = {
    id: string;
    status: string;
    nextRecurrent: Nullable<number>;
    subscriptionPrice?: {
        periodNumberOfMonths: number;
        periodPrice: number;
        currency: string;
    };
} | null;

export type T_ParticipationAndSubscription =
    | (T_Participation & {
          subscription: T_SubscriptionStatus;
      })
    | null;

export interface ParticipationAndSubscription {
    participations?: T_Participation[];
    subscriptions?: T_SubscriptionStatus[];
}

export const INIT_PARTICIPATION_SUBSCRIPTION = {
    participations: [],
    subscriptions: [],
};

export async function getParticipationAndSubscription(headers: Headers): Promise<ParticipationAndSubscription> {
    const response = await apiRequest(headers, '/api/bootcamp-participation/participations', {}, false);

    if (response.ok) {
        const responseParticipations = response?.data as Record<string, any>;
        const participations: T_Participation[] = (responseParticipations?.participations as T_Participation[]) ?? [];
        const subscriptionsIds: Set<Nullable<string>> = new Set();
        const participationsWithActions: T_Participation[] = [];

        participations.forEach((participation) => {
            if (participation) {
                subscriptionsIds.add(participation.subscriptionId);
                participationsWithActions.push(participation);
            }
        });

        const subscriptionsRequests: Promise<ApiRequestReturn<any>>[] = [];
        const subscriptionsAlternativeRequests: Promise<ApiRequestReturn<any>>[] = [];
        Array.from(subscriptionsIds).forEach((id: Nullable<string>, index: number) => {
            const isActiveParticipation = participations[index]?.status !== ParticipationStatus.CANCELLED;
            if (isActiveParticipation) {
                subscriptionsRequests.push(apiRequest(headers, `/api/payment-subscription/subscriptions/${id}`, {}));
            } else {
                subscriptionsRequests.push(
                    apiRequest(headers, `/api/payment-subscription/subscriptions/${id}/status`, {}),
                );
            }
            subscriptionsAlternativeRequests.push(
                apiRequest(headers, `/api/payment-subscription/subscriptions/${id}/status`, {}),
            );
        });

        return Promise.all(subscriptionsRequests)
            .then((responsesSubscriptions) =>
                Promise.all(
                    responsesSubscriptions.map(async (r, idxR) => {
                        if (r.ok) {
                            return r.data;
                        }
                        const responseSubscriptionAlternativeRequest = await subscriptionsAlternativeRequests[idxR];

                        if (responseSubscriptionAlternativeRequest.ok) {
                            return responseSubscriptionAlternativeRequest.data;
                        }

                        throw new Error(
                            `Error fetching subscriptions on /api/profile. Status code: ${r.statusCode}`,
                            r.err as Error,
                        );
                    }),
                ),
            )
            .then((responsesSubscriptions) => {
                const subscriptions: T_SubscriptionStatus[] = [];
                responsesSubscriptions.forEach((responsesSubscription: T_SubscriptionStatus | T_Subscription) => {
                    let subscription;
                    if (responsesSubscription) {
                        if ('publicId' in responsesSubscription) {
                            subscription = {
                                id: responsesSubscription.publicId,
                                status: responsesSubscription.state,
                                nextRecurrent: responsesSubscription.nextRecurrentDate,
                                subscriptionPrice: responsesSubscription.subscriptionPrice,
                            };
                        } else {
                            subscription = {
                                id: responsesSubscription.id,
                                status: responsesSubscription.status,
                                nextRecurrent: responsesSubscription.nextRecurrent,
                            };
                        }
                        subscriptions.push(subscription);
                    }
                });

                return {
                    participations: participationsWithActions,
                    subscriptions,
                };
            })
            .catch(() => ({ ...INIT_PARTICIPATION_SUBSCRIPTION }));
    }

    return { ...INIT_PARTICIPATION_SUBSCRIPTION };
}
