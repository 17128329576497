import React, { ChangeEvent } from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { Alert, BooleanInput, Button, IconError, Input, Modal } from '@darwinex/components-library';
import Image, { ImageProps } from 'next/image';
import ImageVisa from '~/public/logos/visa.png';
import ImageMasterCard from '~/public/logos/mastercard.png';
import classNames from 'classnames';
import stylesInput from '@darwinex/components-library/build/components/Input/Input.module.scss';
import { PaymentType } from '~/pages/checkout';
import styles from './AddPaymentMethod.module.scss';

const availableCardsCommonProps = {
    width: 38,
    height: 23,
    layout: 'fixed',
    objectFit: 'cover',
};

const availableCardsVisaProps: Omit<ImageProps, 'src'> = { ...availableCardsCommonProps, alt: 'VISA' };
const availableCardsMastercardProps: Omit<ImageProps, 'src'> = { ...availableCardsCommonProps, alt: 'MASTERCARD' };

export type AddPaymentMethodTypes = 'EDIT' | 'NEW';

interface Props {
    onModalClose: React.MouseEventHandler;
    onAddPaymentMethod: React.MouseEventHandler<HTMLButtonElement>;
    firstName: string;
    errorFirstName: string | JSX.Element | null;
    warningFirstName?: boolean;
    successFirstName: boolean;
    setFirstNameCreditCard: (event: React.ChangeEvent<HTMLInputElement>) => void;
    lastName: string;
    errorLastName: string | JSX.Element | null;
    warningLastName?: boolean;
    successLastName: boolean;
    setLastNameCreditCard: (event: React.ChangeEvent<HTMLInputElement>) => void;
    type: AddPaymentMethodTypes;
    alert?: boolean;
    disabledSaveBtn?: boolean;
    paymentMethods: PaymentType[];
    setPayment: (e: ChangeEvent) => void;
}

const AddPaymentMethod = ({
    onModalClose,
    onAddPaymentMethod,
    firstName,
    errorFirstName = null,
    warningFirstName = false,
    successFirstName = false,
    setFirstNameCreditCard,
    lastName,
    errorLastName = null,
    warningLastName = false,
    successLastName = false,
    setLastNameCreditCard,
    type,
    alert,
    disabledSaveBtn = false,
    paymentMethods,
    setPayment,
}: Props) => {
    const { M } = useI18N();

    const title =
        type === 'EDIT' ? M('modals.add-payment-method.edit.title') : M('modals.add-payment-method.new.title');
    const subtitle =
        type === 'EDIT' ? M('modals.add-payment-method.edit.subtitle') : M('modals.add-payment-method.new.subtitle');
    const description =
        type === 'EDIT'
            ? M('modals.add-payment-method.edit.description')
            : M('modals.add-payment-method.new.description');

    return (
        <Modal size="md" title={title} onModalClose={onModalClose}>
            {type === 'NEW' && paymentMethods.length > 1 && (
                <div className="d-flex dx-g-5 dx-mb-5">
                    {paymentMethods.includes('CREDIT_CARD') && (
                        <BooleanInput
                            type="radio"
                            id="payment-method-card"
                            name="payment-method"
                            className={classNames(styles.radio_input)}
                            checked
                            onChange={() => {}}
                            label={<span className="dx-font-size-xl">{M('modals.add-payment-method.add-card')}</span>}
                        />
                    )}
                    {paymentMethods.includes('PAYPAL') && (
                        <BooleanInput
                            type="radio"
                            id="payment-method-paypal"
                            name="payment-method"
                            className={classNames(styles.radio_input)}
                            checked={false}
                            onChange={(e) => setPayment(e)}
                            label={<span className="dx-font-size-xl">{M('modals.add-payment-method.add-paypal')}</span>}
                        />
                    )}
                </div>
            )}

            <div className="dx-font-size-lg">
                <h5 className="dx-mb-3 font-open-sans--bold">{subtitle}</h5>
                <p className="dx-my-3">{description}</p>
            </div>

            {alert && (
                <Alert variant="danger" icon={<IconError />} className="dx-mb-5">
                    {M('modals.add-payment-method.credit-card.error')}
                </Alert>
            )}

            <form className={styles.form}>
                <p className={classNames(styles.input_container)}>
                    {M('modals.add-payment-method.credit-card.form.name.label')}
                </p>

                <div className={classNames(styles.input_container, styles.has_2, 'dx-mt-2')}>
                    <div className={styles.input_container}>
                        <Input
                            name="firstName"
                            type="text"
                            value={firstName}
                            wrapperClassName={styles.input}
                            label={M('modals.add-payment-method.credit-card.form.firstName.label')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFirstNameCreditCard(e);
                            }}
                            error={errorFirstName !== null}
                            warning={warningFirstName}
                            success={successFirstName}
                        />
                    </div>
                    <div className={styles.input_container}>
                        <Input
                            name="lastName"
                            type="text"
                            value={lastName}
                            wrapperClassName={styles.input}
                            label={M('modals.add-payment-method.credit-card.form.lastName.label') as string}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setLastNameCreditCard(e);
                            }}
                            error={errorLastName !== null}
                            warning={warningLastName}
                            success={successLastName}
                        />
                    </div>
                </div>
                <div className={styles.input_container}>
                    <div className={stylesInput.container}>
                        <label className={stylesInput.label}>
                            {M('modals.add-payment-method.credit-card.form.cardNumber.label')}
                            <span className="color-semantic-danger-500">&nbsp;*</span>
                        </label>
                        <div className={classNames(stylesInput.input_wrapper, styles.input_wrapper)}>
                            <div
                                id="cardNumber"
                                className={classNames(stylesInput.input, 'd-flex align-items-center')}
                            />
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.input_container, styles.has_2)}>
                    <div className={stylesInput.container}>
                        <label className={stylesInput.label}>
                            {M('modals.add-payment-method.credit-card.form.cardDate.label')}
                            <span className="color-semantic-danger-500">&nbsp;*</span>
                        </label>
                        <div className={classNames(stylesInput.input_wrapper, styles.input_wrapper)}>
                            <div
                                id="cardExpiry"
                                className={classNames(stylesInput.input, 'd-flex align-items-center')}
                            />
                        </div>
                    </div>
                    <div className={stylesInput.container}>
                        <label className={stylesInput.label}>
                            {M('modals.add-payment-method.credit-card.form.cardSecurityCode.label')}
                            <span className="color-semantic-danger-500">&nbsp;*</span>
                        </label>
                        <div className={classNames(stylesInput.input_wrapper, styles.input_wrapper)}>
                            <div id="cardCvc" className={classNames(stylesInput.input, 'd-flex align-items-center')} />
                        </div>
                    </div>
                </div>
                <div className="dx-my-5">{M('modals.add-payment-method.credit-card.available-cards')}</div>
                <div className={styles.available_cards}>
                    <Image
                        quality={100}
                        title="VISA"
                        src={ImageVisa}
                        {...availableCardsVisaProps}
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                        }}
                    />
                    <Image
                        quality={100}
                        title="MASTERCARD"
                        src={ImageMasterCard}
                        {...availableCardsMastercardProps}
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                        }}
                    />
                </div>
                {type === 'EDIT' ? (
                    <div className="d-flex justify-content-end dx-mt-10">
                        <Button
                            onClick={onAddPaymentMethod}
                            size="z-md"
                            border="rect"
                            variant="primary"
                            type="submit"
                            disabled={disabledSaveBtn}
                        >
                            {M('modals.add-payment-method.submit')}
                        </Button>
                    </div>
                ) : (
                    <div className="dx-mt-10 d-flex align-items-center justify-content-between">
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                onModalClose(event);
                            }}
                            size="z-md"
                            border="rect"
                            variant="secondary"
                            type="submit"
                        >
                            {M('modals.add-payment-method.cancel')}
                        </Button>
                        <Button
                            onClick={onAddPaymentMethod}
                            size="z-md"
                            border="rect"
                            variant="primary"
                            type="submit"
                            disabled={disabledSaveBtn}
                        >
                            {M('modals.add-payment-method.save')}
                        </Button>
                    </div>
                )}
            </form>
        </Modal>
    );
};

export default AddPaymentMethod;
