import React, { useEffect, useState } from 'react';

import { useProfile } from '~/contexts/NextProfileProvider';
import { useModal } from '~/contexts/NextModalProvider';
import { T_ProfileResponse } from '~/pages/api/profile';
import { AccountType, ParticipationStatus, URLS_DOWNLOAD } from '~/enums/profile';
import { useProfileService } from '~/hooks/services/useProfileService';
import { T_StartParticipationOutput, useParticipationService } from '~/hooks/services/useParticipationService';
import { useRouter } from 'next/router';
import SBStartParticipation, {
    T_DataParticipation,
} from '~/storybook/pages/platform/settings/modals/start-participation/StartParticipation';
import { getFirstParticipation } from '~/utils/participations';
import { getWelcomeUrl } from '~/utils/urls';
import { T_Participation } from '~/services/participation-subscription';
import { T_PartialProfile, UpdateProfileType } from '~/services/profile';

const StartParticipation = () => {
    const router = useRouter();

    const { profile, setPartialProfile } = useProfile();
    const { getProfileInfo } = useProfileService();
    const { startParticipation } = useParticipationService();
    const { closeModal, setModal } = useModal();

    const [participation, setParticipation] = useState<T_Participation | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [dataParticipation, setDataParticipation] = useState<T_DataParticipation>(null);

    useEffect(() => {
        if (profile) {
            const participationProfile = getFirstParticipation(profile);
            if (participationProfile && participationProfile.status === ParticipationStatus.ONBOARDING_COMPLETE) {
                setParticipation(participationProfile);
            }
        }
    }, [JSON.stringify(profile)]);

    useEffect(() => {
        if (participation && participation.id) {
            doStartParticipation();
        }
    }, [participation?.id]);

    const {
        data: startParticipationData,
        error: startParticipationError,
        execute: doStartParticipation,
    } = startParticipation<T_StartParticipationOutput>({
        participationId: participation ? participation.id : '',
    });

    useEffect(() => {
        setLoading(false);
        if (startParticipationData) {
            const data = startParticipationData as T_DataParticipation;
            if (data && participation) {
                data.accountType =
                    `${participation.tradingAccount?.tradingPlatform}${participation.tradingAccount?.familyType}` as AccountType;
            }
            setDataParticipation(data);
            doGetUserData();
        } else if (startParticipationError) {
            closeModal();
            router.push('/metatrader-under-maintenance');
            // setModal('generic-error');
        }
    }, [startParticipationData, startParticipationError]);

    const {
        data: userData,
        error: userError,
        execute: doGetUserData,
    } = getProfileInfo<T_PartialProfile>([UpdateProfileType.PARTICIPATION_SUBSCRIPTION]);

    useEffect(() => {
        if (userData) {
            setPartialProfile(userData);
        } else if (userError) {
            closeModal();
            router.push('/500');
        }
    }, [userData, userError]);

    const onModalClose = () => {
        if (typeof startParticipationError !== 'undefined') {
            router.push('/metatrader-under-maintenance');
            // setModal('generic-error');
        } else if (userData) {
            const profileData = userData as T_ProfileResponse;
            router.push(getWelcomeUrl(profileData));
        }
        closeModal();
    };

    const openExternalLink = (href: string) => {
        window.open(href, 'Darwinex Zero');
    };

    return (
        <SBStartParticipation
            onModalClose={() => onModalClose()}
            data={typeof dataParticipation !== 'undefined' ? dataParticipation : null}
            error={startParticipationError}
            username={profile ? profile.username : ''}
            avatar={profile && profile.avatarUrl ? profile.avatarUrl : ''}
            urlsDownload={URLS_DOWNLOAD}
            openExternalLink={(href) => openExternalLink(href)}
        />
    );
};

export default StartParticipation;
