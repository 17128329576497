import { checkEmptyObject } from '~/common/utils';
import { IncomingHttpHeaders } from 'http';

export enum ContentTypeHeaderValues {
    JSON = 'application/json',
    CSV = 'text/csv',
    TEXT = 'text/plain',
    HTML = 'text/html',
    PDF = 'application/pdf',
}

export type AllowedContentTypes = `${ContentTypeHeaderValues}`;
export type ApiRequestOK<T> = { data: T; statusCode: number; contentType: AllowedContentTypes; ok: true };
export type ApiRequestNOK = { err: unknown; statusCode: number; ok: false };
export type ApiRequestReturn<T> = ApiRequestOK<T> | ApiRequestNOK;

export async function apiRequest<T = unknown>(
    rawHeaders: Headers | IncomingHttpHeaders,
    url: string,
    baseFetchOptions: RequestInit = {},
    shouldLogError = true,
): Promise<ApiRequestReturn<T>> {
    if (process.env.API_URL === undefined) throw new Error('API_URL env must be defined');
    const API: string = process.env.API_URL;
    // if (process.env.NEXT_PUBLIC_DEPLOYED_URL === undefined) throw new Error('API_URL env must be defined');
    // const API: string = process.env.NEXT_PUBLIC_DEPLOYED_URL;

    let convertedHeaders: Headers;
    if (rawHeaders instanceof Headers) {
        convertedHeaders = rawHeaders;
    } else {
        convertedHeaders = convertHeaders(rawHeaders);
    }

    const finalURL = API + url;
    const headers: Record<string, string> = prepareHeaders(
        convertedHeaders,
        baseFetchOptions.method,
        baseFetchOptions.body as string,
    );
    const requestOptions = prepareRequestOptions(baseFetchOptions, headers);

    return doApiRequest<T>(finalURL, requestOptions, shouldLogError);
}

export const convertHeaders = (incomingHttpHeaders: IncomingHttpHeaders): Headers => {
    const headers = new Headers();
    Object.entries(incomingHttpHeaders).forEach(([name, value]) => {
        if (value) {
            if (Array.isArray(value)) {
                value.forEach((v) => headers.append(name, v));
            } else {
                headers.append(name, value);
            }
        }
    });
    return headers;
};

function prepareHeaders(rawHeaders: Headers, method = '', body: string | null = null): Record<string, string> {
    const finalHeaders: Record<string, string> = {};

    const cookieHeader = rawHeaders.get('cookie') ?? '';

    finalHeaders.cookie = cookieHeader;

    const dataSend = ['post', 'put', 'patch'].includes(method.toLowerCase());
    const dataCookie = ['post', 'put', 'patch', 'delete'].includes(method.toLowerCase());

    const xsrf = getCookieFromHeader(cookieHeader, 'XSRF-TOKEN');
    const reff = getCookieFromHeader(cookieHeader, '__reff');

    if (dataSend) Object.assign(finalHeaders, { 'Content-Type': ContentTypeHeaderValues.JSON });
    if (dataCookie && xsrf) Object.assign(finalHeaders, { 'X-XSRF-TOKEN': xsrf });
    if (dataCookie && reff) Object.assign(finalHeaders, { 'X-DX-Referrer-User': reff });

    /* Add user events information */
    const forwarded = rawHeaders.get('x-forwarded-for') ?? '';
    const host = rawHeaders.get('host') ?? '';
    // const { remoteAddress } = req.socket;
    // let ip = typeof forwarded === 'string' ? forwarded.split(/, /)[0] : host ?? remoteAddress ?? 'unknown';
    let ip = typeof forwarded === 'string' ? forwarded.split(/, /)[0] : host ?? 'unknown';
    if (ip && ip.substring(0, 7) === '::ffff:') {
        ip = ip.substring(7);
    }
    Object.assign(finalHeaders, { 'x-forwarded-for': ip });

    const userAgent = rawHeaders.get('user-agent');
    Object.assign(finalHeaders, { 'user-agent': userAgent });

    const trueClientIp = rawHeaders.get('true-client-ip');
    Object.assign(finalHeaders, { 'true-client-ip': trueClientIp });

    const dxOrigin = process.env.NODE_ENV === 'development' ? process.env.DX_ORIGIN : rawHeaders.get('dx-origin');
    Object.assign(finalHeaders, { 'dx-origin': dxOrigin });

    if (body) {
        const bodyRequest = JSON.parse(body);
        const locale = bodyRequest && bodyRequest.locale ? bodyRequest.locale : null;
        if (locale) Object.assign(finalHeaders, { 'Accept-Language': locale });
    }

    return finalHeaders;
}

function prepareRequestOptions(baseOptions: RequestInit, headers: Record<string, string>): RequestInit {
    const optionsFetch: RequestInit = {};

    if (baseOptions.method) Object.assign(optionsFetch, { method: baseOptions.method });
    if (baseOptions.body && !checkEmptyObject(baseOptions.body as object)) {
        const newBody = typeof baseOptions.body === 'object' ? JSON.stringify(baseOptions.body) : baseOptions.body;
        Object.assign(optionsFetch, { body: newBody });
    }
    Object.assign(optionsFetch, { headers });

    return optionsFetch;
}

async function doApiRequest<T>(
    finalURL: string,
    fetchOptions: RequestInit,
    shouldLogError: boolean,
): Promise<ApiRequestReturn<T>> {
    // console.log({ finalURL, fetchOptions });

    const apiResult = await fetch(finalURL, fetchOptions);
    try {
        const body = await apiResult.text();

        if (apiResult.ok) {
            const contentType = apiResult.headers.get('Content-Type') as AllowedContentTypes;
            /**
             * For CSV files, we return the body as is, without parsing it.
             */
            if (contentType && contentType.includes(ContentTypeHeaderValues.CSV)) {
                return {
                    data: body as unknown as T,
                    statusCode: apiResult.status,
                    ok: true,
                    contentType,
                };
            }

            if (body.length === 0) {
                return {
                    data: {} as T,
                    statusCode: apiResult.status,
                    ok: true,
                    contentType,
                };
            }
            try {
                const parsed = JSON.parse(body);
                return {
                    data: parsed,
                    statusCode: apiResult.status,
                    ok: true,
                    contentType,
                };
            } catch (errBody: unknown) {
                console.warn('Error when parsing JSON, but request was succesful. #apiRequest', {
                    // ip: headers.ip,
                    finalURL,
                    method: fetchOptions?.method,
                    status: apiResult.status,
                    err: (errBody as Error)?.message,
                });
                return { err: errBody, statusCode: apiResult.status, ok: false };
            }
        } else {
            if (shouldLogError) {
                console.error(`Error ${apiResult.status} calling #apiRequest ${finalURL} ${apiResult.statusText}`, {
                    // ip: headers.ip,
                    finalURL,
                    method: fetchOptions?.method,
                    status: apiResult.status,
                    err: apiResult.statusText,
                    // userAgent: headers['user-agent'],
                    // 'x-forwaded-for': headers['x-forwaded-for'],
                });
            }
            throw new Error(body);
        }
    } catch (err: unknown) {
        let message = apiResult.statusText;
        if (err instanceof Error) {
            message = err.message;
        }
        try {
            const jsonError = JSON.parse(message);
            return { err: jsonError, statusCode: apiResult.status, ok: false };
        } catch (errorParseJson: unknown) {
            return { err: message, statusCode: apiResult.status, ok: false };
        }
    }
}

function getCookieFromHeader(cookieHeader: string, cookieName: string): string | null {
    const parts = cookieHeader.split(`${cookieName}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(';')?.shift() ?? null;
    }
    return null;
}
