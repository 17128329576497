import { getNumberWithAtLeast2Digit } from '~/utils/format';
import { CompetitionTypes } from '~/enums/competition-types';
import Nullable from '../types/Nullable';

const getEditionRequestFormat = (month: number, year: number): string => `${year}-${getNumberWithAtLeast2Digit(month)}`;

const getEditionMonthAndYearFromRequestFormat = (requestFormat: string): { month: number; year: number } => {
    const month = Number(requestFormat.split('-')[1]);
    const year = Number(requestFormat.split('-')[0]);
    return {
        month,
        year,
    };
};

const getCompetitionRequestString = (competition: Nullable<CompetitionTypes>): string => {
    if (competition === CompetitionTypes.SILVER) return 'silver';
    if (competition === CompetitionTypes.GOLD) return 'gold';
    if (competition === CompetitionTypes.CLASSIC) return 'classic';
    return '';
};

export { getEditionRequestFormat, getEditionMonthAndYearFromRequestFormat, getCompetitionRequestString };
