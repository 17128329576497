import { KnowledgeTestAnswers } from '~/storybook/pages/platform/signup/modals/KnowledgeTest';
import useFetch from '~/hooks/useFetch';
import Nullable from '~/types/Nullable';

export interface QuestionAnswersMap {
    questionId: string;
    answers: string[];
}

export enum KnowledgeTestStatusEnum {
    KNOWLEDGE_TEST_LOADING = 'KNOWLEDGE_TEST_LOADING',
    KNOWLEDGE_TEST_PRE = 'KNOWLEDGE_TEST_PRE',
    KNOWLEDGE_TEST_NATIONAL_ID = 'KNOWLEDGE_TEST_NATIONAL_ID',
    KNOWLEDGE_TEST_QUESTIONS = 'KNOWLEDGE_TEST_QUESTIONS',
    KNOWLEDGE_TEST_ERROR = 'KNOWLEDGE_TEST_ERROR',
    KNOWLEDGE_TEST_SUCCESS = 'KNOWLEDGE_TEST_SUCCESS',
    KNOWLEDGE_TEST_PRE_NATIONAL_ID = 'KNOWLEDGE_TEST_PRE_NATIONAL_ID',
}

export type KnowledgeTestStepStatus =
    | KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE
    | KnowledgeTestStatusEnum.KNOWLEDGE_TEST_NATIONAL_ID
    | KnowledgeTestStatusEnum.KNOWLEDGE_TEST_QUESTIONS
    | KnowledgeTestStatusEnum.KNOWLEDGE_TEST_ERROR
    | KnowledgeTestStatusEnum.KNOWLEDGE_TEST_SUCCESS
    | KnowledgeTestStatusEnum.KNOWLEDGE_TEST_LOADING
    | KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE_NATIONAL_ID;

export type KnowledgeTestFlowAction = 'REGISTER' | 'RESTART' | 'NAVIGATION';

export type KnowledgeTestStatus = {
    status: 'NOT_VALID' | 'VALID';
    score: number;
    nextAllowedDate: number;
    nationalId: Nullable<string>;
};

const KNOWLEDGE_TEST_MIFID_URL = '/api/mifid/knowledge-tests';
const KNOWLEDGE_TEST_DNI_URL = '/api/mifid/knowledge-tests';

const useGetKnowledgeTestQuestions = () => {
    const { data, status, error, execute } = useFetch<{ knowledgeTest: QuestionAnswersMap[] }>(
        KNOWLEDGE_TEST_MIFID_URL,
    );
    return {
        data,
        status,
        error,
        execute,
    };
};

const useGetKnowledgeTestDNI = () => {
    const { data, status, error, execute } = useFetch<{ knowledgeTestDNI: Nullable<string> }>(KNOWLEDGE_TEST_DNI_URL);
    return {
        data,
        status,
        error,
        execute,
    };
};

export const getKnowledgeTestStatus = async (): Promise<KnowledgeTestStatus> => {
    const res = await fetch(`${KNOWLEDGE_TEST_MIFID_URL}/me`);
    return res.json();
};

const setKnowledgeTestAnswers = async (
    answers: KnowledgeTestAnswers,
    nationalId: Nullable<string>,
    action: KnowledgeTestFlowAction,
    hasValidCoupon: Nullable<boolean>,
): Promise<Response> => {
    const transformedAnswers = Object.entries(answers).map(([questionId, answerId]) => ({
        questionId,
        answerId,
    }));

    const body = {
        responses: transformedAnswers,
        action,
        ...(hasValidCoupon !== null && { liteValidation: hasValidCoupon }),
        ...(nationalId && { nationalId: nationalId.trim().toUpperCase() }),
    };

    return fetch(`${KNOWLEDGE_TEST_MIFID_URL}/me/responses`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
};

export const useKnowledgeTest = () => ({
    useGetKnowledgeTestQuestions,
    useGetKnowledgeTestDNI,
    getKnowledgeTestStatus,
    setKnowledgeTestAnswers,
});
