import React from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { Alert, Button, IconInfoEmpty, Modal } from '@darwinex/components-library';
import Spinner from '~/storybook/components/common/spinner/Spinner';
import styles from './CancelParticipationManual.module.scss';

interface Props {
    onModalClose: React.MouseEventHandler;
    onCancelSubscription: () => void;
    cancelRequested?: boolean;
}

const CancelParticipationManual = ({ onModalClose, onCancelSubscription, cancelRequested = true }: Props) => {
    const { M } = useI18N();
    return (
        <Modal size="md" title={M('modals.cancel-participation-manual.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg">
                <h5 className="dx-mb-3 font-open-sans--bold">
                    {M('modals.cancel-participation-manual.are-you-sure.title')}
                </h5>
                <p className="dx-my-3">{M('modals.cancel-participation-manual.are-you-sure.description')}</p>
                <p className="dx-my-3 font-open-sans--bold">
                    {M('modals.cancel-participation-manual.are-you-sure.participate-again')}
                </p>
            </div>
            <div className="dx-mt-9 d-flex align-items-center justify-content-between">
                <Button onClick={onModalClose} size="z-md" border="rect" variant="secondary" type="submit">
                    {M('modals.cancel-participation-manual.discard-cta')}
                </Button>
                <Button
                    onClick={onCancelSubscription}
                    size="z-md"
                    border="rect"
                    variant="danger"
                    type="submit"
                    disabled={cancelRequested}
                >
                    {cancelRequested ? (
                        <Spinner size={24} className="dx-mx-auto" />
                    ) : (
                        M('modals.cancel-participation-manual.request-cta')
                    )}
                </Button>
            </div>
        </Modal>
    );
};

export default CancelParticipationManual;
