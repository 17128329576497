import { getNumberWithAtLeast2Digit } from './format';

const PRODUCT_NAME_REG_EXP = /^[A-Z]{3,4}\.\d+\.\d+/;
const TICKER_REG_EXP = /^[A-Z]{3,4}\.\d+/;

const getShortName = (productName: string): string => productName.split('.')[0];

const getColorIndexFromProductName = (productName: string): number => Number(productName.split('.')[2]);

const getColorIndexFromTicker = (ticker: string): number => Number(ticker.split('.')[1]);

const getVarColorFromProductName = (productName: string): string => {
    const colorIndex = getColorIndexFromProductName(productName);
    return `--dxcl-c-darwin-${getNumberWithAtLeast2Digit(colorIndex)}`;
};

const getVarColorFromTicker = (ticker: string): string => {
    const colorIndex = getColorIndexFromTicker(ticker);
    return `--dxcl-c-darwin-${getNumberWithAtLeast2Digit(colorIndex)}`;
};

const getVersion = (productName: string): number => Number(productName.split('.')[1]);

const getShortNameFromTicker = (ticker: string): string => ticker.split('.')[0];

const checkProductNameFormat = (productName: string): void | Error => {
    if (!PRODUCT_NAME_REG_EXP.test(productName)) {
        throw Error('Product name format is not correct. Correct format example: NTIA.5.12');
    }
};

const checkTickerFormat = (ticker: string): void | Error => {
    if (!TICKER_REG_EXP.test(ticker)) {
        throw Error('Ticker format is not correct. Correct format example: NTIA.12');
    }
};

export {
    getShortName,
    getColorIndexFromProductName,
    getColorIndexFromTicker,
    getVarColorFromProductName,
    getVarColorFromTicker,
    getVersion,
    getShortNameFromTicker,
    checkProductNameFormat,
    checkTickerFormat,
};
