import useFetch from '~/hooks/useFetch';
import { OperationType } from '~/enums/operation-type';
import Nullable from '../../types/Nullable';

interface State<T> {
    data?: T;
    error?: Error;
    status?: number;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const PROVIDER_PORTAL_MS_URL = '/api/providerportal';

export interface DarwinStatsInfo {
    // monthlyDivergencePercentage: Nullable<number>;
    // monthlyLatencyInMs: Nullable<number>;
    // productId: Nullable<number>;
    productName: Nullable<string>;
    // strategyName: Nullable<string>;
    targetVarPercentage: Nullable<number>;
    varRatio: Nullable<number>;
}

export interface ClosureRateInfo {
    value: Nullable<number>;
    lastUpdateTime: Nullable<number>;
}

export interface OpenPositionInfo {
    symbol: string;
    type: OperationType;
    traderVWAP: string;
    traderVolume: number;
    darwinEstimatedVolume: number;
}

export const useProviderPortalService = () => {
    const useGetRisk = (productName: Nullable<string>): UseFetchReturnType<Array<DarwinStatsInfo>> => {
        const { data, status, error, execute } = useFetch<Array<DarwinStatsInfo>>(
            `${PROVIDER_PORTAL_MS_URL}/darwinStats/bootcamp`,
        );

        return {
            data: data || [],
            status,
            error,
            execute: () => {
                if (productName) {
                    execute();
                }
            },
        };
    };

    const useGetDarwinClosureRate = (productName: Nullable<string>): UseFetchReturnType<ClosureRateInfo> => {
        const { data, status, error, execute } = useFetch<ClosureRateInfo>(
            `${PROVIDER_PORTAL_MS_URL}/darwins/${productName}/closurerate`,
        );
        return {
            data: data || {
                lastUpdateTime: null,
                value: null,
            },
            status,
            error,
            execute: () => {
                if (productName) {
                    execute();
                }
            },
        };
    };

    const useGetOpenPositions = (productName: Nullable<string>): UseFetchReturnType<Array<OpenPositionInfo>> => {
        const { data, status, error, execute } = useFetch<Array<OpenPositionInfo>>(
            `${PROVIDER_PORTAL_MS_URL}/darwins/${productName}/positions/open`,
        );
        return {
            data: data || [],
            status,
            error,
            execute: () => {
                if (productName) {
                    execute();
                }
            },
        };
    };

    return {
        getRisk: useGetRisk,
        getDarwinClosureRate: useGetDarwinClosureRate,
        getOpenPositions: useGetOpenPositions,
    };
};
