import React from 'react';
import { KnowledgeTestStatusEnum, KnowledgeTestStepStatus } from '~/hooks/services/useKnowledgeTest';
import KnowledgeTestModal from '~/components/modals/knowledge-test';
import moment from 'moment/moment';

import Nullable from '~/types/Nullable';
import { useModal } from '~/contexts/NextModalProvider';

interface Props {
    configuration: Record<string, any> | null;
    requestOriginCountry: Nullable<string>;
}

const KnowledgeTestOnboardingModal = ({ configuration, requestOriginCountry }: Props) => {
    const { closeModal } = useModal();

    // console.log('configuration', configuration);

    const testStatus = configuration?.initialKnowledgeTestModalStatus ?? null;
    let knowledgeTestModalStatus: Nullable<KnowledgeTestStepStatus> = null;

    // console.log('#KnowledgeTestOnboardingModal testStatus', testStatus);
    if (!testStatus || typeof testStatus.status === 'undefined' || !testStatus.status) {
        // console.log('Inside if', { testStatus });
        knowledgeTestModalStatus = KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE;
    } else {
        // console.log('Inside else', { testStatus });
        if (testStatus.status === 'NOT_VALID') {
            knowledgeTestModalStatus = KnowledgeTestStatusEnum.KNOWLEDGE_TEST_ERROR;
            // console.log('Has old not valid test', { testStatus });
            if (testStatus?.nextAllowedDate) {
                // console.log('Inside calculate next allowed date', { testStatus });
                const nowDate = moment.utc();
                const nextAllowedDate = moment.utc(testStatus.nextAllowedDate);
                if (nextAllowedDate.isBefore(nowDate)) {
                    knowledgeTestModalStatus = KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE;
                }
            }
        }
    }

    if (!knowledgeTestModalStatus) {
        setTimeout(() => {
            closeModal();
        }, 0);
        return null;
    }

    // console.log('#knowledgeTestOnboarding', { knowledgeTestModalStatus });

    return (
        <KnowledgeTestModal
            initialKnowledgeTestModalStatus={knowledgeTestModalStatus as KnowledgeTestStatusEnum}
            onModalClose={closeModal}
            onContinuePay={closeModal}
            retryDateInMs={testStatus?.nextAllowedDate}
            flow="NAVIGATION"
            requestOriginCountry={requestOriginCountry}
            onChangeInfo={() => {}}
            hasValidCoupon={null}
        />
    );
};

export default KnowledgeTestOnboardingModal;
