import React, { useState } from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import {
    Alert,
    BooleanInput,
    Button,
    IconCopyText,
    IconDarwinexOwl,
    IconExternalLink,
    IconSuccessCircleEmpty,
    IconWarningRounded,
    Input,
    Modal,
    IconUser,
} from '@darwinex/components-library';
import { T_StartParticipationOutput } from '~/hooks/services/useParticipationService';
import classNames from 'classnames';
import { AccountType } from '~/enums/profile';
import DropdownTooltip from '~/components/misc/dropdowntooltip';
import Image from 'next/image';
import UserAvatar from '~/public/images/avatar-default.png';
import Spinner from '~/storybook/components/common/spinner/Spinner';
import { getNumberWithAtLeast2Digit } from '~/utils/format';
import styles from './StartParticipation.module.scss';

export type T_DataParticipation =
    | (T_StartParticipationOutput & {
          accountType: AccountType;
      })
    | null;

interface StartParticipationProps {
    onModalClose: React.MouseEventHandler;
    // eslint-disable-next-line camelcase
    data: T_DataParticipation;
    urlsDownload: Record<string, any>;
    avatar: string;
    error?: any;
    username: string;
    openExternalLink: (href: string) => void;
}

const StartParticipation = ({
    onModalClose,
    data,
    urlsDownload,
    avatar,
    error,
    username,
    openExternalLink,
}: StartParticipationProps) => {
    const { M } = useI18N();
    const [showInvestorPassword, setShowInvestorPassword] = useState(false);

    const onOpenWebTerminal = () => {
        if (data && data.accountType) {
            openExternalLink(urlsDownload[data.accountType].webTerminal);
        }
    };
    const onDownloadWindows = () => {
        if (data && data.accountType) {
            openExternalLink(urlsDownload[data.accountType].loadWindows);
        }
    };
    const onDownloadMacOS = () => {
        if (data && data.accountType) {
            openExternalLink(urlsDownload[data.accountType].loadMacOS);
        }
    };
    const onDownloadLinux = () => {
        if (data && data.accountType) {
            openExternalLink(urlsDownload[data.accountType].loadLinux);
        }
    };
    const onDownloadAndroid = () => {
        if (data && data.accountType) {
            openExternalLink(urlsDownload[data.accountType].loadAndroid);
        }
    };
    const onDownloadIOS = () => {
        if (data && data.accountType) {
            openExternalLink(urlsDownload[data.accountType].loadIOS);
        }
    };

    const [darwinName, darwinColor] = data?.ticker?.split('.') ?? [undefined, undefined];
    const wantInvestorPasswordLabel = data && (
        <span className="font-open-sans--bold">{M(`modals.start-participation.commons.i-want-readonly-password`)}</span>
    );

    const modalTitle = !data ? (
        ''
    ) : (
        <div className={styles.modal_title}>{M('modals.start-participation.title', { username })}</div>
    );

    const getUserAvatar = () => {
        if (avatar) {
            return (
                <Image
                    title={username}
                    alt={username}
                    src={avatar}
                    quality={100}
                    width={80}
                    height={80}
                    className={styles.avatar}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            );
        }
        return <IconUser width={80} height={80} color="var(--dxcl-c-tertiary-300)" />;
    };

    return (
        <Modal
            size="auto"
            title={modalTitle}
            headerIcon={data ? <DarwinTicker className="dx-mx-5" darwinName={darwinName} /> : null}
            onModalClose={onModalClose}
            className={classNames(
                styles.start_participation_modal,
                darwinColor && styles[`c_${getNumberWithAtLeast2Digit(darwinColor)}`],
            )}
            headerClassName={styles.header}
            headerIconClassName={styles.header_icon}
            iconCloseClassName={styles.close_icon}
            bodyClassName={styles.body}
        >
            {!data && (
                <div className={styles.loading}>
                    <Spinner size={48} />
                </div>
            )}
            {data && (
                <div className={styles.layout}>
                    <div>
                        <div className={styles.boxes_container}>
                            <div className={styles.box}>
                                {M('modals.start-participation.darwin-name')}
                                <DarwinTicker darwinName={darwinName} />
                            </div>
                            <div className={styles.box}>
                                {M('modals.start-participation.darwin-color')}
                                <div className={styles.darwin_color_ball} />
                            </div>
                            <div className={styles.box}>
                                {M('modals.start-participation.avatar-chosen')}
                                {getUserAvatar()}
                            </div>
                        </div>
                        <Alert
                            variant="info"
                            icon={<IconWarningRounded width={24} height={24} />}
                            className="dx-py-3 dx-mb-3"
                            iconClassName={styles.alert_icon}
                            shadow={false}
                        >
                            {M('modals.start-participation.change-avatar-alert')}
                        </Alert>
                        <div className={styles.separator}></div>
                        <div className="dx-my-3 d-flex flex-column align-items-center">
                            <span className="dx-mx-5 dx-mb-3 text-center">
                                {M('modals.start-participation.dont-know-darwin-yet')}
                            </span>
                            <a
                                href="https://www.darwinexzero.com/docs/en/what-is-a-darwin"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {' '}
                                {M('modals.start-participation.darwin-know-more')}
                            </a>
                        </div>
                        <div className={styles.separator}></div>
                    </div>
                    <div>
                        <div className="d-flex align-items-center dx-gx-5 dx-mt-mb-0">
                            <IconSuccessCircleEmpty
                                width={24}
                                height={24}
                                className="flex-shrink-0 color-semantic-success-600"
                            />
                            <div className="color-secondary-700 dx-font-size-xxl font-open-sans--bold">
                                {M(`modals.start-participation.${data.accountType}.account-created`)}
                            </div>
                        </div>
                        <div className={classNames(styles.separator, 'dx-my-5')} />
                        <div className="color-secondary-600">
                            <Input
                                readOnly
                                type="text"
                                name="login"
                                onChange={noop}
                                wrapperClassName={styles.input}
                                value={data.brokerAccountNumber}
                                label={M(`modals.start-participation.${data.accountType}.login`)}
                                afterContent={<InputCopyToClipboard ctcText={data.brokerAccountNumber} />}
                            />
                        </div>
                        <div className="dx-mt-7 color-secondary-600">
                            <Input
                                readOnly
                                type="text"
                                name="login"
                                onChange={noop}
                                wrapperClassName={styles.input}
                                value={data.password}
                                label={M(`modals.start-participation.commons.password`)}
                                afterContent={<InputCopyToClipboard ctcText={data.password} />}
                            />
                        </div>
                        <div className="dx-mt-7 color-secondary-600">
                            <Input
                                readOnly
                                type="text"
                                name="login"
                                onChange={noop}
                                wrapperClassName={styles.input}
                                value={`${data.serverName} (${data.url})`}
                                label={M(`modals.start-participation.commons.server`)}
                                afterContent={<InputCopyToClipboard ctcText={data.url} />}
                            />
                        </div>
                        <div className={classNames(styles.separator, 'dx-my-5')} />
                        <BooleanInput
                            id="want-required-password"
                            name="want-required-password"
                            type="checkbox"
                            label={wantInvestorPasswordLabel}
                            checked={showInvestorPassword}
                            onChange={(e) => setShowInvestorPassword((p) => !p)}
                            color="secondary"
                        />
                        {showInvestorPassword && (
                            <div className="dx-mt-5 color-secondary-600">
                                <Input
                                    readOnly
                                    type="text"
                                    name="login"
                                    onChange={noop}
                                    wrapperClassName={styles.input}
                                    value={data.investorPassword}
                                    label={M(`modals.start-participation.commons.investorPassword`)}
                                    afterContent={<InputCopyToClipboard ctcText={data.investorPassword} />}
                                />
                            </div>
                        )}
                        <div className={classNames(styles.separator, 'dx-my-5')} />
                        <p className="dx-mb-3">{M(`modals.start-participation.commons.check-margin-requirements`)}</p>
                        <p>{M(`modals.start-participation.commons.details-sent-to-email`)}</p>
                        <div className={classNames(styles.separator, 'dx-my-5')} />
                        <p className="dx-mb-3 font-open-sans--bold">
                            {M(`modals.start-participation.start-trading-now`)}
                        </p>
                        <Button size="z-md" variant="primary" border="rect" type="button" onClick={onOpenWebTerminal}>
                            <span style={{ whiteSpace: 'break-spaces' }}>
                                {M(`modals.start-participation.${data.accountType}.open-web-terminal`)}
                                <IconExternalLink width={16} height={16} className="dx-ml-3" />
                            </span>
                        </Button>
                        <p className="dx-my-3 font-open-sans--bold">
                            {M(`modals.start-participation.or-download-terminal`)}
                        </p>
                        <div className="d-flex dx-g-5 flex-wrap">
                            <Button
                                size="z-md"
                                variant="secondary"
                                border="rect"
                                type="button"
                                className="flex-1"
                                onClick={onDownloadWindows}
                            >
                                {M(`modals.start-participation.${data.accountType}.download-windows`)}
                            </Button>
                            <Button
                                size="z-md"
                                variant="secondary"
                                border="rect"
                                type="button"
                                className="flex-1"
                                onClick={onDownloadMacOS}
                            >
                                {M(`modals.start-participation.${data.accountType}.download-macos`)}
                            </Button>
                            <Button
                                size="z-md"
                                variant="secondary"
                                border="rect"
                                type="button"
                                className="flex-1"
                                onClick={onDownloadLinux}
                            >
                                {M(`modals.start-participation.${data.accountType}.download-linux`)}
                            </Button>
                            <Button
                                size="z-md"
                                variant="secondary"
                                border="rect"
                                type="button"
                                className="flex-1"
                                onClick={onDownloadAndroid}
                            >
                                {M(`modals.start-participation.${data.accountType}.download-android`)}
                            </Button>
                            <Button
                                size="z-md"
                                variant="secondary"
                                border="rect"
                                type="button"
                                className="flex-1"
                                onClick={onDownloadIOS}
                            >
                                {M(`modals.start-participation.${data.accountType}.download-ios`)}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

const DarwinTicker = ({ darwinName, className }: { darwinName?: string; className?: string }) => {
    if (!darwinName) {
        // throw new Error(`Must have darwinName. Received: "${darwinName}"`);
        return null;
    }
    return (
        <div className={classNames('d-flex align-items-center', className)}>
            <IconDarwinexOwl width={24} />
            <span className="dx-ml-4 dx-mt-2 dx-font-size-xxl font-open-sans--extra-bold">{darwinName}</span>
        </div>
    );
};

const InputCopyToClipboard = ({ ctcText }: { ctcText: string }) => {
    const { M } = useI18N();
    const [tooltipVisible, setTooltipVisible] = useState(false);

    async function copyToClipboard(text: string) {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2500);
        return navigator.clipboard.writeText(text);
    }

    return (
        <div className={styles.input_ctc}>
            <DropdownTooltip
                dropup
                trigger="click"
                open={tooltipVisible}
                menuClasses={styles.ctc_menu}
                onOpen={() => copyToClipboard(ctcText)}
                content={<div className={styles.ctc_tooltip}>{M('commons.copied-to-clipboard')}</div>}
            >
                <IconCopyText width={20} height={20} />
            </DropdownTooltip>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

export default StartParticipation;
