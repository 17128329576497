import React, { ChangeEvent } from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { BooleanInput, Button, Modal } from '@darwinex/components-library';
import classNames from 'classnames';
import { PaymentType } from '~/pages/checkout';
import { AddPaymentMethodTypes } from '~/storybook/pages/platform/settings/modals/add-payment-method/AddPaymentMethod';
import styles from './AddPaymentMethod.module.scss';

interface Props {
    onModalClose: React.MouseEventHandler;
    paymentMethods: PaymentType[];
    setPayment?: (e: ChangeEvent) => void;
}

const AddPayPalMethod = ({ onModalClose, paymentMethods, setPayment }: Props) => {
    const { M } = useI18N();

    const title = M('modals.add-payment-method.new.title');
    const subtitle = M('modals.add-payment-method.new.subtitle');
    const description = M('modals.add-payment-method.new.description');

    return (
        <Modal size="md" title={title} onModalClose={onModalClose}>
            {paymentMethods.length > 1 && (
                <div className="d-flex dx-g-5 dx-mb-5">
                    {paymentMethods &&
                        paymentMethods.includes('CREDIT_CARD') &&
                        typeof setPayment !== 'undefined' &&
                        setPayment && (
                            <BooleanInput
                                type="radio"
                                id="payment-method-card"
                                name="payment-method"
                                className={classNames(styles.radio_input)}
                                checked={false}
                                onChange={setPayment}
                                label={
                                    <span className="dx-font-size-xl">{M('modals.add-payment-method.add-card')}</span>
                                }
                            />
                        )}
                    {paymentMethods.includes('PAYPAL') && (
                        <BooleanInput
                            type="radio"
                            id="payment-method-paypal"
                            name="payment-method"
                            className={classNames(styles.radio_input)}
                            checked
                            onChange={() => {}}
                            label={<span className="dx-font-size-xl">{M('modals.add-payment-method.add-paypal')}</span>}
                        />
                    )}
                </div>
            )}

            <div className={styles.container}>
                <div className="dx-font-size-lg">
                    <h5 className="dx-mb-3 font-open-sans--bold">{subtitle}</h5>
                    <p className="dx-my-3">{description}</p>
                </div>

                <div className="dx-my-10">
                    <div id="paypal-button-modal" className="center" />
                </div>
            </div>

            <form className={styles.form}>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        onModalClose(event);
                    }}
                    size="z-md"
                    border="rect"
                    variant="secondary"
                    type="submit"
                >
                    {M('modals.add-payment-method.cancel')}
                </Button>
            </form>
        </Modal>
    );
};

export default AddPayPalMethod;
