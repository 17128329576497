import React, { useEffect, useState } from 'react';

import {
    KnowledgeTestAnswers,
    KnowledgeTestErrorWithPayment,
    KnowledgeTestErrorNoPayment,
    KnowledgeTestForm,
    KnowledgeTestLoading,
    KnowledgeTestSuccessWithPayment,
    KnowledgeTestSuccessNoPayment,
} from '~/storybook/pages/platform/signup/modals/KnowledgeTest';
import { KnowledgeTestIntro } from '~/storybook/pages/platform/signup/modals/KnowledgeTestIntro';
import {
    type KnowledgeTestFlowAction,
    KnowledgeTestStatus,
    KnowledgeTestStatusEnum,
    useKnowledgeTest,
} from '~/hooks/services/useKnowledgeTest';
import { KnowledgeTestIntroNationalID } from '~/storybook/pages/platform/signup/modals/KnowledgeTestIntroNationalID';
import { T_FormInfo, useValidation } from '~/hooks/useValidation';
import { useI18N } from '~/contexts/NextI18NProvider';
import { KnowledgeTestMandatory } from '~/storybook/pages/platform/signup/modals/KnowledgeTestMandatory';
import Nullable from '~/types/Nullable';
import { ServiceAvailability } from '~/enums/profile';
import { Currency } from '~/enums/Currency';

interface KnowledgeTestModalProps {
    initialKnowledgeTestModalStatus: KnowledgeTestStatusEnum;
    onModalClose: () => void;
    onContinuePay: () => void;
    retryDateInMs?: number;
    mustFillNationalID?: boolean;
    continueToPay?: boolean;
    flow: KnowledgeTestFlowAction;
    canExitTestWithoutFinishing?: boolean;
    requestOriginCountry: Nullable<string>;
    previouslySelectedServiceId?: undefined;
    onChangeInfo: (
        tradingPlatform: string,
        familyType: string,
        familyAvailability: ServiceAvailability,
        currency: Currency,
        serviceId: string,
        couponValue?: Nullable<string>,
    ) => void;
    onChangeFinalPaymentAmount?: (finalPaymentAmount: number) => void;
    hasValidCoupon: Nullable<boolean>;
}

const KnowledgeTestModal = ({
    initialKnowledgeTestModalStatus,
    onModalClose,
    retryDateInMs,
    onContinuePay,
    flow,
    mustFillNationalID = false,
    continueToPay = false,
    canExitTestWithoutFinishing = false,
    requestOriginCountry,
    previouslySelectedServiceId,
    onChangeInfo,
    onChangeFinalPaymentAmount,
    hasValidCoupon,
}: KnowledgeTestModalProps) => {
    const { M } = useI18N();
    const [knowledgeTestModalStatus, setKnowledgeTestModalStatus] = useState<KnowledgeTestStatusEnum>(
        initialKnowledgeTestModalStatus,
    );
    const [knowledgeTestStatus, setKnowledgeTestStatus] = useState<KnowledgeTestStatus | null>(null);

    const [retryDateInMsTest, setRetryDateInMsTest] = useState<number>(retryDateInMs ?? 0);

    const [invalidNationalIDs, setInvalidNationalIDs] = useState<Set<string>>(new Set());
    const [ktSubmitError, setKtSubmitError] = useState<Nullable<'INVALID_NATIONAL_ID' | 'DUPLICATED_NATIONAL_ID'>>();

    const { useGetKnowledgeTestQuestions, setKnowledgeTestAnswers, getKnowledgeTestStatus } = useKnowledgeTest();

    const { data: knowledgeTestQuestions, execute: doGetKnowledgeTestQuestions } = useGetKnowledgeTestQuestions();
    useEffect(() => {
        getKnowledgeTestStatus()
            .then((ktStatus) => {
                setKnowledgeTestStatus(ktStatus);
                if (ktStatus.nationalId) setFieldValue('nationalId', ktStatus.nationalId);
                return true;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }, []);

    const formInfo: T_FormInfo = {
        onFormSuccess: async () => {
            setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_QUESTIONS);
        },
        liveValidation: false,
        fields: [
            {
                name: 'nationalId',
                value: '',
                validators: [
                    {
                        type: 'required',
                        customTypeData: null,
                        value: '',
                        errorMessage: null,
                    },
                    {
                        type: 'maxlength',
                        customTypeData: {
                            minLength: 9,
                            maxLength: 9,
                        },
                        value: '',
                        errorMessage: M('errors.commons.maxlength', { min: 9, max: 9 }),
                    },
                    {
                        type: 'hasHTML',
                        customTypeData: null,
                        value: '',
                        errorMessage: M('errors.commons.hasHTML'),
                    },
                ],
            },
        ],
    } as T_FormInfo;

    const { fieldsData, setFieldValue, validateFields, isValidateForm } = useValidation(formInfo);
    const { nationalId } = fieldsData;

    useEffect(() => {
        if (
            [
                KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE as string,
                KnowledgeTestStatusEnum.KNOWLEDGE_TEST_NATIONAL_ID as string,
            ].includes(knowledgeTestModalStatus)
        ) {
            doGetKnowledgeTestQuestions();
        }
    }, [knowledgeTestModalStatus]);

    const onKnowledgeFormQuestionsFinished = async (answers: KnowledgeTestAnswers) => {
        setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_LOADING);
        const response = await setKnowledgeTestAnswers(
            answers,
            mustFillNationalID ? (nationalId.value as string) : null,
            flow,
            hasValidCoupon,
        );
        if (response.ok) {
            const testResult: KnowledgeTestStatus = await response.json();
            if (testResult?.status === 'NOT_VALID') {
                setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_ERROR);
                setRetryDateInMsTest(testResult?.nextAllowedDate ?? 0);
                return;
            }
            if (testResult?.status === 'VALID') {
                setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_SUCCESS);
                return;
            }
            onModalClose();
        } else if (response.status === 400) {
            const err = await response.json();
            if (err.message === 'INVALID_NATIONAL_ID') {
                setKtSubmitError(err.message);
                setInvalidNationalIDs((p) => p.add(nationalId.value as string));
                setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_NATIONAL_ID);
                return;
            }
            if (err.message === 'DUPLICATED_NATIONAL_ID') {
                setKtSubmitError(err.message);
                setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_NATIONAL_ID);
            } else {
                throw new Error(`Unknown error submitting test... ${err}`);
            }
        } else {
            throw new Error(`Unknown error sending response to the test. HTTP Status: ${response.status}`);
        }
    };

    const nationalIdHasError = (): string | null => {
        if (typeof nationalId !== 'undefined' && nationalId.error !== null) {
            return nationalId.error as string;
        }
        const KT_SUBMIT_ERROR_COPIES_MAP = {
            INVALID_NATIONAL_ID: M('errors.commons.correct') as string,
            DUPLICATED_NATIONAL_ID: M('knowledge-test.modals.intro.dni.error') as string,
        } as const;
        if (ktSubmitError) {
            return KT_SUBMIT_ERROR_COPIES_MAP[ktSubmitError];
        }
        return null;
    };

    // console.log('#knowledgeTest -> ', {
    //     knowledgeTestModalStatus,
    //     knowledgeTestStatus,
    //     fieldsData,
    //     formInfo,
    //     invalidNationalIDs,
    // });

    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_LOADING) {
        return <KnowledgeTestLoading onClose={() => onModalClose()} />;
    }
    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE) {
        return (
            <KnowledgeTestIntro
                onGoToQuestions={() => setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_QUESTIONS)}
                onModalClose={() => {
                    onModalClose();
                }}
                flow={flow}
            />
        );
    }

    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_NATIONAL_ID) {
        const nationalIdError = nationalIdHasError();
        const isInputValid = isValidateForm !== null && isValidateForm !== false && !!nationalIdError;
        return (
            <KnowledgeTestIntroNationalID
                onGoToQuestions={() => (!nationalIdError ? validateFields() : undefined)}
                onModalClose={() => onModalClose()}
                nationalId={(nationalId?.value as string) ?? ''}
                setNationalId={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // Reset previous errors
                    setKtSubmitError(null);

                    const inp = e.target.value.toUpperCase();
                    setFieldValue('nationalId', inp);
                    if (inp.length === 9 && !validateSpanishDNI(inp)) {
                        setKtSubmitError('INVALID_NATIONAL_ID');
                    }
                }}
                error={!!nationalIdError}
                success={isInputValid}
                validationMsg={nationalIdError ?? undefined}
            />
        );
    }

    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_QUESTIONS && knowledgeTestQuestions) {
        return (
            <KnowledgeTestForm
                questions={knowledgeTestQuestions.knowledgeTest}
                onFormFinished={onKnowledgeFormQuestionsFinished}
                onClose={() => onModalClose()}
                canExitTestWithoutFinishing={canExitTestWithoutFinishing}
            />
        );
    }
    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_ERROR && continueToPay) {
        return (
            <KnowledgeTestErrorWithPayment
                retryDateInMs={retryDateInMsTest}
                onCancel={() => onModalClose()}
                onContinuePay={() => {
                    onContinuePay();
                }}
                onChangeInfo={onChangeInfo}
                onChangeFinalPaymentAmount={onChangeFinalPaymentAmount}
                requestOriginCountry={requestOriginCountry}
                previouslySelectedServiceId={previouslySelectedServiceId}
            />
        );
    }
    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_ERROR && !continueToPay) {
        return <KnowledgeTestErrorNoPayment onCancel={() => onModalClose()} />;
    }
    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_SUCCESS && continueToPay) {
        return (
            <KnowledgeTestSuccessWithPayment
                onContinue={() => {
                    onContinuePay();
                }}
                onCancel={() => onModalClose()}
            />
        );
    }
    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_SUCCESS && !continueToPay) {
        return (
            <KnowledgeTestSuccessNoPayment
                onContinue={() => {
                    onContinuePay();
                }}
            />
        );
    }
    if (knowledgeTestModalStatus === KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE_NATIONAL_ID) {
        return (
            <KnowledgeTestMandatory
                onGoToDNI={() => {
                    setKnowledgeTestModalStatus(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_NATIONAL_ID);
                }}
                flow={flow}
            />
        );
    }

    throw Error(`Unknown Knowledge Test Status: ${knowledgeTestModalStatus}`);
    return null;
};

/**
 * Validate a spanish national ID
 * @param possibleDNI {string}
 * @return boolean Return if passed string is valid
 */
function validateSpanishDNI(possibleDNI: string) {
    const stringDNI = possibleDNI.toUpperCase();
    const DNI_VALIDATION_LETTER_CHARSET = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const FOREIGN_CHARACTERS_REPLACEMENTS = new Map([
        ['X', '0'],
        ['Y', '1'],
        ['Z', '2'],
    ]);

    if (stringDNI.length !== 9) return false;
    const firstPart = stringDNI.slice(0, 8);
    const checkLetter = stringDNI.slice(8, 9);
    const firstChar = firstPart.charAt(0);
    const dniNumberFirstChar = FOREIGN_CHARACTERS_REPLACEMENTS.get(firstChar) ?? firstChar;
    const dniNumber = Number(`${dniNumberFirstChar}${firstPart.substring(1)}`);
    if (Number.isNaN(dniNumber)) return false;
    const calculatedLetter = DNI_VALIDATION_LETTER_CHARSET.charAt(dniNumber % DNI_VALIDATION_LETTER_CHARSET.length);
    return checkLetter === calculatedLetter;
}

export default KnowledgeTestModal;
