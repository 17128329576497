export enum EmailStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
}

export const TRADING_ACCOUNTS_SIGNUP = {
    'MT5|FOREX': 'components.select-trading-account.MT5.forex-cfds',
    'MT5|FUTURES': 'components.select-trading-account.MT5.futures',
    'MT4|FOREX': 'components.select-trading-account.MT4.forex-cfds',
};

export enum MigrateYourDarwinStepsStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum MigrationDarwinStatus {
    MIGRABLE = 'MIGRABLE',
    NOT_MIGRABLE = 'NOT_MIGRABLE',
    NONE = 'NONE',
    CANCELLED = 'CANCELLED',
    REQUESTED_NOT_USER = 'REQUESTED_NOT_USER',
    REQUESTED_NOT_KYC = 'REQUESTED_NOT_KYC',
    REQUESTED_NOT_WALLET = 'REQUESTED_NOT_WALLET',
    REQUESTED = 'REQUESTED',
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    VALIDATED = 'VALIDATED',
}
