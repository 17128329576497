export enum GraphTimeFrames {
    ALL = 'ALL',
    Y2 = '2Y',
    Y1 = '1Y',
    M6 = '6M',
    M3 = '3M',
    M1 = '1M',
    W1 = '1W',
    D1 = '1D',
    YTD = 'YTD',
}
