import { apiRequest, ApiRequestReturn } from '~/common/apiRequest';

export type T_Legal = {
    type: string;
    signatureDate: number;
    platform: string;
    regulatedCompany: string;
    locale: string;
    activeDocument: boolean;
};

export interface LegalDocuments {
    legalDocuments: T_Legal[];
}

export const INIT_LEGAL_DOCUMENTS = {
    legalDocuments: [],
};

export async function getAllLegalDocuments(headers: Headers): Promise<LegalDocuments> {
    type ApiResponse = {
        totalPages?: number;
        content: T_Legal[];
    };

    const response = await apiRequest<ApiResponse>(
        headers,
        `/api/user/signed-legal-documents?page=1&per_page=50&onlyActive=true`,
        {},
    );

    if ('data' in response) {
        let legalDocuments = [...response.data.content];
        const totalPages = response.data.totalPages ?? 0;
        if (totalPages > 1) {
            const legalDocumentsRequests: Promise<ApiRequestReturn<ApiResponse>>[] = [];
            for (let i = 2; i < Math.abs(totalPages); i++) {
                legalDocumentsRequests.push(
                    apiRequest(headers, `/api/user/signed-legal-documents?page=${i}&per_page=50&onlyActive=true`, {}),
                );
            }

            Promise.all(legalDocumentsRequests)
                .then((responsesLegalDocuments) =>
                    Promise.all(
                        responsesLegalDocuments.map((r) => {
                            if ('data' in r) return r.data;
                            throw new Error(`Failed request. Status code: ${r.statusCode}`, r.err as Error);
                        }),
                    ),
                )
                .then((responsesLegalDocuments) => {
                    responsesLegalDocuments.forEach((res) => {
                        const legalDocumentsResponse: T_Legal[] = [...res.content];
                        legalDocuments = [...legalDocuments, ...legalDocumentsResponse];
                    });
                })
                .catch((reason) => {
                    console.error(`Error fetching signed legal documents. Reason: ${reason}`);
                });
        }

        return {
            legalDocuments,
        };
    }
    return { ...INIT_LEGAL_DOCUMENTS };
}
