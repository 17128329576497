import moment from 'moment';

/**
 * @description Filter data from the beginning of the year to the current date. As "YTD" timestamp is not supported
 * by every backend endpoint, this filter action needs to be performed on the frontend.
 * @param data
 * GraphData (each graph data has its own type)
 * @param getTimestamp
 * Function to get the timestamp from a graph data point
 */
const yearToDateFilter = <T>(data: Array<T> | undefined, getTimestamp: (arg: T) => number): Array<T> => {
    if (data === undefined) {
        return [];
    }
    const startDateTimeStamp = moment.utc().startOf('year').valueOf();
    return data.filter((item) => getTimestamp(item) >= startDateTimeStamp);
};

export default yearToDateFilter;
