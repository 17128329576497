import { apiRequest } from '~/common/apiRequest';

import { RequirementsStatus } from '~/enums/requirements-status';
import { TypeProfile } from '~/enums/profile';
import { T_Legal } from '~/services/legal-documents';

export type T_KYSInfoAddress = {
    line1: string;
    line2?: string;
    country: string;
    zipCode: string;
    state: string;
    city: string;
};

export type T_KYSInfoParticular = {
    name: string;
    surname: string;
    middleName?: string;
    tinCode: string;
    address: T_KYSInfoAddress;
};

export type T_KYSInfoCorporative = {
    fiscalCode: string;
    businessName: string;
    address: T_KYSInfoAddress;
};

export type T_KYSInfo = T_KYSInfoParticular | T_KYSInfoCorporative | null;

export type T_KYS = {
    status: RequirementsStatus | null;
    type: TypeProfile.PARTICULAR | TypeProfile.CORPORATIVE | null;
    info: T_KYSInfo;
};

/* Interfaces endpoint /api/mifid/ */

interface KYSInfoAddress {
    line1: string;
    line2?: string;
    country: string;
    zipCode: string;
    state: string;
    city: string;
}

interface KYSInfoParticular {
    name: string;
    surname: string;
    middleName?: string;
    tinCode: string;
}

interface KYSInfoCorporative {
    fiscalCode: string;
    businessName: string;
}

interface KYSInfo {
    status: RequirementsStatus | null;
    type: TypeProfile.PARTICULAR | TypeProfile.CORPORATIVE | null;
    address: KYSInfoAddress;
    particular: KYSInfoParticular | null;
    corporative: KYSInfoCorporative | null;
}

export interface KYSPromise {
    KYS: T_KYS;
}

export const INIT_KYS = {
    KYS: {
        status: null,
        type: null,
        info: null,
    },
};

export async function getIKS(headers: Headers): Promise<KYSPromise> {
    const response = await apiRequest<KYSInfo>(headers, '/api/mifid/kys/me', {}, false);

    if (response.ok) {
        let info: T_KYSInfo = null;
        const userInfo = response.data;
        if (userInfo.status !== null) {
            if (userInfo.type === TypeProfile.PARTICULAR) {
                info = {
                    ...userInfo.particular,
                    address: userInfo.address,
                } as T_KYSInfoParticular;
            } else {
                info = {
                    ...userInfo.corporative,
                    address: userInfo.address,
                } as T_KYSInfoCorporative;
            }
        }
        const KYS: T_KYS = {
            status: userInfo.status,
            type: userInfo.type,
            info,
        };
        return {
            KYS,
        };
    }

    return { ...INIT_KYS };
}
