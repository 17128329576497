import { useI18N } from '~/contexts/NextI18NProvider';
import {
    Button,
    IconCheck,
    IconChevron,
    IconChevronRounded,
    IconError,
    IconExternalLink,
    IconMigrate,
} from '@darwinex/components-library';
import React from 'react';
import classNames from 'classnames';
import { MigrateYourDarwinStepsStatus } from '~/utils/enums';
import styles from './MigrateYourDarwin.module.scss';

type StepProps = {
    label: string;
    status: MigrateYourDarwinStepsStatus;
};

export enum CTA_MigrationTypeValue {
    START = 'START',
    CANCEL = 'CANCEL',
}

export interface MigrateYourDarwinProps {
    CTA_Migration: {
        type: CTA_MigrationTypeValue.START | CTA_MigrationTypeValue.CANCEL | null;
        onClickStart: () => void;
        onClickCancel: () => void;
    };
    hideChevron?: boolean;
    isOpenMigratedSteps: boolean;
    setIsOpenMigratedSteps: (isOpen: boolean) => void;
    steps: StepProps[] | null;
    step: {
        description: string;
        CTA_1?: {
            label: string;
            onClick: () => void;
            isExternal: boolean;
        };
        CTA_2?: {
            label: string;
            onClick: () => void;
            isExternal: boolean;
        };
    } | null;
}

const ICON_MAP: Record<MigrateYourDarwinStepsStatus, Record<string, any>> = {
    INACTIVE: {
        style: styles.steps__group__icon__empty,
        icon: (step: number | string) => step,
    },
    ACTIVE: {
        style: styles.steps__group__icon__active,
        icon: (step: number | string) => step,
    },
    WARNING: {
        style: styles.steps__group__icon__warning,
        icon: (step: number | string) => step,
    },
    SUCCESS: {
        style: styles.steps__group__icon__check,
        icon: () => <IconCheck width={24} height={24} />,
    },
    ERROR: {
        style: styles.steps__group__icon__error,
        icon: () => <IconError width={24} height={24} viewBox="0 0 24 24" />,
    },
};

export const MigrateYourDarwin = (migrateYourDarwin: MigrateYourDarwinProps) => {
    const { steps, step, CTA_Migration, hideChevron, isOpenMigratedSteps, setIsOpenMigratedSteps } = migrateYourDarwin;
    const { M } = useI18N();

    return (
        <div className={styles.migrate}>
            <div className={styles.migrate__module}>
                <div>
                    <div className={styles.migrate__module__icon}>
                        <IconMigrate width={32} height={32} />
                    </div>
                    <div className={styles.migrate__module__label}>
                        <p className="dx-font-size-xxl font-open-sans--bold">
                            {M('page.settings.components.migrate-your-darwin.title')}
                        </p>
                    </div>
                    {!hideChevron && (
                        <button
                            type="button"
                            onClick={() => setIsOpenMigratedSteps(!isOpenMigratedSteps)}
                            className={styles.migrate__module__toggler}
                        >
                            <IconChevron rotation={isOpenMigratedSteps ? 'up' : 'down'} />
                        </button>
                    )}
                </div>
                {(isOpenMigratedSteps || CTA_Migration.type === CTA_MigrationTypeValue.START) && (
                    <div className={styles.steps_container}>
                        <p className="dx-font-size-lg dx-mb-4">
                            {M('page.settings.components.migrate-your-darwin.description')}
                        </p>

                        {CTA_Migration.type === CTA_MigrationTypeValue.START ? (
                            <div className="dx-mb-7">
                                <Button
                                    size="z-md"
                                    border="rect"
                                    type="button"
                                    variant="primary"
                                    onClick={CTA_Migration.onClickStart}
                                >
                                    <span>{M('page.settings.components.migrate-your-darwin.cta-start')}</span>
                                </Button>
                            </div>
                        ) : (
                            CTA_Migration.type === CTA_MigrationTypeValue.CANCEL && (
                                <div className="dx-mb-7">
                                    <Button
                                        size="z-md"
                                        border="rect"
                                        type="button"
                                        variant="danger-alt"
                                        onClick={CTA_Migration.onClickCancel}
                                    >
                                        <span>{M('page.settings.components.migrate-your-darwin.cta-cancel')}</span>
                                    </Button>
                                </div>
                            )
                        )}

                        {steps && steps.length > 0 && (
                            <div className={styles.steps}>
                                <div className={styles.steps__header}>
                                    {steps.map((step, index) => (
                                        <>
                                            <MigrateStepGroup step={index + 1} stepConfig={step} />
                                            {index !== steps.length - 1 && <MigrateChevron />}
                                        </>
                                    ))}
                                </div>

                                {step && (
                                    <>
                                        <p className="dx-font-size-md dx-my-4">{M(step.description)}</p>

                                        {(step.CTA_1 || step.CTA_2) && (
                                            <div>
                                                {step.CTA_1 && (
                                                    <Button
                                                        size="z-md"
                                                        border="rect"
                                                        type="button"
                                                        variant="primary"
                                                        onClick={step.CTA_1.onClick}
                                                    >
                                                        <span>{M(step.CTA_1.label)}</span>{' '}
                                                        {step.CTA_1.isExternal && (
                                                            <IconExternalLink
                                                                height={16}
                                                                width={16}
                                                                className="dx-ml-3"
                                                            />
                                                        )}
                                                    </Button>
                                                )}
                                                {step.CTA_2 && (
                                                    <Button
                                                        size="z-md"
                                                        border="rect"
                                                        type="button"
                                                        variant="secondary"
                                                        className="dx-mt-md-0 dx-ml-md-4 dx-mt-sm-4 dx-ml-sm-0"
                                                        onClick={step.CTA_2.onClick}
                                                    >
                                                        <span>{M(step.CTA_2.label)}</span>{' '}
                                                        {step.CTA_2.isExternal && (
                                                            <IconExternalLink
                                                                height={16}
                                                                width={16}
                                                                className="dx-ml-3"
                                                            />
                                                        )}
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const MigrateChevron = () => (
    <div className={styles.steps__chevron}>
        <IconChevron
            width={8}
            height={8}
            className={classNames(styles.steps__chevron__icon_left, 'color-tertiary-400')}
        />
        <IconChevronRounded
            width={12}
            height={12}
            rotation="down"
            className={classNames(styles.steps__chevron__icon_down, 'color-tertiary-400')}
        />
    </div>
);

const MigrateStepGroup = ({ step, stepConfig }: { step: number; stepConfig: StepProps }) => {
    const { M } = useI18N();
    return (
        <div className={classNames(styles.steps__group, 'd-flex justify-content-between align-items-center')}>
            <div className={classNames(styles.steps__group__icon, ICON_MAP[stepConfig.status].style)}>
                {ICON_MAP[stepConfig.status].icon(step)}
            </div>
            <div className={classNames(styles.steps__group__label, 'dx-font-size-md font-open-sans--bold')}>
                {M(stepConfig.label)}
            </div>
        </div>
    );
};
