import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import useFetch from '~/hooks/useFetch';
import { T_Consul } from '~/pages/api/consul-analytics-nextjs';

declare global {
    interface Window {
        fpr: any;
        FB: any;
    }
}

const AnalyticsCommon = () => {
    const [analyticsProps, setAnalyticsProps] = useState<T_Consul | null>(null);

    useEffect(() => {
        doGetAnalyticsProps();
    }, []);

    const { data: dataAnalytics, execute: doGetAnalyticsProps } = useFetch(
        `/api/consul-analytics-nextjs?keys=first-promoter,gtm,gtm-auth,facebook`,
    );

    useEffect(() => {
        if (dataAnalytics) {
            const data = dataAnalytics as T_Consul;
            setAnalyticsProps(data);
        }
    }, [dataAnalytics]);

    useEffect(() => {
        if (analyticsProps && analyticsProps['first-promoter']) {
            (function (w) {
                w.fpr =
                    w.fpr ||
                    function () {
                        w.fpr.q = w.fpr.q || [];
                        w.fpr.q[arguments[0] == 'set' ? 'unshift' : 'push'](arguments);
                    };
            })(window);
            window.fpr('init', { cid: analyticsProps['first-promoter'] });
            window.fpr('click');
            loadFPScript();
        }
    }, [analyticsProps]);

    const loadFPScript = () => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.id = 'FirstPromoter';
        script.type = 'text/javascript';
        script.src = 'https://cdn.firstpromoter.com/fpr.js';
        if (!document.getElementById('FirstPromoter')) {
            head.appendChild(script);
        }
    };

    return (
        analyticsProps && (
            <>
                {/* Facebook */}
                {analyticsProps.facebook && (
                    <Script
                        id="Facebook"
                        src="https://connect.facebook.net/es_ESP/sdk.js"
                        async
                        defer
                        onLoad={() => {
                            if (typeof window.FB !== 'undefined') {
                                window.FB.init({
                                    appId: analyticsProps.facebook,
                                    autoLogAppEvents: true,
                                    xfbml: true,
                                    version: 'v3.0',
                                });
                            }
                        }}
                    />
                )}

                {analyticsProps.gtm && analyticsProps['gtm-auth'] && (
                    <Script
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${analyticsProps['gtm-auth']}';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${analyticsProps.gtm}');`,
                        }}
                    ></Script>
                )}

                {analyticsProps['gtm-auth'] && (
                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${analyticsProps.gtm}${analyticsProps['gtm-auth']}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                        }}
                    ></noscript>
                )}
            </>
        )
    );
};
export default AnalyticsCommon;
