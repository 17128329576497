import useFetch from '~/hooks/useFetch';

interface State<T> {
    data?: T;
    error?: Error;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const URL_BASE_SUBSCRIPTION = '/api/payment-subscription';

export type T_CustomersMe = Record<string, any>;

type T_Update_CustomersMe = {
    name?: string;
    surname?: string;
    address?: {
        address?: string;
        postalCode?: string;
        country?: string;
        state?: string;
    };
};

type T_Update_CustomersMeSubscription = {
    paySubscriptionWithEarning: boolean;
};

export const useSubscriptionService = () => {
    const useGetCustomersMe = (): UseFetchReturnType<T_CustomersMe> => {
        const { data, error, execute } = useFetch(`${URL_BASE_SUBSCRIPTION}/customers/me`, {
            method: 'GET',
        });
        return {
            data: data as T_CustomersMe,
            error,
            execute,
        };
    };

    const usePutCustomersMe = (options: T_Update_CustomersMe): UseFetchReturnType<T_CustomersMe> => {
        const { data, error, execute } = useFetch(`${URL_BASE_SUBSCRIPTION}/customers/me`, {
            method: 'PUT',
            body: JSON.stringify(options),
        });
        return {
            data: data as T_CustomersMe,
            error,
            execute,
        };
    };

    const usePutCustomersMeSubscription = (
        options: T_Update_CustomersMeSubscription,
    ): UseFetchReturnType<T_CustomersMe> => {
        const { data, error, execute } = useFetch(`${URL_BASE_SUBSCRIPTION}/customers/me/subscription-settings`, {
            method: 'PUT',
            body: JSON.stringify(options),
        });
        return {
            data: data as T_CustomersMe,
            error,
            execute,
        };
    };

    return {
        getCustomersMe: useGetCustomersMe,
        putCustomersMe: usePutCustomersMe,
        putCustomersMeSubscription: usePutCustomersMeSubscription,
    };
};
