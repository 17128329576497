import React, { useState } from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { Alert, BREAKPOINT_MAP, Button, IconChevronRounded, IconInfoEmpty, Modal } from '@darwinex/components-library';
import useWindowSize from '~/hooks/useWindowSize';
import classNames from 'classnames';
import { getAmountWithCurrencyFormat } from '~/utils/format';
import TradingAccountTypeSelector from '~/storybook/components/common/trading-account-type-selector/TradingAccountTypeSelector';
import { PriceResponseNewDto } from '~/pages/api/prices';
import { getKeyByFamilyAndPlatform } from '~/utils/trading-accounts';
import Nullable from '~/types/Nullable';
import { ServiceAvailability } from '~/enums/profile';
import styles from './RestartParticipation.module.scss';

interface Props {
    onModalClose: React.MouseEventHandler;
    onPaymentSubscription: () => void;
    pricesInfo: PriceResponseNewDto[];
    onChangeTradingAccount: (
        tradingPlatform: string,
        familyType: string,
        accountAvailability: ServiceAvailability,
        serviceId: string,
    ) => void;
    previousServiceId: Nullable<string>;
}

const RestartParticipationTradingAccount = ({
    onModalClose,
    onPaymentSubscription,
    pricesInfo,
    onChangeTradingAccount,
    previousServiceId,
}: Props) => {
    const { M, U } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;

    const [tradingAccountSelectorOpen, setTradingAccountSelectorOpen] = useState<boolean>(false);
    const [currencySelectorOpen, setCurrencySelectorOpen] = useState<boolean>(false);
    const [selectedTradingPlatform, setSelectedTradingPlatform] = useState<Nullable<string>>(null);
    const [selectedFamilyType, setSelectedFamilyType] = useState<Nullable<string>>(null);
    const [selectedCurrency, setSelectedCurrency] = useState<Nullable<string>>(null);
    const [selectedExtraPrice, setSelectedExtraPrice] = useState<Nullable<number>>(null);
    const [selectedAccountPaymentPeriodInMonths, setSelectedAccountPaymentPeriodInMonths] =
        useState<Nullable<number>>(null);

    const toggleCurrencySelectorOpen = () => setCurrencySelectorOpen((prev) => !prev);
    const toggleTradingAccountSelectorOpen = () => setTradingAccountSelectorOpen((prev) => !prev);

    const accountSelected = selectedTradingPlatform !== null && selectedFamilyType !== null;

    return (
        <Modal size="md" title={M('modals.restart-participation.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg dx-mb-5">
                <p className="dx-my-3">{M('modals.restart-participation.trading-account.text')}</p>
            </div>
            <div className="dx-mb-3 dx-mt-5">
                {M('components.select-trading-account.label')}
                <span className="dx-ml-2 color-semantic-danger-500">*</span>
            </div>

            <div className={styles.selector_trading_account__container}>
                <div
                    onClick={() => {
                        if (currencySelectorOpen) {
                            toggleCurrencySelectorOpen();
                        }
                        toggleTradingAccountSelectorOpen();
                    }}
                    className={classNames(
                        'dx-p-5 w-100-perc bg-secondary-100 br-md d-flex justify-content-between cursor-pointer',
                        styles.selector_trading_account__selected,
                    )}
                >
                    {accountSelected ? (
                        M(
                            `components.select-trading-account.${getKeyByFamilyAndPlatform(
                                selectedFamilyType,
                                selectedTradingPlatform,
                            )}.title`,
                        )
                    ) : (
                        <span className="color-secondary-500">
                            {M('components.select-trading-account.placeholder')}
                        </span>
                    )}
                    <IconChevronRounded
                        rotation={tradingAccountSelectorOpen ? 'up' : 'down'}
                        className="color-primary-500"
                        width={16}
                        height={16}
                    />
                </div>
                <TradingAccountTypeSelector
                    selectorOpen={tradingAccountSelectorOpen}
                    onClickSelectorOption={(tradingPlatform, familyType, accountAvailability) => {
                        toggleTradingAccountSelectorOpen();
                        let previousServicePaymentPeriod: Nullable<number> = null;
                        pricesInfo.forEach((serviceInfo) => {
                            serviceInfo.priceInfo.forEach((priceInfo) => {
                                if (priceInfo.serviceId === previousServiceId) {
                                    previousServicePaymentPeriod = priceInfo.price.numOfMonths;
                                }
                            });
                        });
                        const selectedAccountInfo = pricesInfo.find(
                            (priceInfo) =>
                                priceInfo.accountInfo.familyType === familyType &&
                                priceInfo.accountInfo.tradingPlatform === tradingPlatform,
                        );

                        setSelectedTradingPlatform(tradingPlatform);
                        setSelectedFamilyType(familyType);
                        if (selectedAccountInfo !== undefined) {
                            const selectedAccountPriceInfo = selectedAccountInfo.priceInfo.find(
                                (priceInfo) => priceInfo.price.numOfMonths === previousServicePaymentPeriod,
                            );
                            setSelectedExtraPrice(selectedAccountPriceInfo?.price.extra ?? null);
                            setSelectedAccountPaymentPeriodInMonths(
                                selectedAccountPriceInfo?.price.numOfMonths ?? null,
                            );
                            setSelectedCurrency(selectedAccountInfo.accountInfo.currency);
                            onChangeTradingAccount(
                                tradingPlatform,
                                familyType,
                                accountAvailability,
                                selectedAccountPriceInfo?.serviceId ?? '',
                            );
                        }
                    }}
                    maxHeight={130}
                    servicesWithPricesData={pricesInfo}
                />
            </div>

            <div className="dx-font-size-lg dx-mb-3 dx-mt-5">
                <p className="dx-my-3">
                    <a href={U('/pricing')} target="_blank" rel="noopener noreferrer">
                        {M('modals.restart-participation.trading-account.assets')}
                    </a>
                </p>
            </div>

            {accountSelected &&
                selectedAccountPaymentPeriodInMonths !== null &&
                selectedExtraPrice !== null &&
                selectedExtraPrice > 0 && (
                    <Alert
                        variant="info-light"
                        shadow={false}
                        iconClassName="d-flex align-items-center"
                        icon={<IconInfoEmpty width={24} height={24} viewBox="0 0 24 24" />}
                        className="dx-mt-5"
                    >
                        {M(
                            `components.select-trading-account.${getKeyByFamilyAndPlatform(
                                selectedFamilyType,
                                selectedTradingPlatform,
                            )}.alert-extra`,
                            {
                                amount: getAmountWithCurrencyFormat(
                                    selectedExtraPrice / selectedAccountPaymentPeriodInMonths,
                                    selectedCurrency,
                                    '0',
                                ),
                            },
                        )}
                    </Alert>
                )}

            <div className="dx-mt-9 d-flex flex-wrap dx-g-5 align-items-center justify-content-between">
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onModalClose}
                    size="z-md"
                    border="rect"
                    variant="secondary"
                    type="submit"
                >
                    {M('modals.restart-participation.close-cta')}
                </Button>
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onPaymentSubscription}
                    size="z-md"
                    border="rect"
                    variant="primary"
                    type="submit"
                >
                    {M('modals.restart-participation.trading-account.payment-cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default RestartParticipationTradingAccount;
