import React, { useState } from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { BooleanInput, Button, Modal } from '@darwinex/components-library';

interface Props {
    onModalClose: React.MouseEventHandler;
    onAcceptTerms: () => void;
}

const AcceptTermsProvider = ({ onModalClose, onAcceptTerms }: Props) => {
    const { M } = useI18N();

    const [acceptTerms, setAcceptTerms] = useState(false);

    return (
        <Modal size="md" title={M('modals.accept-terms-provider.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg">
                <p className="dx-my-3">{M('modals.accept-terms-provider.read')}</p>
                <p className="dx-my-3 dx-mb-8">{M('modals.accept-terms-provider.why-need')}</p>

                <BooleanInput
                    id="want-required-password"
                    name="want-required-password"
                    type="checkbox"
                    label={M('modals.accept-terms-provider.accept')}
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms((p) => !p)}
                    color="secondary"
                />
            </div>
            <div className="dx-mt-9 d-flex align-items-center justify-content-between">
                <Button onClick={onModalClose} size="z-md" border="rect" variant="secondary" type="submit">
                    {M('commons.go-back')}
                </Button>
                <Button
                    onClick={onAcceptTerms}
                    size="z-md"
                    border="rect"
                    variant="primary"
                    type="submit"
                    disabled={!acceptTerms}
                >
                    {M('modals.accept-terms-provider.accept-cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default AcceptTermsProvider;
