import React, { useState } from 'react';
import { Button, Modal } from '@darwinex/components-library';

import classNames from 'classnames';
import { useI18N } from '~/contexts/NextI18NProvider';
import { KnowledgeTestFlowAction } from '~/hooks/services/useKnowledgeTest';
import styles from './KnowledgeTest.module.scss';

interface Props {
    onGoToDNI: React.MouseEventHandler;
    flow: KnowledgeTestFlowAction;
}

export const KnowledgeTestMandatory = ({ onGoToDNI, flow }: Props) => {
    const { M } = useI18N();
    return (
        <Modal
            size="md"
            type="plain"
            title={M('knowledge-test.modals.pre-info.title')}
            iconClose={false}
            onModalClose={() => {}}
            headerClassName={classNames(styles.border_none, 'bg-tertiary-700', 'color-secondary-100')}
            bodyClassName={classNames(styles.border_none, 'bg-tertiary-700', 'color-secondary-100')}
        >
            <p className="color-secondary-100 dx-font-size-lg">{M('knowledge-test.modals.pre-info.body')}</p>

            <div className="bg-tertiary-800 dx-p-7 dx-mt-5 color-tertiary-400">
                {M(`knowledge-test.modals.pre-info.description-${flow}`)}
            </div>

            <div className="d-flex justify-content-end dx-mt-5">
                <Button size="z-md" border="rect" type="button" variant="primary" onClick={onGoToDNI}>
                    {M('knowledge-test.modals.pre-info.cta')}
                </Button>
            </div>
        </Modal>
    );
};
