import useFetch from '~/hooks/useFetch';
import { UpdateProfileType } from '~/services/profile';
import Nullable from '~/types/Nullable';

interface State<T> {
    data?: T;
    error?: Error;
    status?: number;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

export const useProfileService = () => {
    const useGetProfileInfo = <T>(update?: Nullable<UpdateProfileType[]>): UseFetchReturnType<T> => {
        const { data, status, error, execute } = useFetch(`/api/profile${update ? `?update=${update.join(',')}` : ''}`);
        return {
            data: data as T,
            status,
            error,
            execute,
        };
    };

    return {
        getProfileInfo: useGetProfileInfo,
    };
};
