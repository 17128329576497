import { apiRequest } from '~/common/apiRequest';
import Nullable from '~/types/Nullable';

export interface UserNotification {
    name: string;
    dateClosed: Nullable<number>;
}

export interface UserNotifications {
    notifications: UserNotification[];
}

export const INIT_USER_NOTIFICATIONS = {
    notifications: [],
};

export async function getUserNotifications(headers: Headers): Promise<UserNotifications> {
    const response = await apiRequest(headers, '/api/user/notification', {}, false);

    if (response.ok) {
        const responseNotifications = response.data as UserNotifications;
        const notifications = (responseNotifications.notifications as UserNotification[]) ?? [];
        return {
            notifications,
        };
    }

    return { ...INIT_USER_NOTIFICATIONS };
}
