import React from 'react';
import { ServiceAvailability } from '~/enums/profile';
import classNames from 'classnames';
import styles from '~/storybook/components/common/trading-account-type-selector/TradingAccountTypeSelector.module.scss';
import { getAmountWithCurrencyFormat } from '~/utils/format';
import { useI18N } from '~/contexts/NextI18NProvider';
import DropdownTooltip from '~/components/misc/dropdowntooltip';
import { PriceResponseNewDto } from '~/pages/api/prices';
import Nullable from '~/types/Nullable';
import { getKeyByFamilyAndPlatform } from '~/utils/trading-accounts';
import { Currency } from '~/enums/Currency';

interface TradingAccountTypeSelectorProps {
    selectorOpen: boolean;
    onClickSelectorOption: (
        tradingPlatform: string,
        familyType: string,
        accountAvailability: ServiceAvailability,
        currency: Currency,
    ) => void;
    maxHeight?: number;
    servicesWithPricesData: PriceResponseNewDto[];
}

const TradingAccountTypeSelector: React.FC<TradingAccountTypeSelectorProps> = ({
    selectorOpen,
    onClickSelectorOption,
    maxHeight,
    servicesWithPricesData,
}) => {
    const { M } = useI18N();

    const renderItem = (
        familyType: string,
        tradingPlatform: string,
        accountAvailability: ServiceAvailability,
        currency: Currency,
        isLast: boolean,
        extraPrice?: Nullable<number>,
    ) => {
        const accountEnabled = accountAvailability !== ServiceAvailability.UNAVAILABLE;

        return (
            <span key={`trading-account-selector-${tradingPlatform}-${familyType}`}>
                <div
                    className={accountEnabled ? 'cursor-pointer' : ''}
                    onClick={() => onClickSelectorOption(tradingPlatform, familyType, accountAvailability, currency)}
                >
                    <div
                        className={classNames(
                            !accountEnabled && styles.tradingAccountTypeSelector_label_disabled,
                            'font-open-sans--bold d-flex justify-content-between',
                        )}
                    >
                        {M(
                            `components.select-trading-account.${getKeyByFamilyAndPlatform(
                                familyType,
                                tradingPlatform,
                            )}.title`,
                        )}
                        {extraPrice && extraPrice > 0 && (
                            <span className="color-secondary-700">
                                {M('components.select-trading-account.MT5.futures.title-extra', {
                                    amount: getAmountWithCurrencyFormat(extraPrice, currency),
                                })}{' '}
                                *
                            </span>
                        )}
                    </div>
                    <div
                        className={classNames(
                            !accountEnabled && styles.tradingAccountTypeSelector_label_disabled,
                            'dx-font-size-md',
                        )}
                    >
                        {M(
                            `components.select-trading-account.${getKeyByFamilyAndPlatform(
                                familyType,
                                tradingPlatform,
                            )}.description`,
                        )}
                        {extraPrice && extraPrice > 0 && (
                            <div className="color-secondary-700">
                                * {M('components.select-trading-account.MT5.futures.description-extra')}
                            </div>
                        )}
                    </div>
                </div>
                {!isLast && <div className="bg-tertiary-300 dx-my-5" style={{ height: 2 }} />}
            </span>
        );
    };

    return (
        <div
            className={classNames(styles.tradingAccountTypeSelector, selectorOpen && styles.open)}
            style={maxHeight !== undefined ? { maxHeight: `${maxHeight}px`, overflow: 'auto' } : {}}
        >
            {servicesWithPricesData?.map((service, index) => {
                const { accountInfo, priceInfo } = service;
                const extraPrice = priceInfo.find((serviceInfo) => serviceInfo.price.extra > 0);
                const isEnabled = accountInfo.availability !== ServiceAvailability.UNAVAILABLE;
                const isLast = index === servicesWithPricesData.length - 1;
                if (isEnabled) {
                    return renderItem(
                        accountInfo.familyType,
                        accountInfo.tradingPlatform,
                        accountInfo.availability,
                        accountInfo.currency,
                        isLast,
                        extraPrice?.price.extra,
                    );
                }
                return (
                    <DropdownTooltip
                        className="dropdown d-flex align-items-center dx-column-platform__tooltip"
                        content={
                            <div className="dropdown-item bg-secondary-200 dx-p-3 dx-font-size-sm">
                                {M('components.select-trading-account.MT5.forex-cfds.not-allowed-warning')}
                            </div>
                        }
                        key={`trading-account-selector-disabled${accountInfo.tradingPlatform}-${accountInfo.familyType}`}
                        trigger="hover"
                    >
                        {renderItem(
                            accountInfo.familyType,
                            accountInfo.tradingPlatform,
                            accountInfo.availability,
                            accountInfo.currency,
                            isLast,
                            extraPrice?.price.extra,
                        )}
                    </DropdownTooltip>
                );
            })}
        </div>
    );
};

export default TradingAccountTypeSelector;
