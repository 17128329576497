import React, { useEffect, useState } from 'react';

import { useProfile } from '~/contexts/NextProfileProvider';
import { useModal } from '~/contexts/NextModalProvider';
import { T_ProfileResponse } from '~/pages/api/profile';
import { useProfileService } from '~/hooks/services/useProfileService';
import { T_StartParticipationOutput, useParticipationService } from '~/hooks/services/useParticipationService';
import { useRouter } from 'next/router';
import SBCancelParticipation from '~/storybook/pages/platform/settings/modals/cancel-participation/CancelParticipation';
import SBCancelParticipationSuccess from '~/storybook/pages/platform/settings/modals/cancel-participation/CancelParticipationSuccess';
import SBCancelParticipationWarning from '~/storybook/pages/platform/settings/modals/cancel-participation/CancelParticipationWarning';
import { getFirstActiveParticipation, getParticipation } from '~/utils/participations';
import { sendDataLayerEvent } from '~/utils/analytics';
import { useProductsService } from '~/hooks/services/useProductsService';
import { getShortName } from '~/utils/darwin-name';
import moment from 'moment';
import GenericLoadingModal from '~/components/modals/generic-loading';
import { forbiddenCancelParticipation } from '~/utils/migration';
import { T_PartialProfile, UpdateProfileType } from '~/services/profile';

const CancelParticipation = () => {
    const router = useRouter();

    const { profile, setPartialProfile } = useProfile();
    const { getProfileInfo } = useProfileService();
    const { closeModal, setModal } = useModal();

    const [participationId, setParticipationId] = useState<string>('');
    const { cancelParticipation } = useParticipationService();
    const participation = getFirstActiveParticipation(profile);

    const [participationSuccess, setParticipationSuccess] = useState<boolean>(false);

    const [cancelRequested, setCancelRequested] = useState<boolean>(false);

    const [cancelDisabled, setCancelDisabled] = useState<boolean | null>(null);

    const { getTradingAccountAndProductBasicInfo } = useProductsService();

    const showManualCancel = () => {
        if (cancelParticipationError) {
            try {
                let enableManualCancel = false;
                JSON.parse(cancelParticipationError.message).data.map((error: Record<string, any>) => {
                    enableManualCancel =
                        enableManualCancel || error.code === 'PARTICIPATION_WITH_INVESTMENT_IS_NOT_CANCELABLE';
                });
                if (enableManualCancel) {
                    setModal('cancel-participation-manual');
                } else {
                    setModal('generic-error');
                }
                setCancelRequested(false);
            } catch (errors) {
                setModal('generic-error');
                setCancelRequested(false);
            }
        }
    };

    const {
        data: productBasicInfoData,
        status: productBasicInfoRequestStatus,
        execute: executeProductBasicInfoRequest,
    } = getTradingAccountAndProductBasicInfo(
        participation?.tradingAccount?.ticker ? getShortName(participation?.tradingAccount?.ticker as string) : null,
    );

    useEffect(() => {
        if (participation?.tradingAccount?.ticker) {
            executeProductBasicInfoRequest();
        }
    }, []);

    useEffect(() => {
        if (productBasicInfoRequestStatus) {
            if (productBasicInfoRequestStatus === 200) {
                const { product } = productBasicInfoData;
                setCancelDisabled(
                    product &&
                        product.creationDate !== null &&
                        moment.utc(product.creationDate + 48 * 60 * 60 * 1000) > moment.utc(),
                );
            } else {
                setCancelDisabled(false);
            }
        }
    }, [productBasicInfoRequestStatus]);

    useEffect(() => {
        if (participationId) {
            setCancelRequested(true);
            doCancelParticipation();
        }
    }, [participationId]);

    const {
        data: cancelParticipationData,
        error: cancelParticipationError,
        execute: doCancelParticipation,
    } = cancelParticipation<T_StartParticipationOutput>({
        participationId: participationId || '',
    });

    useEffect(() => {
        if (cancelParticipationData) {
            setParticipationSuccess(true);
            doGetUserData();
            setCancelRequested(false);
        } else if (cancelParticipationError) {
            showManualCancel();
        }
    }, [cancelParticipationData, cancelParticipationError]);

    const {
        data: userData,
        error: userError,
        execute: doGetUserData,
    } = getProfileInfo<T_PartialProfile>([UpdateProfileType.PARTICIPATION_SUBSCRIPTION]);

    useEffect(() => {
        if (userData) {
            const newProfile = userData as T_ProfileResponse;
            if (newProfile) {
                const { participations, subscriptions } = newProfile;
                const participation = getParticipation(participationId, participations, subscriptions);
                sendDataLayerEvent({
                    event: 'cancel_subscription',
                    canBeReactivated: participation?.canBeReactivated,
                    withInvestment: false,
                });
            } else {
                sendDataLayerEvent({ event: 'cancel_subscription' });
            }
            setPartialProfile(userData);
        } else if (userError) {
            closeModal();
            router.push('/500');
        }
    }, [userData, userError]);

    const onModalClose = () => {
        closeModal();
        if (cancelParticipationError) {
            showManualCancel();
        }
    };

    if (cancelDisabled === null) {
        return <GenericLoadingModal />;
    }

    if (cancelDisabled) {
        return <SBCancelParticipationWarning onModalClose={() => onModalClose()} />;
    }

    if (forbiddenCancelParticipation(profile)) {
        return <SBCancelParticipationWarning onModalClose={() => onModalClose()} isMigrated />;
    }

    return !participationSuccess ? (
        <SBCancelParticipation
            onModalClose={() => onModalClose()}
            onCancelSubscription={() => {
                if (participation) {
                    setParticipationId(participation.id);
                } else {
                    closeModal();
                }
            }}
            onRestartSubscription={() => {
                setModal('restart-participation');
            }}
            cancelRequested={cancelRequested}
        />
    ) : (
        <SBCancelParticipationSuccess onModalClose={() => onModalClose()} />
    );
};

export default CancelParticipation;
