import React from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { BREAKPOINT_MAP, Button, Modal } from '@darwinex/components-library';
import useWindowSize from '~/hooks/useWindowSize';
import classNames from 'classnames';

interface Props {
    onModalClose: React.MouseEventHandler;
    onRestartSubscription: () => void;
}

const RestartParticipation = ({ onModalClose, onRestartSubscription }: Props) => {
    const { M } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;

    return (
        <Modal size="md" title={M('modals.restart-participation.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg">
                <h5 className="dx-mb-3 font-open-sans--bold">
                    {M('modals.restart-participation.what-will-happen.title')}
                </h5>
                <p className="dx-my-3">{M('modals.restart-participation.what-will-happen.text-1')}</p>
                <p className="dx-my-3">{M('modals.restart-participation.what-will-happen.text-2')}</p>
                <p className="dx-my-3">{M('modals.restart-participation.what-will-happen.text-3')}</p>
            </div>
            <div className="dx-mt-9 d-flex flex-wrap dx-g-5 align-items-center justify-content-between">
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onModalClose}
                    size="z-md"
                    border="rect"
                    variant="secondary"
                    type="submit"
                >
                    {M('modals.restart-participation.close-cta')}
                </Button>
                <Button
                    className={classNames(isMobile && 'w-100-perc')}
                    onClick={onRestartSubscription}
                    size="z-md"
                    border="rect"
                    variant="primary"
                    type="submit"
                >
                    {M('modals.restart-participation.restart-cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default RestartParticipation;
