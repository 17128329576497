import React, { useEffect, useState } from 'react';

import { useModal } from '~/contexts/NextModalProvider';
import AddPaymentMethod from '~/components/modals/add-payment-method';
import RestartParticipation from '~/storybook/pages/platform/settings/modals/restart-participation/RestartParticipation';
import RestartParticipationDetails from '~/storybook/pages/platform/settings/modals/restart-participation/RestartParticipationDetails';
import RestartParticipationSuccess from '~/storybook/pages/platform/settings/modals/restart-participation/RestartParticipationSuccess';
import RestartParticipationError from '~/storybook/pages/platform/settings/modals/restart-participation/RestartParticipationError';
import RestartParticipationTradingAccount from '~/storybook/pages/platform/settings/modals/restart-participation/RestartParticipationTradingAccount';
import { useParticipationService } from '~/hooks/services/useParticipationService';
import { getFirstParticipation } from '~/utils/participations';
import { useProfile } from '~/contexts/NextProfileProvider';
import { useRouter } from 'next/router';
import { useProfileService } from '~/hooks/services/useProfileService';
import useFetch from '~/hooks/useFetch';
import { sendDataLayerEvent } from '~/utils/analytics';
import AddPaypal from '~/components/modals/add-paypal';
import RestartParticipationWarning from '~/storybook/pages/platform/settings/modals/restart-participation/RestartParticipationWarning';
import { useProductsService } from '~/hooks/services/useProductsService';
import { getShortName } from '~/utils/darwin-name';
import moment from 'moment/moment';
import GenericLoadingModal from '~/components/modals/generic-loading';
import { checkEmptyObject } from '~/common/utils';
import { useSubscriptionService } from '~/hooks/services/useSubscriptionService';
import { forbiddenCancelParticipation } from '~/utils/migration';
import { CLOSE_BANNER_MIGRATE_ZERO } from '~/services/migration';
import { usePricesService } from '~/hooks/services/usePricesService';
import { KnowledgeTestStatus, KnowledgeTestStatusEnum, useKnowledgeTest } from '~/hooks/services/useKnowledgeTest';
import KnowledgeTestModal from '~/components/modals/knowledge-test';
import { T_CustomerInfo } from '~/services/participation-subscription';
import { T_PartialProfile, UpdateProfileType } from '~/services/profile';
import { ConsulKnowledgeTestInfo } from '~/types';
import { shouldDoKnowledgeTestByConsulAndWhitelist } from '~/utils/consul-knowledge-test';
import { Currency } from '~/enums/Currency';
import Nullable from '~/types/Nullable';
import { ServiceAvailability } from '~/enums/profile';

interface Props {
    configuration?: Record<string, any> | null;
    requestOriginCountry: string | null;
    consulKnowledgeTest?: ConsulKnowledgeTestInfo;
}

const RestartParticipationModal = ({ configuration, requestOriginCountry, consulKnowledgeTest }: Props) => {
    const router = useRouter();
    const { closeModal } = useModal();
    const { profile, setPartialProfile } = useProfile();
    const { getProfileInfo } = useProfileService();
    const { getCustomersMe } = useSubscriptionService();

    const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
    const [participationId, setParticipationId] = useState<string>('');
    const [stepModal, setStepModal] = useState<string>('INIT');
    const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
    const [paymentMethodDefault, setPaymentMethodDefault] = useState<Record<string, any> | null>(null);
    const [errorRestart, setErrorRestart] = useState<string | null>(null);
    const [restartRequested, setRestartRequested] = useState<boolean>(false);
    const [restartDisabled, setRestartDisabled] = useState<boolean | null>(null);
    const [payWithPFees, setPayWithPFees] = useState<boolean>(false);

    const [customerInfo, setCustomerInfo] = useState<T_CustomerInfo>(null);

    const [selectedAccountTradingPlatform, setSelectedAccountTradingPlatform] = useState<Nullable<string>>(null);
    const [selectedAccountFamilyType, setSelectedAccountFamilyType] = useState<Nullable<string>>(null);
    const [selectedAccountAvailability, setSelectedAccountAvailability] = useState<Nullable<ServiceAvailability>>(null);
    const [knowledgeTestStatus, setKnowledgeTestStatus] = useState<KnowledgeTestStatus | undefined>();
    const [selectedServiceId, setSelectedServiceId] = useState<Nullable<string>>(null);

    const { getTradingAccountAndProductBasicInfo } = useProductsService();
    const { getKnowledgeTestStatus } = useKnowledgeTest();
    const { restartParticipation } = useParticipationService();
    const { getPricesInfo } = usePricesService();

    const participation = getFirstParticipation(profile);

    const userCurrency =
        customerInfo?.credits && customerInfo?.credits?.currencyCode !== null
            ? customerInfo?.credits?.currencyCode
            : null;

    const {
        data: productBasicInfoData,
        status: productBasicInfoRequestStatus,
        execute: executeProductBasicInfoRequest,
    } = getTradingAccountAndProductBasicInfo(
        participation?.tradingAccount?.ticker ? getShortName(participation?.tradingAccount?.ticker as string) : null,
    );

    const {
        data: paymentDefaultData,
        error: paymentDefaultError,
        execute: doGetPaymentDefault,
    } = useFetch('/api/payment-subscription/payment-methods/default');

    const { data: pricesData, execute: executePricesRequest } = getPricesInfo(
        profile?.country ?? requestOriginCountry ?? '',
        userCurrency ?? undefined,
    );

    const { data: subscriptionDetailsData, execute: executeSubscriptionDetailsRequest } = useFetch<Record<
        string,
        any
    > | null>(`/api/payment-subscription/subscriptions/${subscriptionId}`);

    const { data: dataCustomerInfo, execute: doCustomerInfo } = getCustomersMe();

    const { data: updatePaymentMethod, execute: doUpdatePaymentMethod } = useFetch<Record<string, any> | null>(
        `/api/payment-subscription/subscriptions/${subscriptionId}`,
        {
            method: 'PATCH',
            body: JSON.stringify({
                paymentMethodId,
            }),
        },
    );

    const {
        data: restartParticipationData,
        error: restartParticipationError,
        execute: doRestartParticipation,
    } = restartParticipation({
        participationId: participationId || '',
        tradingPlatform: selectedAccountTradingPlatform ?? '',
        familyType: selectedAccountFamilyType ?? '',
        currency: userCurrency ?? Currency.EUR,
        payWithYourEarnings: payWithPFees,
        country: profile?.country ?? requestOriginCountry ?? null,
        serviceId: selectedServiceId ?? '',
    });

    useEffect(() => {
        if (participation?.tradingAccount?.ticker) {
            executeProductBasicInfoRequest();
        } else {
            setRestartDisabled(false);
        }
    }, []);

    useEffect(() => {
        if (subscriptionId) {
            executeSubscriptionDetailsRequest();
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (productBasicInfoRequestStatus) {
            if (productBasicInfoRequestStatus === 200) {
                const { product } = productBasicInfoData;
                setRestartDisabled(
                    product &&
                        product.creationDate !== null &&
                        moment.utc(product.creationDate + 48 * 60 * 60 * 1000) > moment.utc(),
                );
            } else {
                setRestartDisabled(false);
            }
        }
    }, [productBasicInfoRequestStatus]);

    useEffect(() => {
        if (profile) {
            const firstParticipation = getFirstParticipation(profile);
            const subscription = firstParticipation ? firstParticipation.subscription : null;
            if (subscription && subscription.id !== subscriptionId) {
                setSubscriptionId(subscription.id);
            }
        }
    }, [JSON.stringify(profile)]);

    useEffect(() => {
        doCustomerInfo();
    }, []);

    useEffect(() => {
        if (customerInfo) {
            executePricesRequest();
        }
    }, [customerInfo]);

    useEffect(() => {
        if (paymentMethodId) {
            doGetPaymentDefault();
            if (subscriptionId) {
                doUpdatePaymentMethod();
            }
            setPayWithPFees(false);
        }
    }, [paymentMethodId]);

    useEffect(() => {
        if (paymentDefaultData) {
            const data = paymentDefaultData as Record<string, any>;
            setPaymentMethodDefault(data);
            if (checkEmptyObject(data)) {
                setStepModal('NEW_CREDIT_CARD');
            } else {
                setStepModal('CREDIT_CARD_DETAILS');
                setPayWithPFees(false);
            }
        } else if (paymentDefaultError) {
            fetch('/api/log', {
                method: 'POST',
                body: JSON.stringify({
                    error: paymentDefaultError.message ?? '',
                    url: document.location.pathname,
                    paymentDefaultError,
                    navigator: navigator?.userAgent ?? '',
                }),
            });
        }
    }, [paymentDefaultData, paymentDefaultError]);

    useEffect(() => {
        if (dataCustomerInfo) {
            const newCustomerInfo = dataCustomerInfo as T_CustomerInfo;
            setCustomerInfo(newCustomerInfo ?? null);
        }
    }, [dataCustomerInfo]);

    useEffect(() => {
        if (updatePaymentMethod && configuration && typeof configuration.onChangeCreditCard !== 'undefined') {
            configuration.onChangeCreditCard();
        }
    }, [updatePaymentMethod]);

    useEffect(() => {
        if (participationId) {
            setRestartRequested(true);
            doRestartParticipation();
        }
    }, [participationId]);

    const deleteBannerMigrateDarwinNotification = async () => {
        await fetch(`/api/user/notification/name/${CLOSE_BANNER_MIGRATE_ZERO}`, {
            method: 'DELETE',
        });
    };

    useEffect(() => {
        if (restartParticipationData) {
            sendDataLayerEvent({
                event: 'reset',
                platform: selectedAccountTradingPlatform ?? '',
                future: 'NO',
                amount: 0 /* selectedPriceInfo
                    ? selectedPriceInfo.discountedPrice?.restartPrice ?? selectedPriceInfo?.basePrice.restartPrice
                    : 0 */,
            });
            deleteBannerMigrateDarwinNotification()
                .then(() => {
                    setStepModal('RESTART_SUCCESS');
                })
                .catch(() => {
                    setStepModal('RESTART_SUCCESS');
                });
        } else if (restartParticipationError) {
            setRestartRequested(false);
            setParticipationId('');
            const errorCardDeclined = false;
            let errorPayment = false;
            JSON.parse(restartParticipationError.message).data.map((error: Record<string, any>) => {
                errorPayment =
                    errorPayment ||
                    error.code === 'CARD_DECLINED' ||
                    error.code === 'INSUFFICIENT_FUNDS' ||
                    error.code === 'INCORRECT_NUMBER' ||
                    error.code === 'INVALID_CVC' ||
                    error.code === 'CARD_DECLINE_RATE_LIMIT_EXCEEDED' ||
                    error.code === 'AUTHENTICATION_REQUIRED' ||
                    error.code === 'INCORRECT_CVC';
            });
            setStepModal('RESTART_ERROR');
            setErrorRestart(
                errorCardDeclined
                    ? 'errors.commons.card_declined'
                    : errorPayment
                    ? 'errors.commons.PAYMENT_ATTEMPT_REFUSED'
                    : null,
            );
        }
    }, [restartParticipationData, restartParticipationError]);

    const onCloseSuccessRestart = () => {
        doGetUserData();
        setRestartRequested(false);
        setParticipationId('');
    };

    const {
        data: userData,
        error: userError,
        execute: doGetUserData,
    } = getProfileInfo<T_PartialProfile>([UpdateProfileType.PARTICIPATION_SUBSCRIPTION]);

    useEffect(() => {
        if (userData) {
            setPartialProfile(userData);
            closeModal();
        } else if (userError) {
            closeModal();
            router.push('/500');
        }
    }, [userData, userError]);

    const onRestartSubscription = () => {
        if (participation) {
            setParticipationId(participation.id);
        } else {
            closeModal();
        }
    };

    const mustFullFillKnowledgeTest = (): boolean => {
        if (!consulKnowledgeTest) throw Error('Missing consul knowledge test info');
        if (!profile || !shouldDoKnowledgeTestByConsulAndWhitelist(consulKnowledgeTest, profile)) return false;
        return selectedAccountAvailability === ServiceAvailability.RESTRICTED;
    };

    if (restartDisabled === null) {
        return <GenericLoadingModal />;
    }

    if (restartDisabled) {
        return <RestartParticipationWarning onModalClose={closeModal} />;
    }

    if (forbiddenCancelParticipation(profile)) {
        return <RestartParticipationWarning onModalClose={closeModal} isMigrated />;
    }

    if (stepModal === 'INIT') {
        return (
            <RestartParticipation
                onModalClose={closeModal}
                onRestartSubscription={() => {
                    setStepModal('TRADING_ACCOUNT');
                }}
            />
        );
    }
    if (stepModal === 'TRADING_ACCOUNT') {
        return (
            <RestartParticipationTradingAccount
                onModalClose={closeModal}
                onPaymentSubscription={async () => {
                    if (selectedAccountTradingPlatform !== null && selectedAccountFamilyType !== null) {
                        if (mustFullFillKnowledgeTest()) {
                            const testStatus = await getKnowledgeTestStatus();
                            setKnowledgeTestStatus(testStatus);
                            if (typeof testStatus === 'undefined' || !testStatus.status) {
                                setStepModal(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE);
                            } else {
                                switch (testStatus.status) {
                                    case 'NOT_VALID':
                                        if (testStatus?.nextAllowedDate) {
                                            const nowDate = moment.utc();
                                            const nextAllowedDate = moment.utc(testStatus.nextAllowedDate);
                                            if (nextAllowedDate.isBefore(nowDate)) {
                                                setStepModal(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_PRE);
                                            } else {
                                                setStepModal(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_ERROR);
                                            }
                                        } else {
                                            setStepModal(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_ERROR);
                                        }
                                        break;
                                    case 'VALID':
                                        setStepModal(KnowledgeTestStatusEnum.KNOWLEDGE_TEST_SUCCESS);
                                        break;
                                    default:
                                        // console.error('Unhandled knowledge test status...', testStatus.status);
                                        break;
                                }
                            }
                        } else {
                            doGetPaymentDefault();
                        }
                    }
                }}
                onChangeTradingAccount={(tradingPlatform, familyType, accountAvailability, serviceId) => {
                    setSelectedAccountTradingPlatform(tradingPlatform);
                    setSelectedAccountFamilyType(familyType);
                    setSelectedAccountAvailability(accountAvailability);
                    setSelectedServiceId(serviceId);
                }}
                pricesInfo={pricesData}
                previousServiceId={subscriptionDetailsData?.serviceId ?? null}
            />
        );
    }
    if (stepModal === 'CREDIT_CARD_DETAILS') {
        return (
            <RestartParticipationDetails
                onModalClose={closeModal}
                onEditCreditCard={() => {
                    if (paymentMethodDefault && paymentMethodDefault.creditCard) {
                        setStepModal('NEW_CREDIT_CARD');
                    } else {
                        setStepModal('ADD_PAYPAL');
                    }
                }}
                onRestartSubscription={onRestartSubscription}
                userCurrency={userCurrency}
                restartRequested={restartRequested}
                onClickPayWithPFees={() => setPayWithPFees((prev) => !prev)}
                payWithPFees={payWithPFees}
                subscriptionId={subscriptionId}
                serviceId={selectedServiceId}
                paymentPeriodNumberOfMonths={subscriptionDetailsData?.subscriptionPrice?.periodNumberOfMonths ?? null}
            />
        );
    }
    if (stepModal === 'NEW_CREDIT_CARD') {
        return (
            <AddPaymentMethod
                type="NEW"
                onModalClose={() => {
                    doGetPaymentDefault();
                }}
                setPaymentMethodId={(pmId: string | null) => {
                    setPaymentMethodId(pmId);
                }}
                setPayment={(e) => {
                    e.preventDefault();
                    setStepModal('ADD_PAYPAL');
                }}
            />
        );
    }
    if (stepModal === 'ADD_PAYPAL') {
        return (
            <AddPaypal
                onModalClose={() => {
                    doGetPaymentDefault();
                }}
                setPaymentMethodIdPaypal={(pmId: string | null) => {
                    setPaymentMethodId(pmId);
                }}
                setPayment={(e) => {
                    e.preventDefault();
                    setStepModal('NEW_CREDIT_CARD');
                }}
            />
        );
    }
    if (stepModal === 'RESTART_SUCCESS') {
        return <RestartParticipationSuccess onModalClose={onCloseSuccessRestart} />;
    }
    if (stepModal === 'RESTART_ERROR') {
        return (
            <RestartParticipationError
                onModalClose={() => {
                    setStepModal('INIT');
                }}
                errorRestart={errorRestart}
            />
        );
    }
    if (
        profile &&
        consulKnowledgeTest &&
        shouldDoKnowledgeTestByConsulAndWhitelist(consulKnowledgeTest, profile) &&
        ['KNOWLEDGE_TEST_PRE', 'KNOWLEDGE_TEST_ERROR', 'KNOWLEDGE_TEST_SUCCESS'].includes(stepModal)
    ) {
        return (
            <KnowledgeTestModal
                initialKnowledgeTestModalStatus={stepModal as KnowledgeTestStatusEnum}
                onModalClose={() => setStepModal('TRADING_ACCOUNT')}
                onContinuePay={() => {
                    setStepModal('TRADING_ACCOUNT');
                    doGetPaymentDefault();
                }}
                retryDateInMs={knowledgeTestStatus?.nextAllowedDate}
                requestOriginCountry={requestOriginCountry}
                onChangeInfo={(tradingPlatform, familyType, familyAvailability, currency, serviceId) => {
                    setSelectedAccountTradingPlatform(tradingPlatform);
                    setSelectedAccountFamilyType(familyType);
                    setSelectedAccountAvailability(familyAvailability);
                    setSelectedServiceId(serviceId);
                }}
                continueToPay
                flow="RESTART"
                canExitTestWithoutFinishing
                previouslySelectedServiceId={subscriptionDetailsData?.serviceId ?? null}
                hasValidCoupon={null}
            />
        );
    }

    throw Error(`Unknown modal status -> ${JSON.stringify({ stepModal })}`);
};

export default RestartParticipationModal;
