/* eslint-disable consistent-return */
import merge from 'lodash/merge';
import { useEffect, useReducer, useRef } from 'react';
import { useRouter } from 'next/router';

import cache from 'memory-cache';
import { sendDataLayerEvent } from '~/utils/analytics';

interface State<T> {
    data?: T;
    error?: Error;
    status?: number;
}

type RequestUrl =
    | string
    | {
          url: string;
          cacheExpiryTime: number;
      };

// discriminated union type
type Action<T> =
    | { type: 'loading' }
    | { type: 'fetched'; payload: { data: T; status: number } }
    | { type: 'error'; payload: Error };

export type UseFetchReturnType<T> = State<T> & { execute: () => void };

function useFetch<T = unknown>(requestUrl: RequestUrl, options?: RequestInit): UseFetchReturnType<T> {
    const router = useRouter();

    const url = typeof requestUrl === 'string' ? requestUrl : requestUrl.url;
    const cacheExpiryTime = typeof requestUrl === 'string' ? null : requestUrl.cacheExpiryTime;

    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef<boolean>(false);

    const initialState: State<T> = {
        error: undefined,
        data: undefined,
        status: undefined,
    };

    // Keep state logic separated
    const fetchReducer = (prevState: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
            case 'loading':
                return { ...initialState };
            case 'fetched':
                return { ...initialState, data: action.payload.data, status: action.payload.status };
            case 'error':
                return { ...initialState, error: action.payload };
            default:
                return prevState;
        }
    };

    const [state, dispatch] = useReducer(fetchReducer, initialState);

    const execute = async () => {
        dispatch({ type: 'loading' });

        // If a cache exists for this url, return it
        const cachedResponse = cache.get(url);
        if (cacheExpiryTime && cachedResponse) {
            dispatch({ type: 'fetched', payload: cachedResponse });
            console.debug('[CACHE HIT]', { url, cacheHit: cachedResponse });
        }

        try {
            const response = await fetch(url, options);
            if (response.status === 401) {
                console.error('Error 401 on useFetch to:', url);
                sendDataLayerEvent({
                    event: 'JS_ERROR',
                    error: `Error 401 on useFetch to: ${url}`,
                    errorInfoExt: `Error 401 on useFetch to: ${url}`,
                });
                document.location.replace('/logout');
            } else {
                const content = await response.text();
                const body = content.length > 0 ? JSON.parse(content) : {};

                if (!response.ok) {
                    const errorResponse = {
                        data: body || response.statusText,
                    };
                    throw new Error(JSON.stringify(errorResponse));
                }

                const data = body as T;
                if (cacheExpiryTime) {
                    cache.put(url, data, cacheExpiryTime);
                }

                if (cancelRequest.current) return;

                dispatch({
                    type: 'fetched',
                    payload: {
                        data,
                        status: response.status,
                    },
                });
            }
        } catch (error) {
            if (cancelRequest.current) return;

            dispatch({ type: 'error', payload: error as Error });
        }
    };

    useEffect(() => {
        cancelRequest.current = false;
        // Use the cleanup function for avoiding a possibly state update after the component was unmounted
        return () => {
            cancelRequest.current = true;
        };
    });

    return { ...state, execute };
}

export default useFetch;
