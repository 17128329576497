declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

export const sendDataLayerEvent = (event: Record<string, any>) => {
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(event);
    }
};
