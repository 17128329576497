import React, { useEffect, useState } from 'react';

export const useInactiveDetector = (interval = 30000) => {
    const [isMouseInactive, setIsMouseInactive] = useState<boolean>(false);

    useEffect(() => {
        let inactiveInterval: number | undefined;

        const resetInactiveInterval = () => {
            clearTimeout(inactiveInterval);
            inactiveInterval = setTimeout(() => {
                setIsMouseInactive(true);
            }, interval) as unknown as number;
        };

        const handleActivity = () => {
            setIsMouseInactive(false);
            resetInactiveInterval();
        };

        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('mousedown', handleActivity);
        document.addEventListener('keypress', handleActivity);
        document.addEventListener('touchmove', handleActivity);

        resetInactiveInterval();

        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('mousedown', handleActivity);
            document.removeEventListener('keypress', handleActivity);
            document.removeEventListener('touchmove', handleActivity);
            clearTimeout(inactiveInterval);
        };
    }, [interval]);

    return isMouseInactive;
};

export const usePageVisibilityDetector = () => {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
        const listener = () => {
            if (document.hidden) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };

        document.addEventListener('visibilitychange', listener);

        return () => {
            document.removeEventListener('visibilitychange', listener);
        };
    }, []);

    return isVisible;
};

export const isVisibleRef = (elementRef: React.RefObject<HTMLDivElement> | null) => {
    let isVisible = false;
    if (elementRef && elementRef.current) {
        const element = elementRef.current as HTMLDivElement;
        const rect = element.getBoundingClientRect();
        isVisible =
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }
    return isVisible;
};
