import useFetch from '~/hooks/useFetch';
import { checkProductNameFormat } from '~/utils/darwin-name';
import { CorrelationList } from '~/storybook/pages/platform/darwin-page/correlation/components/CorrelationChartTable';
import { TradingAccountAndProductBasicResponse } from '~/pages/api/products/[productShortName]/basic-info';
import { ProductHistoryDto } from '~/types/dto/products/ProductHistoryDto';
import { ProductCapacityDto } from '~/types/dto/products/ProductCapacityDto';
import Nullable from '../../types/Nullable';

interface State<T> {
    data: T;
    error?: Error;
    status?: number;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

interface ProductCardBasicInfo {
    productName: Nullable<string>;
    username: Nullable<string>;
    firstQuoteDate: Nullable<number>;
    productReturn: Nullable<number>;
    invested: Nullable<number>;
    investors: Nullable<number>;
    currency: Nullable<string>;
}

interface ProductInvestable {
    productName: Nullable<string>;
    quote: Nullable<number>;
    quoteDate: Nullable<number>;
    buyAllowed: Nullable<boolean>;
    sellAllowed: Nullable<boolean>;
    conditionalBuyAllowed: Nullable<boolean>;
    conditionalSellAllowed: Nullable<boolean>;
    returnPercentage: Nullable<number>;
    estimatedOpenDate: Nullable<boolean>;
}

const PRODUCTS_MS_URL = '/api/products';

export const useProductsService = () => {
    const useGetCorrelationList = (
        productName: Nullable<string>,
        page?: number,
        perPage?: number,
    ): UseFetchReturnType<CorrelationList> => {
        const { data, status, error, execute } = useFetch<CorrelationList>(
            `${PRODUCTS_MS_URL}/correlation/${productName}/bc/list?page=${page}&per_page=${perPage}`,
        );
        return {
            data: data || {
                content: [],
                first: false,
                last: false,
                number: 0,
                numberOfElements: 0,
                size: 0,
                totalElements: 0,
                totalPages: 0,
                sort: {
                    empty: false,
                    sorted: false,
                    unsorted: true,
                },
            },
            status,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetTradingAccountAndProductBasicInfo = (
        productName: Nullable<string>,
    ): UseFetchReturnType<TradingAccountAndProductBasicResponse> => {
        const { data, status, error, execute } = useFetch<TradingAccountAndProductBasicResponse>(
            `${PRODUCTS_MS_URL}/${productName}/basic-info`,
        );
        return {
            data: data || {
                tradingAccount: {
                    accountName: null,
                    ticker: null,
                    accountType: null,
                    connectionType: null,
                    creationDate: null,
                    status: null,
                    avatar: null,
                    registerDate: null,
                    username: null,
                    tradingJournalVisible: null,
                    assetsVisible: null,
                },
                product: {
                    type: null,
                    productName: null,
                    status: null,
                    validationDate: null,
                    creationDate: null,
                    investable: null,
                },
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useSearchProductName = (searchInput: Nullable<string>): UseFetchReturnType<Array<string>> => {
        const { data, status, error, execute } = useFetch<Array<string>>(
            `${PRODUCTS_MS_URL}/bootcamp/search?productName=${searchInput}`,
        );
        return {
            data: data || [],
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetProductBasicInfo = (productName: string): UseFetchReturnType<ProductCardBasicInfo> => {
        const { data, status, error, execute } = useFetch<ProductCardBasicInfo>(
            `${PRODUCTS_MS_URL}/detail?productName=${productName}`,
        );
        return {
            data: data || {
                productName: null,
                username: null,
                productReturn: null,
                firstQuoteDate: null,
                currency: null,
                invested: null,
                investors: null,
            },
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetProductsHistory = (
        productShortname: string,
        productStatus?: string,
    ): UseFetchReturnType<Array<ProductHistoryDto>> => {
        let URL = `${PRODUCTS_MS_URL}/productname/name/history?shortname=${productShortname.toUpperCase()}`;
        if (productStatus) {
            URL += `&status=${productStatus}`;
        }
        const { data, status, error, execute } = useFetch<Array<ProductHistoryDto>>(URL);
        return {
            data: data || [],
            status,
            error,
            execute: () => {
                if (productShortname) {
                    execute();
                }
            },
        };
    };

    return {
        getCorrelationList: useGetCorrelationList,
        getTradingAccountAndProductBasicInfo: useGetTradingAccountAndProductBasicInfo,
        getProductsNameBySearchInput: useSearchProductName,
        getProductBasicInfo: useGetProductBasicInfo,
        getProductHistory: useGetProductsHistory,
    };
};
