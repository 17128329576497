import React, { useEffect, useState } from 'react';

import SBAddPaymentMethod, {
    AddPaymentMethodTypes,
} from '~/storybook/pages/platform/settings/modals/add-payment-method/AddPaymentMethod';
import { T_ChargeBeeProps } from '~/pages/api/chargebee';
import useFetch from '~/hooks/useFetch';
import { useI18N } from '~/contexts/NextI18NProvider';
import { T_FormInfo, useValidation } from '~/hooks/useValidation';
import { PaymentType } from '~/pages/checkout';
import { useProfile } from '~/contexts/NextProfileProvider';
import isClient from '~/utils/is-client';
import { useSubscriptionService } from '~/hooks/services/useSubscriptionService';

interface Props {
    onModalClose: React.MouseEventHandler;
    setPaymentMethodId: (paymentMethodId: string | null) => void;
    type: AddPaymentMethodTypes;
    setPayment: (e: any) => void;
}

declare global {
    interface Window {
        Chargebee: any;
    }
}

const AddPaymentMethod = ({ onModalClose, setPaymentMethodId, type, setPayment }: Props) => {
    const { locale, M } = useI18N();
    const { profile } = useProfile();

    const [chargeBee, setChargeBee] = useState<T_ChargeBeeProps | null>(null);
    const [cbInstance, setCbInstance] = useState<any>(null);
    const [cardComponent, setCardComponent] = useState<any>(null);
    const [token, setToken] = useState<string | null>(null);
    const [paymentIntent, setPaymentIntent] = useState<Record<string, any> | null>(null);

    const [alert, setAlert] = useState<boolean>(false);

    const [addCreditCardRequested, setAddCreditCardRequested] = useState<boolean>(false);

    const { data: dataChargeBee, execute: doChargeBee } = useFetch(`/api/payment-subscription/charge-bee/info`);

    const { getCustomersMe } = useSubscriptionService();
    const { data: dataUserInitInfo, execute: doUserInitInfo } = getCustomersMe();

    useEffect(() => {
        doUserInitInfo();
        if (cbInstance === null) {
            doChargeBee();
        } else {
            setTimeout(() => {
                loadInstance();
            }, 100);
        }
    }, []);

    useEffect(() => {
        if (dataChargeBee) {
            const data = dataChargeBee as T_ChargeBeeProps;
            setChargeBee({
                siteName: data.siteName,
                apiKeyPublic: data.apiKeyPublic,
                paymentMethods: data.paymentMethods,
            });
        }
    }, [dataChargeBee]);

    useEffect(() => {
        if (isClient() && chargeBee) {
            loadChargebeeScript();
        }
    }, [chargeBee]);

    const loadChargebeeScript = (): void => {
        const chargebeeJs = document.getElementById('js-chargebee');
        if (!chargebeeJs) {
            const url = 'https://js.chargebee.com/v2/chargebee.js';
            const script = document.createElement('script');
            script.id = 'js-chargebee';
            script.type = 'text/javascript';
            script.src = url;
            script.async = true;
            script.defer = true;
            // script.dataset.cbGtmEnabled = 'true';
            script.onload = () => {
                if (typeof window.Chargebee !== 'undefined') {
                    setTimeout(() => {
                        setCbInstance(
                            window.Chargebee.init({
                                site: chargeBee?.siteName,
                                publishableKey: chargeBee?.apiKeyPublic,
                                enableGTMTracking: true,
                            }),
                        );
                    }, 100);
                } else {
                    // document.location.reload();
                    document.location.replace('/500');
                }
            };
            document.body.appendChild(script);
        } else if (typeof window.Chargebee !== 'undefined') {
            setTimeout(() => {
                setCbInstance(
                    window.Chargebee.init({
                        site: chargeBee?.siteName,
                        publishableKey: chargeBee?.apiKeyPublic,
                        enableGTMTracking: true,
                    }),
                );
            }, 100);
        } else {
            // document.location.reload();
            document.location.replace('/500');
        }
    };

    useEffect(() => {
        try {
            if (isClient() && cbInstance && cardComponent === null) {
                loadInstance();
            }
        } catch (errorChargebee) {
            document.location.replace('/500');
        }
    }, [cbInstance]);

    const loadInstance = () => {
        // Create card
        cbInstance
            .load('components')
            .then(() => {
                // Create card
                const options = {
                    locale,
                    style: {
                        base: {
                            color: '#000000',
                            ':focus': {
                                color: '#000000',
                            },
                            '::placeholder': {
                                color: '#000000',
                            },
                            ':focus::placeholder': {
                                color: '#000000',
                            },
                        },
                        // Styles for invalid state
                        invalid: {
                            color: '#E94745',
                            ':focus': {
                                color: '#e44d5f',
                            },
                            '::placeholder': {
                                color: '#FFCCA5',
                            },
                        },
                    },
                };
                const cardChargebee = cbInstance.createComponent('card', options);

                // Create card fields
                cardChargebee.createField('number').at('#cardNumber');
                cardChargebee.createField('expiry').at('#cardExpiry');
                cardChargebee.createField('cvv').at('#cardCvc');

                // Mount card component
                cardChargebee.mount();

                setCardComponent(cardChargebee);
            })
            .catch(() => {
                document.location.replace('/500');
            });
    };

    const {
        data: paymentIntentData,
        error: paymentIntentError,
        execute: doGetPaymentIntent,
    } = useFetch<Record<string, any> | null>(`/api/payment-subscription/charge-bee/payment-intents`, {
        method: 'POST',
        body: JSON.stringify({
            amount: 0,
            currencyCode: 'EUR',
            paymentMethod: 'CREDIT_CARD',
        }),
    });

    useEffect(() => {
        if (paymentIntentData) {
            setPaymentIntent(paymentIntentData);
            setAlert(false);
        } else if (paymentIntentError) {
            setPaymentIntent(null);
            setAlert(true);
        }
    }, [paymentIntentData, paymentIntentError]);

    useEffect(() => {
        if (paymentIntent) {
            setAlert(false);
            validateCard();
        }
    }, [paymentIntent]);

    const formInfo: T_FormInfo = {
        onFormSuccess: async () => {
            doGetPaymentIntent();
        },
        onFormError: async () => {
            setAddCreditCardRequested(false);
        },
        liveValidation: false,
        fields: [
            {
                name: 'firstName',
                value: '',
                validators: [
                    {
                        type: 'required',
                        customTypeData: null,
                        value: '',
                        errorMessage: null,
                    },
                    {
                        type: 'maxlength',
                        customTypeData: {
                            minLength: 2,
                            maxLength: 100,
                        },
                        value: '',
                        errorMessage: M('errors.commons.maxlength', { min: 2, max: 100 }),
                    },
                    {
                        type: 'hasHTML',
                        customTypeData: null,
                        value: '',
                        errorMessage: M('errors.commons.hasHTML'),
                    },
                ],
            },
            {
                name: 'lastName',
                value: '',
                validators: [
                    {
                        type: 'required',
                        customTypeData: null,
                        value: '',
                        errorMessage: null,
                    },
                    {
                        type: 'maxlength',
                        customTypeData: {
                            minLength: 2,
                            maxLength: 100,
                        },
                        value: '',
                        errorMessage: M('errors.commons.maxlength', { min: 2, max: 100 }),
                    },
                    {
                        type: 'hasHTML',
                        customTypeData: null,
                        value: '',
                        errorMessage: M('errors.commons.hasHTML'),
                    },
                ],
            },
        ],
    };

    const { fieldsData, setFieldValue, validateFields, isValidateForm } = useValidation(formInfo);
    const { firstName, lastName } = fieldsData;

    useEffect(() => {
        if (addCreditCardRequested) {
            validateFields();
        }
    }, [addCreditCardRequested]);

    const validateCard = () => {
        if (cardComponent && dataUserInitInfo) {
            const firstNameVal = typeof firstName === 'undefined' ? '' : firstName.value;
            const lastNameVal = typeof lastName === 'undefined' ? '' : lastName.value;

            const userInfo = dataUserInitInfo as Record<string, any>;
            const addressLineVal = userInfo.address.address;
            const zipCodeVal = userInfo.address.postalCode;
            const countryCodeVal = userInfo.address.country;

            const email = profile?.email;

            const addressInfo = {
                firstName: firstNameVal,
                lastName: lastNameVal,
                zip: zipCodeVal,
                countryCode: countryCodeVal,
                addressLine1: addressLineVal,
            };

            cardComponent
                .tokenize({
                    firstName: firstNameVal,
                    zip: zipCodeVal,
                    countryCode: countryCodeVal,
                })
                .then(() => {
                    cardComponent
                        .authorizeWith3ds(paymentIntent, {
                            billingAddress: addressInfo,
                            customerBillingAddress: addressInfo,
                            shippingAddress: addressInfo,
                            email,
                        })
                        .then((paymentIntent: Record<string, string>) => {
                            setToken(paymentIntent.id);
                        })
                        .catch((error: Error) => {
                            setToken(null);
                            setPaymentIntent(null);
                            setAlert(true);
                            setAddCreditCardRequested(false);

                            // Send error to logs
                            fetch('/api/log', {
                                method: 'POST',
                                body: JSON.stringify({
                                    error: `#authorizeWith3dsAddPayment#${error.message ?? ''}`,
                                    url: document.location.pathname,
                                    username: profile?.username ?? '',
                                    navigator: navigator?.userAgent ?? '',
                                }),
                            });
                        });
                })
                .catch((error: Error) => {
                    setToken(null);
                    setPaymentIntent(null);
                    setAlert(true);
                    setAddCreditCardRequested(false);

                    // Send error to logs
                    fetch('/api/log', {
                        method: 'POST',
                        body: JSON.stringify({
                            error: `#tokenizeCheckout#${error.message ?? ''}`,
                            url: document.location.pathname,
                            username: profile?.username ?? '',
                            navigator: navigator?.userAgent ?? '',
                        }),
                    });
                });
        }
    };

    useEffect(() => {
        if (token) {
            doAddCreditCard();
        }
    }, [token]);

    const {
        data: addCreditCardData,
        error: addCreditCardError,
        execute: doAddCreditCard,
    } = useFetch<Record<string, any> | null>(`/api/payment-subscription/payment-methods`, {
        method: 'POST',
        body: JSON.stringify({
            token,
        }),
    });

    const firstNameHasError = () => {
        if (typeof firstName !== 'undefined' && firstName.error !== null) {
            return firstName.error;
        }
        return null;
    };

    const lastNameHasError = () => {
        if (typeof lastName !== 'undefined' && lastName.error !== null) {
            return lastName.error;
        }
        return null;
    };

    useEffect(() => {
        if (addCreditCardData) {
            const data = addCreditCardData as Record<string, any>;
            setPaymentMethodId(data.id);
            setAddCreditCardRequested(false);
        } else if (addCreditCardError) {
            setAddCreditCardRequested(false);
            setToken(null);
            setPaymentIntent(null);
            setAlert(true);
        }
    }, [addCreditCardData, addCreditCardError]);

    return (
        <SBAddPaymentMethod
            type={type}
            firstName={typeof firstName === 'undefined' ? '' : (firstName.value as string)}
            errorFirstName={firstNameHasError()}
            successFirstName={isValidateForm === true && firstNameHasError() === null}
            setFirstNameCreditCard={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                setFieldValue('firstName', event.target.value);
            }}
            lastName={typeof lastName === 'undefined' ? '' : (lastName.value as string)}
            errorLastName={lastNameHasError()}
            successLastName={isValidateForm === true && lastNameHasError() === null}
            setLastNameCreditCard={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                setFieldValue('lastName', event.target.value);
            }}
            onModalClose={(event) => {
                setCardComponent(null);
                onModalClose(event);
            }}
            onAddPaymentMethod={(event) => {
                event.preventDefault();
                setAddCreditCardRequested(true);
            }}
            alert={alert}
            disabledSaveBtn={token !== null || paymentIntent !== null || addCreditCardRequested}
            paymentMethods={(chargeBee?.paymentMethods ?? []) as PaymentType[]}
            setPayment={(e) => setPayment(e)}
        />
    );
};

export default AddPaymentMethod;
