import React from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import { Alert, Button, IconInfoEmpty, Modal } from '@darwinex/components-library';
import styles from './CancelParticipation.module.scss';

interface Props {
    onModalClose: React.MouseEventHandler;
    onCancelSubscription: () => void;
    onRestartSubscription: () => void;
    cancelRequested?: boolean;
}

const CancelParticipation = ({
    onModalClose,
    onRestartSubscription,
    onCancelSubscription,
    cancelRequested = false,
}: Props) => {
    const { M } = useI18N();
    return (
        <Modal size="md" title={M('modals.cancel-participation.title')} onModalClose={onModalClose}>
            <div className="dx-font-size-lg">
                <h5 className="dx-mb-3 font-open-sans--bold">{M('modals.cancel-participation.are-you-sure.title')}</h5>
                <p className="dx-my-3">{M('modals.cancel-participation.are-you-sure.description')}</p>
                <p className="dx-my-3 font-open-sans--bold">
                    {M('modals.cancel-participation.are-you-sure.participate-again')}
                </p>
                <Alert
                    variant="info"
                    shadow={false}
                    className="dx-py-3 dx-mb-7"
                    iconClassName={styles.alert_icon}
                    icon={<IconInfoEmpty viewBox="0 0 24 24" />}
                >
                    {M('modals.cancel-participation.alert')}
                </Alert>
                <h5 className="font-open-sans--bold dx-my-3">
                    {M('modals.cancel-participation.restart-recommendation.title')}
                </h5>
                <p>{M('modals.cancel-participation.restart-recommendation.description')}</p>
            </div>
            <div className="dx-mt-9 d-flex align-items-center justify-content-between">
                <Button onClick={onRestartSubscription} size="z-md" border="rect" variant="secondary" type="submit">
                    {M('modals.cancel-participation.restart-cta')}
                </Button>
                <Button
                    onClick={onCancelSubscription}
                    size="z-md"
                    border="rect"
                    variant="danger"
                    type="submit"
                    disabled={cancelRequested}
                >
                    {M('modals.cancel-participation.cancel-cta')}
                </Button>
            </div>
        </Modal>
    );
};

export default CancelParticipation;
