import React, { useEffect } from 'react';

import { useProfile } from '~/contexts/NextProfileProvider';
import { useModal } from '~/contexts/NextModalProvider';
import { T_ProfileResponse } from '~/pages/api/profile';
import { useProfileService } from '~/hooks/services/useProfileService';
import SBAcceptTermsProvider from '~/storybook/pages/platform/settings/modals/accept-terms-provider/AcceptTermsProvider';
import useFetch from '~/hooks/useFetch';
import { sendDataLayerEvent } from '~/utils/analytics';
import { useI18N } from '~/contexts/NextI18NProvider';
import { T_PartialProfile, UpdateProfileType } from '~/services/profile';

interface Props {
    configuration?: Record<string, any> | null;
}

const AcceptTermsProvider = ({ configuration }: Props) => {
    const { setPartialProfile } = useProfile();
    const { getProfileInfo } = useProfileService();
    const { closeModal } = useModal();
    const { locale } = useI18N();

    const {
        data: validationData,
        error: validationError,
        status: validationStatus,
        execute: doSignLegalTerms,
    } = useFetch(`/api/user/signed-legal-documents`, {
        method: 'POST',
        body: JSON.stringify({
            signedLegalDocumentType: 'TERMS_AND_CONDITIONS_DARWIN_PROVIDER',
            language: locale,
        }),
    });

    useEffect(() => {
        if (validationStatus === 201) {
            sendDataLayerEvent({ event: 'darwin_provider_term' });
            doGetUserData();
        } else if (validationError) {
            closeModal();
        }
    }, [validationStatus, validationError]);

    const {
        data: userData,
        error: userError,
        execute: doGetUserData,
    } = getProfileInfo<T_PartialProfile>([UpdateProfileType.LEGAL_DOCUMENTS]);

    useEffect(() => {
        if (userData) {
            setPartialProfile(userData);
            closeModal();
            if (configuration && typeof configuration.onAccept !== 'undefined') {
                configuration.onAccept();
            }
        } else if (userError) {
            closeModal();
        }
    }, [userData, userError]);

    const onModalClose = () => {
        closeModal();
    };

    return (
        <SBAcceptTermsProvider
            onModalClose={() => onModalClose()}
            onAcceptTerms={() => {
                doSignLegalTerms();
            }}
        />
    );
};

export default AcceptTermsProvider;
