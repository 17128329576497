import { Button, IconCloseRounded, Input, Modal } from '@darwinex/components-library';
import React from 'react';
import { useI18N } from '~/contexts/NextI18NProvider';
import styles from '~/storybook/pages/platform/settings/modals/add-withdrawal-method/AddWithdrawalMethod.module.scss';

interface Props {
    onGoToQuestions: React.MouseEventHandler;
    onModalClose: React.MouseEventHandler;
    nationalId: string;
    setNationalId: (e: React.ChangeEvent<HTMLInputElement>) => void;
    validationMsg?: string;
    error?: boolean;
    warning?: boolean;
    success?: boolean;
}

export const KnowledgeTestIntroNationalID = ({
    onGoToQuestions,
    onModalClose,
    nationalId,
    setNationalId,
    validationMsg = '',
    error = false,
    warning = false,
    success = false,
}: Props) => {
    const { M } = useI18N();
    return (
        <Modal
            type="plain"
            size="md"
            headerClassName="bg-secondary-300 color-secondary-600 dx-py-5"
            title={M('knowledge-test.modals.intro.title')}
            onModalClose={onModalClose}
            iconClose={<IconCloseRounded width={24} height={24} />}
        >
            <p className="dx-font-size-lg">{M('knowledge-test.modals.intro.body')}</p>

            <div className="d-flex justify-content-end dx-mt-5">
                <Input
                    name="dni"
                    type="text"
                    value={nationalId ?? ''}
                    wrapperClassName={styles.input}
                    label={M('knowledge-test.modals.intro.dni.label')}
                    placeholder="12345678A"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNationalId(e);
                    }}
                    error={error}
                    warning={warning}
                    success={success}
                    validationMsg={validationMsg}
                    required
                />
            </div>

            <p className="dx-mt-3">{M('knowledge-test.modals.intro.dni.msg')}</p>

            <div className="d-flex justify-content-end dx-mt-5">
                <Button
                    size="z-md"
                    border="rect"
                    type="button"
                    variant="primary"
                    disabled={error}
                    onClick={onGoToQuestions}
                >
                    {M('knowledge-test.modals.intro.cta')}
                </Button>
            </div>
        </Modal>
    );
};
