import { apiRequest } from '~/common/apiRequest';
import { RegulatedCompanies } from '~/enums/profile';

export interface UserPreferences {
    visualizationPreferences: Record<string, string>;
}

export const INIT_USER_PREFERENCES = {
    visualizationPreferences: {},
};

export async function getUserPreferences(headers: Headers): Promise<UserPreferences> {
    const response = await apiRequest(headers, '/api/user/visualization-preferences', {}, false);

    if (response.ok) {
        const visualizationPreferences = (response.data as Record<string, any>) ?? {};
        const visualizationPreferencesZero: { [key: string]: any } = {};
        Object.keys(visualizationPreferences).forEach((key) => {
            if (key.startsWith('ZERO')) {
                visualizationPreferencesZero[key] = visualizationPreferences[key];
            }
        });

        return {
            visualizationPreferences: visualizationPreferencesZero,
        };
    }

    return { ...INIT_USER_PREFERENCES };
}
