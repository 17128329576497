import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import StartParticipation from '~/components/modals/start-participation';
import CancelParticipation from '~/components/modals/cancel-participation';
import CancelParticipationManual from '~/components/modals/cancel-participation-manual';
import RestartParticipation from '~/components/modals/restart-participation';
import AcceptTermsProvider from '~/components/modals/accept-terms-provider';
import ReactiveParticipation from '~/components/modals/reactive-participation';
import GenericError from '~/components/modals/generic-error';
import KnowledgeTestOnboardingModal from '~/components/modals/knowledge-test-onboarding';
import { ConsulKnowledgeTestInfo } from '~/types';
import { shouldDoKnowledgeTestByConsulAndWhitelist } from '~/utils/consul-knowledge-test';
import { useProfile } from './NextProfileProvider';

/**
 * Defines the Modal Context for the whole app
 */

export type T_ModalConfiguration = {
    name: string;
    configuration: Record<string, any>;
};

export type T_Modal = string | null | T_ModalConfiguration;

type ModalProvider = {
    modal: T_Modal;
    setModal: (modal: T_Modal) => void;
    closeModal: () => void;
};

const NextModalContext = React.createContext<ModalProvider | null>(null);

type NextModalProviderProps = {
    requestOriginCountry: string | null;
    consulKnowledgeTest?: ConsulKnowledgeTestInfo;
    children: ReactNode;
};

export const NextModalProvider = ({ children, requestOriginCountry, consulKnowledgeTest }: NextModalProviderProps) => {
    const [modal, setModal] = useState<T_Modal>(null);
    const { profile } = useProfile();

    const closeModal = useCallback(() => setModal(null), []);

    const providerModalValue = useMemo<ModalProvider>(
        () => ({ modal, setModal, closeModal }),
        [modal, setModal, closeModal],
    );

    const modalHasConfiguration = (modalConfig: T_Modal): modalConfig is T_ModalConfiguration =>
        !!(modalConfig as T_ModalConfiguration).name;

    const modalName = modal === null ? null : modalHasConfiguration(modal) ? modal.name : modal;
    const modalConfiguration = modal === null ? null : modalHasConfiguration(modal) ? modal.configuration : null;

    return (
        <NextModalContext.Provider value={providerModalValue}>
            {children}
            <>
                {modalName === 'start-participation' && <StartParticipation />}
                {modalName === 'cancel-participation' && <CancelParticipation />}
                {modalName === 'cancel-participation-manual' && <CancelParticipationManual />}
                {modalName === 'restart-participation' && (
                    <RestartParticipation
                        configuration={modalConfiguration}
                        requestOriginCountry={requestOriginCountry}
                        consulKnowledgeTest={consulKnowledgeTest}
                    />
                )}
                {modalName === 'reactive-participation' && <ReactiveParticipation />}
                {modalName === 'accept-terms-provider' && <AcceptTermsProvider configuration={modalConfiguration} />}
                {modalName === 'generic-error' && <GenericError configuration={modalConfiguration} />}
                {profile &&
                    consulKnowledgeTest &&
                    shouldDoKnowledgeTestByConsulAndWhitelist(consulKnowledgeTest, profile) &&
                    modalName === 'knowledge-test-onboarding' && (
                        <KnowledgeTestOnboardingModal
                            configuration={modalConfiguration}
                            requestOriginCountry={requestOriginCountry}
                        />
                    )}
            </>
        </NextModalContext.Provider>
    );
};

export const useModal = () => {
    const context = React.useContext(NextModalContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a NextModalProvider');
    }
    return context as ModalProvider;
};
