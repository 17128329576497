import useFetch from '~/hooks/useFetch';
import Nullable from '~/types/Nullable';
import { Currency } from '~/enums/Currency';
import { SubscriptionStatus } from '~/enums/profile';

interface State<T> {
    data?: T;
    error?: Error;
    status?: number;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const PAYMENT_SUBSCRIPTION_URL = '/api/payment-subscription';

interface SubscriptionPrice {
    baseRecurrentPrice: number;
    currency: Currency;
    extraRecurrentPrice: number;
    initialPrice: number;
    restartPrice: number;
}

interface SubscriptionPriceWithDiscountInfo {
    basePrice: SubscriptionPrice;
    discount?: {
        applicableTo: string;
        appliedCouponCode: string;
        initialDiscount: {
            quantity: number;
            type: string;
        };
    };
    discountedPrice?: SubscriptionPrice;
}

interface AmountWithCurrency {
    currency: Currency;
    amount: number;
}

interface PaymentPreviewInfoDto {
    charges: {
        currency: Currency;
        restartCharge: number;
        proratedCharges: number;
    };
    credit: AmountWithCurrency;
    walletMoneyBefore: {
        baseCurrency: AmountWithCurrency;
        walletCurrency: AmountWithCurrency;
    };
    nextBillingAtAfterRestart: number;
    paymentMethod: {
        type: string;
        hint: string;
    };
    defaultPreview: {
        paymentDue: AmountWithCurrency;
        walletMoneyAfter: {
            baseCurrency: AmountWithCurrency;
            walletCurrency: AmountWithCurrency;
        };
    };
    withEarningPreview: {
        paymentDue: AmountWithCurrency;
        walletMoneyAfter: {
            baseCurrency: AmountWithCurrency;
            walletCurrency: AmountWithCurrency;
        };
    };
}

export enum CouponStatus {
    COUPON_APPLIED = 'COUPON_APPLIED',
    COUPON_NOT_FOUND = 'COUPON_NOT_FOUND',
    COUPON_NOT_VALID = 'COUPON_NOT_VALID',
    COUPON_NOT_APPLICABLE = 'COUPON_NOT_APPLICABLE',
}

interface SubscriptionDetailData {
    creditCard: {
        last4Numbers: string;
        firstName: string;
        lastName: string;
        expiryMonth: number;
        expiryYear: number;
        expiryDate: number;
    };
    nextRecurrentDate: number;
    paypal: Nullable<string>;
    publicId: string;
    scheduledServiceId: Nullable<string>;
    serviceId: string;
    state: SubscriptionStatus;
    subscriptionPrice: {
        currency: Currency;
        periodNumberOfMonths: number;
        periodPrice: number;
    };
}

export const usePaymentsSubscriptionService = () => {
    const useGetSubscriptionPrice = (
        serviceId: string,
        country: string,
        coupon: Nullable<string>,
    ): UseFetchReturnType<SubscriptionPriceWithDiscountInfo> => {
        let URL = `${PAYMENT_SUBSCRIPTION_URL}/services/${serviceId}/preview-subscription-price`;
        const searchParams = new URLSearchParams();
        searchParams.set('country', country);
        if (coupon) {
            searchParams.set('coupon', coupon);
        }
        if (searchParams.toString().length > 0) {
            URL += `?${searchParams.toString()}`;
        }
        const { data, status, error, execute } = useFetch<SubscriptionPriceWithDiscountInfo>(URL);
        return {
            data,
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    const useGetPaymentPreview = (
        subscriptionId: Nullable<string>,
        serviceId: Nullable<string>,
    ): UseFetchReturnType<PaymentPreviewInfoDto> => {
        const URL = `${PAYMENT_SUBSCRIPTION_URL}/restart-subscription-preview`;
        const { data, execute } = useFetch<PaymentPreviewInfoDto>(URL, {
            method: 'POST',
            body: JSON.stringify({
                subscriptionId,
                serviceId,
            }),
        });
        return {
            data,
            execute: () => {
                if (subscriptionId && serviceId) {
                    execute();
                }
            },
        };
    };

    const useUpdateSubscriptionPaymentPeriod = (
        subscriptionId: Nullable<string>,
        serviceId: Nullable<string>,
    ): UseFetchReturnType<void> => {
        const URL = `${PAYMENT_SUBSCRIPTION_URL}/subscriptions/${subscriptionId}/change-frequency`;
        const { execute, status } = useFetch(URL, {
            method: 'PUT',
            body: JSON.stringify({
                serviceId,
            }),
        });
        return {
            status,
            execute: () => {
                if (subscriptionId && serviceId) {
                    execute();
                }
            },
        };
    };

    const useGetSubscriptionDetailsData = (
        subscriptionId: Nullable<string>,
    ): UseFetchReturnType<SubscriptionDetailData> => {
        const URL = `${PAYMENT_SUBSCRIPTION_URL}/subscriptions/${subscriptionId}`;
        const { data, execute } = useFetch<SubscriptionDetailData>(URL);
        return {
            data,
            execute: () => {
                if (subscriptionId) {
                    execute();
                }
            },
        };
    };

    return {
        getSubscriptionPrice: useGetSubscriptionPrice,
        getPaymentPreview: useGetPaymentPreview,
        updateSubscriptionPaymentPeriod: useUpdateSubscriptionPaymentPeriod,
        getSubscriptionDetailsData: useGetSubscriptionDetailsData,
    };
};
