import { MigrateYourDarwinStepsStatus, MigrationDarwinStatus } from '~/utils/enums';
import {
    CTA_MigrationTypeValue,
    MigrateYourDarwinProps,
} from '~/storybook/pages/platform/settings/components/migrate-your-darwin/MigrateYourDarwin';
import { MigrationStatus } from '~/services/migration';
import { T_ProfileResponse } from '~/pages/api/profile';

type T_MigrationStepsConfig = {
    openInternalLink: (url: string) => void;
    openExternalLink: (url: string) => void;
    migrationStatus: MigrationStatus;
    isOpenMigratedSteps: boolean;
    setIsOpenMigratedSteps: (isOpen: boolean) => void;
    startMigration: () => void;
    cancelMigration: () => void;
    requestMigration: () => void;
};

const DEFAULT_STATUS = {
    STEP_1: 'page.settings.components.migrate-your-darwin.steps.open-live',
    STEP_2: 'page.settings.components.migrate-your-darwin.steps.fund-account',
    STEP_3: 'page.settings.components.migrate-your-darwin.steps.migrate-darwin',
    STEP_4: 'page.settings.components.migrate-your-darwin.steps.start-trading',
};

const STEP_1_STATUTES = {
    ACTIVE: {
        label: 'page.settings.components.migrate-your-darwin.steps.register',
        status: MigrateYourDarwinStepsStatus.ACTIVE,
    },
    WARNING: {
        label: DEFAULT_STATUS.STEP_1,
        status: MigrateYourDarwinStepsStatus.WARNING,
    },
    SUCCESS: {
        label: DEFAULT_STATUS.STEP_1,
        status: MigrateYourDarwinStepsStatus.SUCCESS,
    },
};

const STEP_2_STATUTES = {
    INACTIVE: {
        label: DEFAULT_STATUS.STEP_2,
        status: MigrateYourDarwinStepsStatus.INACTIVE,
    },
    ACTIVE: {
        label: DEFAULT_STATUS.STEP_2,
        status: MigrateYourDarwinStepsStatus.ACTIVE,
    },
    SUCCESS: {
        label: DEFAULT_STATUS.STEP_2,
        status: MigrateYourDarwinStepsStatus.SUCCESS,
    },
};

const STEP_3_STATUTES = {
    INACTIVE: {
        label: DEFAULT_STATUS.STEP_3,
        status: MigrateYourDarwinStepsStatus.INACTIVE,
    },
    ACTIVE: {
        label: DEFAULT_STATUS.STEP_3,
        status: MigrateYourDarwinStepsStatus.ACTIVE,
    },
    WARNING: {
        label: 'page.settings.components.migrate-your-darwin.steps.migrating-darwin',
        status: MigrateYourDarwinStepsStatus.WARNING,
    },
    SUCCESS: {
        label: DEFAULT_STATUS.STEP_3,
        status: MigrateYourDarwinStepsStatus.SUCCESS,
    },
    ERROR: {
        label: 'page.settings.components.migrate-your-darwin.steps.migration-error',
        status: MigrateYourDarwinStepsStatus.ERROR,
    },
};

const STEP_4_STATUTES = {
    INACTIVE: {
        label: DEFAULT_STATUS.STEP_4,
        status: MigrateYourDarwinStepsStatus.INACTIVE,
    },
    SUCCESS: {
        label: DEFAULT_STATUS.STEP_4,
        status: MigrateYourDarwinStepsStatus.SUCCESS,
    },
};

export const getMigrationSteps = ({
    openInternalLink,
    openExternalLink,
    migrationStatus,
    isOpenMigratedSteps,
    setIsOpenMigratedSteps,
    startMigration,
    cancelMigration,
    requestMigration,
}: T_MigrationStepsConfig): MigrateYourDarwinProps | null => {
    if (
        !migrationStatus ||
        migrationStatus === MigrationDarwinStatus.NOT_MIGRABLE ||
        migrationStatus === MigrationDarwinStatus.CANCELLED ||
        migrationStatus === MigrationDarwinStatus.NONE
    )
        return null;

    const CTA_MigrationFunction = {
        onClickStart: startMigration,
        onClickCancel: cancelMigration,
    };

    const OpenChevron = {
        isOpenMigratedSteps,
        setIsOpenMigratedSteps,
    };

    if (migrationStatus === MigrationDarwinStatus.MIGRABLE) {
        return {
            CTA_Migration: {
                type: CTA_MigrationTypeValue.START,
                ...CTA_MigrationFunction,
            },
            hideChevron: true,
            ...OpenChevron,
            step: null,
            steps: null,
        };
    }

    switch (migrationStatus) {
        case MigrationDarwinStatus.REQUESTED_NOT_USER:
            return {
                CTA_Migration: {
                    type: CTA_MigrationTypeValue.CANCEL,
                    ...CTA_MigrationFunction,
                },
                hideChevron: false,
                ...OpenChevron,
                steps: [
                    STEP_1_STATUTES.ACTIVE,
                    STEP_2_STATUTES.INACTIVE,
                    STEP_3_STATUTES.INACTIVE,
                    STEP_4_STATUTES.INACTIVE,
                ],
                step: {
                    description: 'page.settings.components.migrate-your-darwin.status.REQUESTED_NOT_USER.description',
                    CTA_1: {
                        label: 'page.settings.components.migrate-your-darwin.status.REQUESTED_NOT_USER.cta-1',
                        onClick: () =>
                            openExternalLink(
                                'https://www.darwinex.com/login?utm_source=zero&utm_medium=link&utm_campaign=migrate_darwin&utm_content=settings',
                            ),
                        isExternal: true,
                    },
                },
            };

        case MigrationDarwinStatus.REQUESTED_NOT_KYC:
            return {
                CTA_Migration: {
                    type: CTA_MigrationTypeValue.CANCEL,
                    ...CTA_MigrationFunction,
                },
                hideChevron: false,
                ...OpenChevron,
                steps: [
                    STEP_1_STATUTES.WARNING,
                    STEP_2_STATUTES.INACTIVE,
                    STEP_3_STATUTES.INACTIVE,
                    STEP_4_STATUTES.INACTIVE,
                ],
                step: {
                    description: 'page.settings.components.migrate-your-darwin.status.REQUESTED_NOT_USER.description',
                    CTA_1: {
                        label: 'page.settings.components.migrate-your-darwin.status.REQUESTED_NOT_USER.cta-1',
                        onClick: () => openExternalLink('https://www.darwinex.com/login'),
                        isExternal: true,
                    },
                },
            };

        case MigrationDarwinStatus.REQUESTED_NOT_WALLET:
            return {
                CTA_Migration: {
                    type: CTA_MigrationTypeValue.CANCEL,
                    ...CTA_MigrationFunction,
                },
                hideChevron: false,
                ...OpenChevron,
                steps: [
                    STEP_1_STATUTES.SUCCESS,
                    STEP_2_STATUTES.ACTIVE,
                    STEP_3_STATUTES.INACTIVE,
                    STEP_4_STATUTES.INACTIVE,
                ],
                step: {
                    description: 'page.settings.components.migrate-your-darwin.status.REQUESTED_NOT_WALLET.description',
                    CTA_1: {
                        label: 'page.settings.components.migrate-your-darwin.status.REQUESTED_NOT_WALLET.cta-1',
                        onClick: () => openExternalLink('https://www.darwinex.com/wallet/add-funds'),
                        isExternal: true,
                    },
                    CTA_2: {
                        label: 'page.settings.components.migrate-your-darwin.status.REQUESTED_NOT_WALLET.cta-2',
                        onClick: () => openInternalLink('/settings/billing'),
                        isExternal: false,
                    },
                },
            };

        case MigrationDarwinStatus.REQUESTED:
            return {
                CTA_Migration: {
                    type: CTA_MigrationTypeValue.CANCEL,
                    ...CTA_MigrationFunction,
                },
                hideChevron: false,
                ...OpenChevron,
                steps: [
                    STEP_1_STATUTES.SUCCESS,
                    STEP_2_STATUTES.SUCCESS,
                    STEP_3_STATUTES.ACTIVE,
                    STEP_4_STATUTES.INACTIVE,
                ],
                step: {
                    description: 'page.settings.components.migrate-your-darwin.status.REQUESTED.description',
                    CTA_1: {
                        label: 'page.settings.components.migrate-your-darwin.status.REQUESTED.cta-1',
                        onClick: () => requestMigration(),
                        isExternal: false,
                    },
                },
            };

        case MigrationDarwinStatus.PENDING:
        case MigrationDarwinStatus.ACCEPTED:
        case MigrationDarwinStatus.IN_PROGRESS:
        case MigrationDarwinStatus.COMPLETED:
            return {
                CTA_Migration: {
                    type: null,
                    ...CTA_MigrationFunction,
                },
                hideChevron: false,
                ...OpenChevron,
                steps: [
                    STEP_1_STATUTES.SUCCESS,
                    STEP_2_STATUTES.SUCCESS,
                    STEP_3_STATUTES.WARNING,
                    STEP_4_STATUTES.INACTIVE,
                ],
                step: {
                    description: 'page.settings.components.migrate-your-darwin.status.PENDING.description',
                },
            };

        case MigrationDarwinStatus.REJECTED:
            return {
                CTA_Migration: {
                    type: null,
                    ...CTA_MigrationFunction,
                },
                hideChevron: false,
                ...OpenChevron,
                steps: [
                    STEP_1_STATUTES.SUCCESS,
                    STEP_2_STATUTES.SUCCESS,
                    STEP_3_STATUTES.ERROR,
                    STEP_4_STATUTES.INACTIVE,
                ],
                step: {
                    description: 'page.settings.components.migrate-your-darwin.status.REJECTED.description',
                },
            };

        case MigrationDarwinStatus.VALIDATED:
            return {
                CTA_Migration: {
                    type: null,
                    ...CTA_MigrationFunction,
                },
                hideChevron: false,
                ...OpenChevron,
                steps: [
                    STEP_1_STATUTES.SUCCESS,
                    STEP_2_STATUTES.SUCCESS,
                    STEP_3_STATUTES.SUCCESS,
                    STEP_4_STATUTES.SUCCESS,
                ],
                step: {
                    description: 'page.settings.components.migrate-your-darwin.status.VALIDATED.description',
                    CTA_1: {
                        label: 'page.settings.components.migrate-your-darwin.status.VALIDATED.cta-1',
                        onClick: () => openExternalLink('https://www.darwinex.com/login'),
                        isExternal: true,
                    },
                },
            };
    }

    return null;
};

export const forbiddenCancelParticipation = (profile: T_ProfileResponse) =>
    profile &&
    profile.migrationDarwin.status &&
    [
        MigrationDarwinStatus.PENDING as string,
        MigrationDarwinStatus.ACCEPTED as string,
        MigrationDarwinStatus.IN_PROGRESS as string,
        MigrationDarwinStatus.COMPLETED as string,
        // MigrationDarwinStatus.VALIDATED as string,
    ].includes(profile.migrationDarwin.status);
