import React, { Component } from 'react';
import classNames from 'classnames';

import { getBottom, getHeight } from '~/common/utils';

import PropTypes from 'prop-types';

class DropdownTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            dropup: this.props.dropup,
            visible: typeof this.props.ready === 'undefined' ? true : this.props.ready,
        };

        this.handleClickClose = this.handleClickClose.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClickClose);
    }

    componentWillReceiveProps(nextProps) {
        const ctx = this;
        this.setState(
            {
                dropup:
                    nextProps.ready && !this.state.dropup && this.dropdownContent
                        ? getBottom(this.dropdownContent) < getHeight(this.dropdownContent) + 300
                        : nextProps.dropup !== this.props.dropup
                        ? nextProps.dropup
                        : this.state.dropup,
                open: this.state.open || (nextProps.open !== this.props.open && nextProps.open),
            },
            () => {
                setTimeout(function () {
                    ctx.setState({
                        visible: typeof nextProps.ready === 'undefined' ? true : nextProps.ready,
                    });
                }, 500);
            },
        );
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickClose);
    }

    handleClick(e) {
        if (this.props.trigger !== 'click') return;
        if (this.state.open) return;

        // wait for the next tick, to avoid auto close by the same click
        setTimeout(() => {
            this.setState(
                {
                    open: true,
                    visible: typeof this.props.ready === 'undefined' ? true : this.props.ready,
                },
                this.props.onOpen,
            );
        });
    }

    handleClickClose(e) {
        if (this.props.trigger !== 'click') return;
        if (!this.state.open) return;

        // avoid close when click inside
        let current = e.target;
        while (current) {
            if (current === this.dropdownContent) return;
            current = current.parentNode;
        }

        this.setState({ open: false, visible: false }, this.props.onClose);
    }

    handleMouseOver() {
        if (this.props.trigger !== 'hover') return;
        this.setState({ open: true }, this.props.onOpen);
    }

    handleMouseOut() {
        if (this.props.trigger !== 'hover') return;
        this.setState({ open: false }, this.props.onClose);
    }

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    render() {
        const { className, content, children, right, menuClasses } = this.props;
        const { open, dropup, visible } = this.state;
        return (
            <span
                ref={(e) => {
                    this.dropdown = e;
                }}
                className={classNames(dropup ? 'dropup' : 'dropdown', className, { open })}
                onClick={(e) => this.handleClick(e)}
                onMouseOver={() => this.handleMouseOver()}
                onMouseOut={() => this.handleMouseOut()}
            >
                {children}
                <div
                    style={{ visibility: visible ? 'visible' : 'hidden' }}
                    ref={(e) => {
                        this.dropdownContent = e;
                    }}
                    className={classNames('dropdown-menu', right ? 'dropdown-menu-right' : '', menuClasses)}
                >
                    {content}
                </div>
            </span>
        );
    }
}

DropdownTooltip.propTypes = {
    trigger: PropTypes.oneOf(['click', 'hover']),
    open: PropTypes.bool,
    className: PropTypes.string,
    right: PropTypes.bool,
    dropup: PropTypes.bool,
    menuClasses: PropTypes.string,
};

DropdownTooltip.defaultProps = {
    trigger: 'click',
    open: false,
    className: '',
    right: false,
    dropup: false,
    menuClasses: '',
    // ready: false,
    // visible: false,
};

export default DropdownTooltip;
