import { T_ProfileResponse } from '~/pages/api/profile';
import { getFirstActiveParticipation, getFirstParticipation } from '~/utils/participations';
import { getShortNameFromTicker } from '~/utils/darwin-name';
import { MigrationDarwinStatus } from '~/utils/enums';
import isClient from '~/utils/is-client';
import { getCookie } from 'cookies-next';

type QueryParam = {
    key: string;
    value: string;
};

const SETTINGS_URL = '/settings';
const SETTINGS_TRIBES_URL = '/settings/mytradingtribes';

const getDarwinUrl = (ticker: string | null, onlyWithDarwin?: boolean): string | null => {
    const DEFAULT_DARWIN_PAGE_TAB = 'performance';
    let url = null;
    if (ticker) {
        const darwinShortName = getShortNameFromTicker(ticker);
        url = `/darwin/${darwinShortName}`;
        if (!onlyWithDarwin) {
            url += `/${DEFAULT_DARWIN_PAGE_TAB}`;
        }
    }
    return url;
};

const getWelcomeUrl = (profile: T_ProfileResponse): string => {
    let tribeCookie = null;
    if (isClient()) {
        tribeCookie = getCookie('JOIN_TRIBE');
    }
    let url;
    const participation = getFirstActiveParticipation(profile);
    if (tribeCookie && profile && participation) {
        url = SETTINGS_TRIBES_URL;
    } else if (profile && participation && profile?.migrationDarwin?.status !== MigrationDarwinStatus.VALIDATED) {
        url = getDarwinUrl(participation?.tradingAccount?.ticker ?? null);
        if (!url) url = SETTINGS_URL;
    } else {
        url = SETTINGS_URL;
    }
    return url;
};

const getDefaultDarwinUrl = (profile: T_ProfileResponse, onlyWithDarwin?: boolean): string | null => {
    const participation = getFirstParticipation(profile);
    const ticker = participation?.tradingAccount?.ticker ?? null;

    let url = getDarwinUrl(ticker, onlyWithDarwin);
    if (!url) url = null;
    return url;
};

const addQueryParams = (baseUrl: string, queryParams: Array<QueryParam>) => {
    let finalUrl = baseUrl;
    if (queryParams.length > 0) {
        if (!baseUrl.includes('?')) {
            finalUrl += '?';
        } else {
            finalUrl += '&';
        }
        queryParams.forEach((queryParam, index) => {
            finalUrl += `${index !== 0 ? '&' : ''}${queryParam.key}=${queryParam.value}`;
        });
    }
    return finalUrl;
};

const getParamValue = (name: string): string => {
    if (typeof window !== 'undefined') {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(name) || '';
    }
    return '';
};

export { getWelcomeUrl, getDefaultDarwinUrl, getDarwinUrl, addQueryParams, getParamValue };
