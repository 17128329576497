import 'normalize.css';
import '@darwinex/components-library/build/styles/bootcamp.css';
import '~/styles/global.scss';

import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';

import { NextI18NProvider } from '~/contexts/NextI18NProvider';
import { NextProfileProvider } from '~/contexts/NextProfileProvider';
import { NextModalProvider } from '~/contexts/NextModalProvider';
import { CurrentCompetitionGeneralInfoProvider } from '~/contexts/CurrentCompetitionProvider';

import { NextNotificationsProvider } from '~/contexts/NextNotificationsProvider';
import Head from 'next/head';
import AnalyticsHeaderCommon from '~/components/common/analytics-header';
import AnalyticsCommon from '~/components/common/analytics';
import { DarwinLiveTradesInfoProvider } from '~/contexts/LiveTradesProvider';
import { NextPageWithLayout } from '~/types';
import Maintenance from '~/pages/maintenance';
import Cookies from 'js-cookie';

const FORCED_LANG_QUERY_PARAM = 'flang';

// eslint-disable-next-line @typescript-eslint/ban-types

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const BASE_URL = process.env.NEXT_PUBLIC_DEPLOYED_URL ?? '';

async function fetchConsulData() {
    const response = await fetch(
        `${BASE_URL}/api/consul-global-nextjs?keys=excluded-logged-locales,hidden-locales,is-maintenance`,
    );
    return response.json();
}

const MyApp = ({ Component, pageProps, router }: AppPropsWithLayout) => {
    const { query } = router;
    let locale = router.locale || 'en'; // Default lang is en

    // console.log('_app locale', locale);

    if (FORCED_LANG_QUERY_PARAM in query) {
        const localeQueryParam = query[FORCED_LANG_QUERY_PARAM];

        // console.log('localeQueryParam', localeQueryParam);

        if (typeof localeQueryParam !== 'string') console.error('Bad lang query param received...');
        locale = localeQueryParam as string;
    }

    const finalProps = { ...pageProps };
    delete finalProps.__messages;

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout ?? ((page) => page);

    const canonicalUrl = `${BASE_URL}${router.asPath === '/' ? '' : router.asPath}`.split('?')[0];

    const [excludedLoggedLocales, setExcludedLoggedLocales] = useState<string[]>([]);
    const [hiddenLocales, setHiddenLocales] = useState<string[]>([]);
    const [isMaintenance, setIsMaintenance] = useState<boolean>(false);

    useEffect(() => {
        fetchConsulData().then((response) => {
            setExcludedLoggedLocales(response['excluded-logged-locales'].split(',') ?? []);
            setHiddenLocales(response['hidden-locales'].split(',') ?? []);
            setIsMaintenance(response['is-maintenance'] ? response['is-maintenance'] === 'true' : false);
        });
    }, []);

    // Force reload if build IDs don't match
    useEffect(() => {
        if (!process.env.NEXT_PUBLIC_DEPLOYED_VERSION) {
            if (process.env.NODE_ENV !== 'development') {
                console.error('Unknown deployed version...');
            }
            return;
        }
        Cookies.set('DXZ_DV', process.env.NEXT_PUBLIC_DEPLOYED_VERSION as string, { sameSite: 'strict' });
        const checkVersion = () => {
            fetch('/api/version')
                .then((res) => {
                    if (!res.ok) throw Error(`Error getting version. Status code:${res.status}`);
                    return res.text();
                })
                .then((serverVersion) => {
                    const clientVersion = process.env.NEXT_PUBLIC_DEPLOYED_VERSION;
                    // console.log('versions', { serverVersion, clientVersion });
                    if (clientVersion !== serverVersion) {
                        window?.location.reload();
                    }
                })
                .catch((err) => console.error(err));
        };
        const intervalID = setInterval(checkVersion, 60 * 1000);
        return () => clearInterval(intervalID);
    }, []);

    return (
        <div className="s-bootcamp">
            <Head>
                <title>Darwinex Zero - Become an Investable Trader from €38 a month with Zero Risk</title>
                <meta
                    name="description"
                    content="Demonstrate the worth of your trading strategy in a virtual trading account and raise capital month by month as you grow. Get your subscription!"
                    key="description"
                />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
                <link rel="icon" type="image/png" href="/favicon.png" />

                <link rel="canonical" href={canonicalUrl} />

                <meta property="og:url" content={canonicalUrl} key="og_url" />
                <meta property="og:image" content={`${BASE_URL}/images/darwinex-zero-social.png`} key="og_image" />
                <meta
                    property="og:image:secure_url"
                    content={`${BASE_URL}/images/darwinex-zero-social.png`}
                    key="og_image_secure"
                />
                <meta property="og:image:type" content="image/png" key="og_image_type" />
                <meta property="og:image:width" content="1200" key="og_image_width" />
                <meta property="og:image:height" content="600" key="og_image_height" />
                <meta property="og:image:alt" content="www.darwinexzero.com" key="og_image_alt" />

                <meta name="twitter:image" content={`${BASE_URL}/images/darwinex-zero-social.png`} key="twitter" />
            </Head>

            <AnalyticsHeaderCommon />

            <NextI18NProvider
                initLocale={locale}
                locales={router.locales}
                messages={pageProps.__messages}
                excludedLoggedLocales={excludedLoggedLocales}
                hiddenLocales={hiddenLocales}
            >
                <NextProfileProvider>
                    <NextNotificationsProvider>
                        <DarwinLiveTradesInfoProvider>
                            <CurrentCompetitionGeneralInfoProvider>
                                <NextModalProvider
                                    requestOriginCountry={pageProps?.requestOriginCountry ?? null}
                                    consulKnowledgeTest={pageProps?.consulKnowledgeTest}
                                >
                                    <AnalyticsCommon />
                                    {isMaintenance ? <Maintenance /> : getLayout(<Component {...pageProps} />)}
                                </NextModalProvider>
                            </CurrentCompetitionGeneralInfoProvider>
                        </DarwinLiveTradesInfoProvider>
                    </NextNotificationsProvider>
                </NextProfileProvider>
            </NextI18NProvider>
        </div>
    );
};

// const ANALYTICS_URL = '/api/web-vitals';
//
// export function reportWebVitals(rawMetric: NextWebVitalsMetric) {
//     const metrics: WebVitals = rawMetric;
// console.log('process.env.NEXT_PUBLIC_WEB_VITALS_DISABLED', process.env.NEXT_PUBLIC_WEB_VITALS_DISABLED);
// if (process.env.NEXT_PUBLIC_WEB_VITALS_DISABLED !== 'true') {
// if (navigator.sendBeacon) {
//     metrics.sentUsing = 'beacon';
//     const body = JSON.stringify(metrics);
//     navigator.sendBeacon(ANALYTICS_URL, body);
// } else {
//     metrics.sentUsing = 'fetch';
//     const body = JSON.stringify(metrics);
//     fetch(ANALYTICS_URL, { body, method: 'POST', keepalive: true });
// }
// }
// }

export default MyApp;
