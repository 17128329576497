import { apiRequest } from '~/common/apiRequest';
import { MigrationDarwinStatus } from '~/utils/enums';
import { getAllLegalDocuments, T_Legal } from '~/services/legal-documents';
import moment from 'moment/moment';
import { UserNotification } from '~/services/user-notifications';

export const MIN_WALLET_AVAILABLE = 1000;
export const CLOSE_BANNER_MIGRATE_ZERO = 'CLOSE_BANNER_MIGRATE_ZERO';

export type MigrationStatus = MigrationDarwinStatus | null;

export type MigrationResponse = {
    status: MigrationStatus;
    details: Record<string, any> | null;
};

export interface MigrationDarwin {
    migrationDarwin: MigrationResponse;
}

export const INIT_MIGRATION = {
    migrationDarwin: {
        status: null,
        details: null,
    },
};

export async function getMigration(
    headers: Headers,
    productName: string,
    legalDocuments?: T_Legal[],
    notifications?: UserNotification[],
): Promise<MigrationDarwin> {
    let details = null;

    if (!productName || productName === '') {
        return { ...INIT_MIGRATION };
    }

    try {
        const response: any = await apiRequest(headers, `/api/darwin-migration/migrations/product/${productName}`);
        if (response.ok) {
            const dataMigrationStatus: MigrationStatus = response?.data?.status ?? null;

            if (dataMigrationStatus) {
                details = {
                    migrationDate: response?.data?.migrationDate ?? null,
                    productName: response?.data?.productName ?? null,
                    rejectionDate: response?.data?.rejectionDate ?? null,
                    scheduledDate: response?.data?.scheduledDate ?? null,
                };
            }

            if (
                !dataMigrationStatus ||
                dataMigrationStatus === MigrationDarwinStatus.NONE ||
                dataMigrationStatus === MigrationDarwinStatus.CANCELLED
            ) {
                const responseMigrable: any = await apiRequest(
                    headers,
                    `/api/darwin-migration/product/${productName}/eligible`,
                );
                if (responseMigrable.ok) {
                    const isMigrable: boolean = responseMigrable?.data?.eligible ?? false;
                    let showBanner = true;
                    if (isMigrable) {
                        let notificationsUser = notifications;
                        if (!notificationsUser) {
                            const responseNotifications: any = await apiRequest(headers, `/api/user/notification`);
                            if (response.ok) {
                                notificationsUser = responseNotifications.notifications as UserNotification[];
                            }
                        }
                        if (notificationsUser) {
                            const notification = notificationsUser.find(
                                (f) => f.name === CLOSE_BANNER_MIGRATE_ZERO && f.dateClosed !== null,
                            );
                            if (typeof notification === 'object') {
                                const currentMonth = moment.utc().month();
                                const notificationMonth = notification.dateClosed
                                    ? moment.utc(notification.dateClosed).month()
                                    : null;
                                if (notificationMonth && currentMonth === notificationMonth) {
                                    showBanner = false;
                                }
                            }
                        }

                        return {
                            migrationDarwin: {
                                status: MigrationDarwinStatus.MIGRABLE,
                                details: {
                                    showBanner,
                                    ...details,
                                },
                            },
                        };
                    }

                    return {
                        migrationDarwin: {
                            status: MigrationDarwinStatus.NOT_MIGRABLE,
                            details,
                        },
                    };
                }
                return { ...INIT_MIGRATION };
            }

            if (dataMigrationStatus === MigrationDarwinStatus.REQUESTED) {
                let termsAcceptedDarwinex;
                let legalDocumentsUser = legalDocuments;
                if (!legalDocumentsUser) {
                    const legalDocuments = await getAllLegalDocuments(headers);
                    legalDocumentsUser = legalDocuments.legalDocuments;
                }
                if (legalDocumentsUser) {
                    termsAcceptedDarwinex = legalDocumentsUser.filter(
                        (legal) => legal.type === 'TERMS_AND_CONDITIONS' && legal.platform === 'DARWINEX',
                    );
                }
                if (!termsAcceptedDarwinex || termsAcceptedDarwinex.length === 0) {
                    return {
                        migrationDarwin: {
                            status: MigrationDarwinStatus.REQUESTED_NOT_USER,
                            details,
                        },
                    };
                }
                const responseKYC: any = await apiRequest(headers, `/api/mifid/status`);
                if (responseKYC.ok) {
                    const KYC = responseKYC?.data;
                    if (!KYC || !(KYC.mifidStatus === 'MIFID_OK' || KYC.mifidCorporativeStatus === 'MIFID_OK')) {
                        return {
                            migrationDarwin: {
                                status: MigrationDarwinStatus.REQUESTED_NOT_KYC,
                                details,
                            },
                        };
                    }
                    const responseWallet: any = await apiRequest(headers, `/api/payments/wallet/available`);
                    if (responseWallet.ok) {
                        const walletAvailable = responseWallet?.data?.available ?? 0;
                        const currency = responseWallet?.data?.currency ?? null;

                        let minEquityAllowed = MIN_WALLET_AVAILABLE;
                        let leverage = null;
                        let recommendedEquity = null;

                        const responseRecommendations: any = await apiRequest(
                            headers,
                            `/api/darwin-migration/product/${productName}/recommendations`,
                        );
                        if (responseRecommendations.ok) {
                            const dataMigrationRecommendations: Record<string, any> = responseRecommendations?.data;
                            minEquityAllowed = dataMigrationRecommendations?.minEquityAllowed ?? MIN_WALLET_AVAILABLE;
                            leverage = dataMigrationRecommendations?.leverage ?? null;
                            recommendedEquity = dataMigrationRecommendations?.recommendedEquity ?? null;
                        }

                        if (!walletAvailable || walletAvailable < minEquityAllowed) {
                            return {
                                migrationDarwin: {
                                    status: MigrationDarwinStatus.REQUESTED_NOT_WALLET,
                                    details: {
                                        currency,
                                        minEquityAllowed,
                                        leverage,
                                        recommendedEquity,
                                        ...details,
                                    },
                                },
                            };
                        }
                        return {
                            migrationDarwin: {
                                status: MigrationDarwinStatus.REQUESTED,
                                details: {
                                    walletAvailable,
                                    currency,
                                    minEquityAllowed,
                                    leverage,
                                    recommendedEquity,
                                    ...details,
                                },
                            },
                        };
                    }
                    return {
                        migrationDarwin: {
                            status: MigrationDarwinStatus.REQUESTED_NOT_WALLET,
                            details,
                        },
                    };
                }
                return {
                    migrationDarwin: {
                        status: MigrationDarwinStatus.REQUESTED_NOT_KYC,
                        details,
                    },
                };
            }
            return {
                migrationDarwin: {
                    status: dataMigrationStatus,
                    details,
                },
            };
        }
        return { ...INIT_MIGRATION };
    } catch (errorMigrationService) {
        return { ...INIT_MIGRATION };
    }
}
