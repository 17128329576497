import useFetch from '~/hooks/useFetch';
import { PriceResponseNewDto } from '~/pages/api/prices';
import { addQueryParams } from '~/utils/urls';

interface State<T> {
    data: T;
    status?: number;
    error?: Error;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

export const usePricesService = () => {
    const useGetPriceInfo = (country: string, currency?: string): UseFetchReturnType<PriceResponseNewDto[]> => {
        const URL = '/api/prices';
        const queryParams = [{ key: 'country', value: country }];
        if (currency) {
            queryParams.push({ key: 'userCurrency', value: currency });
        }
        const urlWithParams = addQueryParams(URL, queryParams);
        const { data, status, error, execute } = useFetch<PriceResponseNewDto[]>(urlWithParams);
        return {
            data: data ?? [],
            status,
            error,
            execute: () => {
                execute();
            },
        };
    };

    return {
        getPricesInfo: useGetPriceInfo,
    };
};
