const getKeyByFamilyAndPlatform = (family: string, platform: string) => {
    const platformKey = platform === 'MT4' ? 'MT4' : 'MT5';
    let familyKey = '';
    switch (family) {
        case 'FOREX':
            familyKey = 'forex-cfds';
            break;
        case 'FUTURES':
            familyKey = 'futures';
            break;
        case 'STOCKS_USD':
            familyKey = 'stocks';
            break;
        default:
            familyKey = '';
    }
    return `${platformKey}.${familyKey}`;
};

export { getKeyByFamilyAndPlatform };
