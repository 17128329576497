import { ConsulKnowledgeTestInfo } from '~/types';
import { T_Profile } from '~/services/profile';
import Nullable from '~/types/Nullable';
import ConsulClient from '~/common/consul';
import { GetServerSidePropsContext } from 'next';

export function withConsulKnowledgeTestInfo<P>(
    handler: (context: GetServerSidePropsContext /* fetchWithCredentials: FetchWithCredentials */) => P,
) {
    return async (context: GetServerSidePropsContext) => {
        if (!(context.req.cookies.atdx && context.req.cookies.logged)) {
            return handler(context);
        }

        const consulKnowledgeTest = await getKnowledgeTestConsulInfo();
        const propsOrError = (await handler(context)) as any;

        if ('error' in propsOrError) {
            if (propsOrError.status === 401) {
                console.error('Error 401 on withServerAuth to:', context.resolvedUrl);
                return {
                    destination: '/logout',
                    permanent: false,
                };
            }
            return {
                props: {},
            };
        }
        if ('props' in propsOrError) {
            return {
                props: { ...propsOrError.props, consulKnowledgeTest },
            };
        }
    };
}

export async function getKnowledgeTestConsulInfo(): Promise<ConsulKnowledgeTestInfo> {
    const consul = ConsulClient.getInstance();
    const IS_KNOWLEDGE_TEST_ENABLED = (await consul.getValue('knowledge-test/enabled')) === 'true';
    const KNOWLEDGE_TEST_WHITELIST = ((await consul.getValue('knowledge-test/avoid-test-whitelist')) || '').split(',');
    // console.log('#getKnowledgeTestConsulInfo', { IS_KNOWLEDGE_TEST_ENABLED, KNOWLEDGE_TEST_WHITELIST });
    return {
        IS_KNOWLEDGE_TEST_ENABLED,
        AVOID_TEST_WHITELIST: KNOWLEDGE_TEST_WHITELIST,
    };
}

export function shouldDoKnowledgeTestByConsulAndWhitelist(
    consulKnowledgeTest: ConsulKnowledgeTestInfo,
    profile: T_Profile,
) {
    const isTestEnabled = consulKnowledgeTest.IS_KNOWLEDGE_TEST_ENABLED;
    const userHasToDoTest = !consulKnowledgeTest.AVOID_TEST_WHITELIST.includes(profile.username);
    // console.log('#shouldDoKnowledgeTest', {
    //     isTestEnabled,
    //     userHasToDoTest,
    //     whitelist: consulKnowledgeTest.AVOID_TEST_WHITELIST,
    //     username: profile?.username,
    // });
    return isTestEnabled && userHasToDoTest;
}
