import React, { ChangeEvent, useEffect, useState } from 'react';
import { T_ChargeBeeProps } from '~/pages/api/chargebee';
import useFetch from '~/hooks/useFetch';
import { PaymentType } from '~/pages/checkout';
import SBAddPayPalMethod from '~/storybook/pages/platform/settings/modals/add-payment-method/AddPayPalMethod';
import { useProfile } from '~/contexts/NextProfileProvider';
import isClient from '~/utils/is-client';

interface AddPaypalProps {
    onModalClose?: React.MouseEventHandler;
    setPaymentMethodIdPaypal: (paymentMethodId: string | null) => void;
    setPayment?: (e: ChangeEvent) => void;
}

declare global {
    interface Window {
        Chargebee: any;
    }
}

const AddPaypal = ({ onModalClose, setPaymentMethodIdPaypal, setPayment }: AddPaypalProps) => {
    const { profile } = useProfile();

    const [chargeBee, setChargeBee] = useState<T_ChargeBeeProps | null>(null);
    const [cbInstance, setCbInstance] = useState<any>(null);
    const [token, setToken] = useState<string | null>(null);
    const [paymentIntentPaypal, setPaymentIntentPaypal] = useState<Record<string, any> | null>(null);

    const { data: dataChargeBee, execute: doChargeBee } = useFetch(`/api/payment-subscription/charge-bee/info`);

    useEffect(() => {
        if (cbInstance === null) {
            doChargeBee();
        } else {
            setTimeout(() => {
                doGetPaymentIntentPaypal();
            }, 100);
        }
    }, []);

    useEffect(() => {
        if (dataChargeBee) {
            const data = dataChargeBee as T_ChargeBeeProps;
            setChargeBee({
                siteName: data.siteName,
                apiKeyPublic: data.apiKeyPublic,
                paymentMethods: data.paymentMethods,
            });
        }
    }, [dataChargeBee]);

    useEffect(() => {
        if (isClient() && chargeBee) {
            loadChargebeeScript();
        }
    }, [chargeBee]);

    const loadChargebeeScript = (): void => {
        const chargebeeJs = document.getElementById('js-chargebee');
        if (!chargebeeJs) {
            const url = 'https://js.chargebee.com/v2/chargebee.js';
            const script = document.createElement('script');
            script.id = 'js-chargebee';
            script.type = 'text/javascript';
            script.src = url;
            script.async = true;
            script.defer = true;
            // script.dataset.cbGtmEnabled = 'true';
            script.onload = () => {
                if (typeof window.Chargebee !== 'undefined') {
                    setTimeout(() => {
                        setCbInstance(
                            window.Chargebee.init({
                                site: chargeBee?.siteName,
                                publishableKey: chargeBee?.apiKeyPublic,
                                enableGTMTracking: true,
                            }),
                        );
                    }, 100);
                } else {
                    // document.location.reload();
                    document.location.replace('/500');
                }
            };
            document.body.appendChild(script);
        } else if (typeof window.Chargebee !== 'undefined') {
            setTimeout(() => {
                setCbInstance(
                    window.Chargebee.init({
                        site: chargeBee?.siteName,
                        publishableKey: chargeBee?.apiKeyPublic,
                        enableGTMTracking: true,
                    }),
                );
            }, 100);
        } else {
            // document.location.reload();
            document.location.replace('/500');
        }
    };

    useEffect(() => {
        try {
            if (isClient() && cbInstance) {
                doGetPaymentIntentPaypal();
            }
        } catch (errorChargebee) {
            document.location.replace('/500');
        }
    }, [cbInstance]);

    const {
        data: paymentIntentPaypalData,
        error: paymentIntentPaypalError,
        execute: doGetPaymentIntentPaypal,
    } = useFetch<Record<string, any> | null>(`/api/payment-subscription/charge-bee/payment-intents`, {
        method: 'POST',
        body: JSON.stringify({
            amount: 0,
            currencyCode: 'EUR',
            paymentMethod: 'PAYPAL',
        }),
    });

    useEffect(() => {
        if (paymentIntentPaypalData) {
            setPaymentIntentPaypal(paymentIntentPaypalData);
        } else if (paymentIntentPaypalError) {
            setPaymentIntentPaypal(null);
        }
    }, [paymentIntentPaypalData, paymentIntentPaypalError]);

    useEffect(() => {
        if (paymentIntentPaypal) {
            mountPaypal();
        }
    }, [paymentIntentPaypal]);

    const mountPaypal = async () => {
        if (cbInstance) {
            const paypalHandler = await cbInstance.load('paypal');

            paypalHandler.setPaymentIntent(paymentIntentPaypal);

            const payPalButton = onModalClose ? '#paypal-button-modal' : '#paypal-button';

            await paypalHandler.mountPaymentButton(payPalButton, {
                style: {
                    shape: 'pill',
                    color: 'gold',
                    layout: 'horizontal',
                    label: 'paypal',
                },
            });

            await paypalHandler.handlePayment({
                success(payment: Record<string, string>) {
                    setToken(payment.id);
                },
                error(errors: Error) {
                    setToken(null);
                    setPaymentIntentPaypal(null);

                    // Send error to logs
                    fetch('/api/log', {
                        method: 'POST',
                        body: JSON.stringify({
                            error: `#payPalAddPayment#${errors.message ?? ''}`,
                            url: document.location.pathname,
                            username: profile?.username ?? '',
                            navigator: navigator?.userAgent ?? '',
                        }),
                    });
                },
            });
        }
    };

    useEffect(() => {
        if (token) {
            doAddPaypal();
        }
    }, [token]);

    const {
        data: addPaypalData,
        error: addPaypalError,
        execute: doAddPaypal,
    } = useFetch<Record<string, any> | null>(`/api/payment-subscription/payment-methods`, {
        method: 'POST',
        body: JSON.stringify({
            token,
        }),
    });

    useEffect(() => {
        if (addPaypalData) {
            const data = addPaypalData as Record<string, any>;
            setPaymentMethodIdPaypal(data.id);
        } else if (addPaypalError) {
            setToken(null);
            setPaymentMethodIdPaypal(null);
        }
    }, [addPaypalData, addPaypalError]);

    return onModalClose ? (
        <SBAddPayPalMethod
            onModalClose={onModalClose}
            paymentMethods={(chargeBee?.paymentMethods ?? []) as PaymentType[]}
            setPayment={setPayment}
        />
    ) : (
        <div id="paypal-button" className="center" />
    );
};

export default AddPaypal;
