export const pathTranslations: Record<string, Record<string, string>> = {
    es: {
        '/why-us': '/por-que-nosotros',
        '/capital-allocation': '/asignacion-capital',
        '/your-darwin-index': '/tu-indice-darwin',
        '/assets': '/activos',
        '/pricing': '/precios',
        '/partners': '/partners',
        '/support': '/soporte',
        '/contact': '/contacto',
        '/legal/privacy-policy': '/legal/politica-privacidad',
        '/legal/terms-of-use': '/legal/terminos-uso',
        '/legal/signal-provider-contract': '/legal/contrato-proveedor-senales',
        '/legal/cookie-policy': '/legal/politica-cookies',
        '/legal/refund-policy': '/legal/politica-reembolso',
    },
    fr: {
        '/why-us': '/pourquoi-nous',
        '/capital-allocation': '/allocation-capital',
        '/your-darwin-index': '/votre-indice-darwin',
        '/assets': '/actifs',
        '/pricing': '/tarification',
        '/partners': '/partners',
        '/support': '/support',
        '/contact': '/contact',
        '/legal/privacy-policy': '/legal/politique-confidentialite',
        '/legal/terms-of-use': '/legal/conditions-utilisation',
        '/legal/signal-provider-contract': '/legal/contrat-fournisseur-signal',
        '/legal/cookie-policy': '/legal/politique-cookies',
        '/legal/refund-policy': '/legal/politique-remboursement',
    },
    it: {
        '/why-us': '/perche-noi',
        '/capital-allocation': '/assegnazione-capitale',
        '/your-darwin-index': '/il-tuo-indice-darwin',
        '/assets': '/assets',
        '/pricing': '/tariffe',
        '/partners': '/partners',
        '/support': '/supporto',
        '/contact': '/contatto',
        '/legal/privacy-policy': '/legale/politica-privacy',
        '/legal/terms-of-use': '/legale/condizioni-di-uso',
        '/legal/signal-provider-contract': '/legale/contratto-fornitore-segnali',
        '/legal/cookie-policy': '/legale/politica-cookies',
        '/legal/refund-policy': '/legale/politica-rimborso',
    },
};
