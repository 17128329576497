import Consul from 'consul';

type ConsulKeyValue = {
    LockIndex: number;
    Key: string;
    Flags: number;
    Value: string;
    CreateIndex: number;
    ModifyIndex: number;
};

export default class ConsulClient {
    private static instance: ConsulClient;

    private consulClient: Consul.Consul;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() {
        this.consulClient = new Consul({
            host: process.env.CONSUL_HOST,
            promisify: true,
            defaults: {
                token: process.env.CONSUL_TOKEN,
            },
        });
    }

    /**
     * The static method that controls the access to the singleton instance
     */
    public static getInstance(): ConsulClient {
        if (!ConsulClient.instance) {
            ConsulClient.instance = new ConsulClient();
        }

        return ConsulClient.instance;
    }

    public async getValue(key: string, rootParent = 'dx-zero-nextjs', backupCommon = true): Promise<string | null> {
        let value: ConsulKeyValue | undefined = await this.consulClient.kv.get(`config/${rootParent}/${key}`);
        if (!value && backupCommon) {
            value = await this.consulClient.kv.get(`config/general/${key}`);
        }
        return value?.Value || null;
    }
}
