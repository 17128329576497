import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useCompetitionService } from '~/hooks/services/useCompetitionService';
import { CompetitionTypes } from '~/enums/competition-types';
import { MonthAndYear } from '~/storybook/pages/platform/darwinia/components/ranking/DarwinDetails';
import Nullable from '~/types/Nullable';

export type CurrentCompetitionGeneralInfo = {
    currentCompetitionDate: MonthAndYear;
    updateCurrentCompetitionGeneralInfo?: () => void;
    currentSilverCompetitionInfo: {
        totalParticipants: Nullable<number>;
        minPrizeRating: Nullable<number>;
        lastExecutionTimestamp: Nullable<number>;
        nextExecutionTimestamp: Nullable<number>;
        maxDrawdownCalculationDate: {
            day: number;
            month: number;
            year: number;
        };
    };
    currentGoldCompetitionInfo: {
        totalParticipants: Nullable<number>;
        lastExecutionTimestamp: Nullable<number>;
        nextExecutionTimestamp: Nullable<number>;
    };
};

const CurrentCompetitionGeneralInfoContext = React.createContext<CurrentCompetitionGeneralInfo | null>(null);

type CurrentCompetitionGeneralInfoProviderProps = {
    children: ReactNode;
};

export const CurrentCompetitionGeneralInfoProvider = ({ children }: CurrentCompetitionGeneralInfoProviderProps) => {
    /** ***********************************************************
     * ************************** SILVER **************************
     ************************************************************* */
    const getMaxDrawdownCalculationDate = (): { day: number; month: number; year: number } => {
        const maxDrawdownCalculationDate = moment.utc().subtract(5, 'month').startOf('month');
        return {
            day: maxDrawdownCalculationDate.date(),
            month: maxDrawdownCalculationDate.month() + 1, // months go from 0 (january) to 11 (december)
            year: maxDrawdownCalculationDate.year(),
        };
    };

    const getCompetitionDate = (): { month: number; year: number } => {
        const currentDate = moment.utc();
        return {
            month: currentDate.month() + 1,
            year: currentDate.year(),
        };
    };

    const [maxDrawdownCalculationDate] = useState<{
        day: number;
        month: number;
        year: number;
    }>(getMaxDrawdownCalculationDate());
    const [currentCompetitionDate] = useState<{ month: number; year: number }>(getCompetitionDate());

    const { getCompetitionGlobalInfo } = useCompetitionService();
    const { data: currentSilverCompetitionGlobalData, execute: executeCurrentSilverCompetitionGlobalInfoRequest } =
        getCompetitionGlobalInfo(CompetitionTypes.SILVER, currentCompetitionDate);

    /** ********************************************************** */

    /** ***********************************************************
     * ************************** GOLD ****************************
     ************************************************************* */
    const { data: currentGoldCompetitionGlobalData, execute: executeCurrentGoldCompetitionGlobalInfoRequest } =
        getCompetitionGlobalInfo(CompetitionTypes.GOLD, currentCompetitionDate);
    /** ********************************************************** */

    const providerModalValue = useMemo<CurrentCompetitionGeneralInfo>(
        () => ({
            currentSilverCompetitionInfo: {
                maxDrawdownCalculationDate,
                totalParticipants: currentSilverCompetitionGlobalData.totalParticipants,
                minPrizeRating: currentSilverCompetitionGlobalData.minPrizeRating,
                lastExecutionTimestamp: currentSilverCompetitionGlobalData.lastExecution,
                nextExecutionTimestamp: currentSilverCompetitionGlobalData.nextExecution,
            },
            currentGoldCompetitionInfo: {
                totalParticipants: currentGoldCompetitionGlobalData.totalParticipants,
                nextExecutionTimestamp: currentGoldCompetitionGlobalData.nextExecution,
                lastExecutionTimestamp: currentGoldCompetitionGlobalData.lastExecution,
            },
            currentCompetitionDate,
            updateCurrentCompetitionGeneralInfo: () => {
                executeCurrentSilverCompetitionGlobalInfoRequest();
                executeCurrentGoldCompetitionGlobalInfoRequest();
            },
        }),
        [currentSilverCompetitionGlobalData],
    );

    useEffect(() => {
        executeCurrentSilverCompetitionGlobalInfoRequest();
        executeCurrentGoldCompetitionGlobalInfoRequest();
    }, []);

    return (
        <CurrentCompetitionGeneralInfoContext.Provider value={providerModalValue}>
            {children}
        </CurrentCompetitionGeneralInfoContext.Provider>
    );
};

export const useCurrentCompetitionInfo = () => {
    const context = React.useContext(CurrentCompetitionGeneralInfoContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a NextModalProvider');
    }
    return context as CurrentCompetitionGeneralInfo;
};
