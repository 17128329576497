import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { T_Consul } from '~/pages/api/consul-analytics-nextjs';
import useFetch from '~/hooks/useFetch';
import { useRouter } from 'next/router';

const AnalyticsHeaderCommon = () => {
    const { locale } = useRouter();
    const [analyticsProps, setAnalyticsProps] = useState<T_Consul | null>(null);

    useEffect(() => {
        doGetAnalyticsProps();
    }, []);

    const { data: dataAnalytics, execute: doGetAnalyticsProps } = useFetch(
        `/api/consul-analytics-nextjs?keys=cookie-bot`,
    );

    useEffect(() => {
        if (dataAnalytics) {
            const data = dataAnalytics as T_Consul;
            setAnalyticsProps(data);
        }
    }, [dataAnalytics]);

    return (
        analyticsProps && (
            <>
                {analyticsProps['cookie-bot'] && locale && (
                    <Script
                        id="Cookiebot"
                        src="https://consent.cookiebot.com/uc.js"
                        data-cbid={analyticsProps['cookie-bot']}
                        data-culture={locale}
                    />
                )}

                {/* FIRST PROMOTER */}
                {/* <Script id="FirstPromoter" src="https://cdn.firstpromoter.com/fpr.js" /> */}
            </>
        )
    );
};
export default AnalyticsHeaderCommon;
