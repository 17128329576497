import React from 'react';
import NavbarEmpty from '~/storybook/components/common/navbar/NavbarEmpty';
import classNames from 'classnames';
import styles from '~/storybook/pages/home/index/components/sectionFeatures/SectionFeatures.module.scss';

const PageMaintenance = () => (
    <div className="page homepage">
        <NavbarEmpty />
        <section className={classNames(styles.section_features, 'dxcl-container')}>
            <div className={classNames(styles.section_features__wrapper, 'dxcl-row')}>
                <div className={classNames(styles.section_features__block)}>
                    <h3 className={styles.section_features__title}>We’re under maintenance</h3>
                    <p className={styles.section_features__text}>
                        We’re upgrading Darwinex Zero at the moment. Please check back soon.
                    </p>
                </div>
            </div>
        </section>
    </div>
);

export default PageMaintenance;
